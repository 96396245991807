import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import BaseButton from 'src/components/base/BaseButton';
import BaseContainer from 'src/components/base/BaseContainer';
import BasePage from 'src/components/base/BasePage';
import Summary from '../OrderSummary/Summary';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.success.main,
    fontSize: 150,
    marginRight: 30,
  },
  thanks: {
    color: theme.custom.myTheme.grey3,
    fontSize: 18,
  },
  orderInfo: {
    color: theme.custom.myTheme.grey3,
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  estDate: {
    color: theme.palette.primary.main,
    '& span': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default function OrderConfirmed() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useHistory();

  const orderDetail = useParams();

  return (
    <BasePage>
      <BaseContainer>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={8}>
            <Box display="flex" alignItems="center">
              <CheckCircle className={classes.icon} />

              <Box>
                <Typography variant="h4" color="primary">
                  {t('orderConfirmed')}
                </Typography>
                <Typography className={classes.thanks}>
                  {t('thankYou')}
                </Typography>
                <Box mt={1} mb={1}>
                  <Typography className={classes.orderInfo}>
                    {t('orderDate')}:{' '}
                    <span>{orderDetail.date.slice(0, 10)}</span>
                  </Typography>
                  <Typography className={classes.orderInfo}>
                    {t('orderNum')}: <span>{orderDetail.id}</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Summary orderDetail={orderDetail} />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={8}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column">
              <BaseButton
                color="secondary"
                text={t('orderDetails')}
                style={{ textTransform: 'capitalize' }}
                onClick={() => push(`/order-details/${orderDetail.id}`)}
              />
              <BaseButton
                text={t('continueShopping')}
                style={{ marginTop: 15, textTransform: 'capitalize' }}
                onClick={() => push(`/`)}
              />
            </Box>
          </Grid>
        </Grid>
      </BaseContainer>
    </BasePage>
  );
}
