import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loading from 'src/components/base/Loading';
import {
  getUserAddressesAction,
  removeAddressItemAction,
  saveAdressToLocalStorageAction,
} from 'src/store/actions/address.actions';
import Dialog from 'src/components/Dialog';
import BaseButton from 'src/components/base/BaseButton';
import { localStoragePrefix, ROUTE_PATH } from 'src/config';

const useClasses = makeStyles((theme) => ({
  deliverTo: {
    cursor: 'pointer',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 280 ? 16 : 14,
    },
  },
  address: {
    fontFamily: 'sfUiDisplayRegular',
    color: theme.custom.myTheme.grey3,
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 280 ? 14 : 12,
    },
  },
  listItemAvatar: {
    // background: 'green',
    [theme.breakpoints.down('xs')]: {
      minWidth: 40,
    },
  },
  icon: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: `2px solid ${theme.custom.myTheme.grey3}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    // marginRight: 25,
    transition: 'all 3s ease-in-out',
    '& div': {
      background: theme.palette.primary.main,
      width: 20,
      height: 20,
      borderRadius: '50%',
      [theme.breakpoints.down('xs')]: {
        width: 15,
        height: 15,
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: 25,
      height: 25,
    },
  },
  selectBtn: {
    color: theme.palette.primary.main,
  },
  actionIcon: {
    width: 23,
    height: 23,
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: window.innerWidth > 280 ? 18 : 15,
      height: window.innerWidth > 280 ? 18 : 15,
    },
  },
  confirmDelete: {
    fontFamily: 'sfUiDisplayRegular',
    color: theme.palette.warning.main,
    textAlign: 'center',
  },
}));
export default function UserAddresses({ editAddressHandler }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { push } = useHistory();
  const classes = useClasses();
  const { addresses, shippingAddress, loading } = useSelector(
    (state) => state.addressReducer
  );
  // const [selectedAddress, setSelectedAddress] = useState();
  const [selectedAddressId, setSelectedAddressId] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [tempAddress, setTempAddress] = useState();
  const theme = useTheme();

  // const [value, setValue] = useState('');

  const selectAddressHandler = (selectedAddress) => {
    setSelectedAddressId(selectedAddress.id);

    dispatch(saveAdressToLocalStorageAction(selectedAddress));
    // localStorage.setItem(
    //   localStoragePrefix + SHIPPING_ADDRESS,
    //   JSON.stringify(selectedAddress)
    // );
    // dispatch({
    //   type: SET_SHIPPING_ADDRESS,
    //   payload: selectedAddress,
    // });

    // push('/payment');
  };

  const removeAddressHandler = (tempAddress) => {
    setConfirmDelete(true);
    setTempAddress(tempAddress);
  };

  const confirmDeleteHandler = () => {
    setConfirmDelete(false);
    dispatch(removeAddressItemAction(tempAddress.id, '/shipping-address'));
    setTempAddress(null);
  };

  const cancelDeleteHandler = () => {
    setConfirmDelete(false);
    setTempAddress(null);
  };

  const confirmDeleteDialog = () => (
    <Dialog
      open={confirmDelete}
      closeDialog={() => setConfirmDelete(false)}
      color="secondary"
    >
      <div style={{ width: 300 }}>
        <Typography className={classes.confirmDelete}>
          {t('confirmDelete')}
        </Typography>
        <Box display="flex" mt={2}>
          <Box flexGrow={1} />
          <BaseButton
            text={t('cancel')}
            color="primary"
            variant="text"
            textStyle={{ color: theme.palette.primary.main, fontSize: 14 }}
            onClick={cancelDeleteHandler}
          />
          <Box width={10} />
          <BaseButton
            text={t('ok')}
            color="secondary"
            variant="text"
            textStyle={{ color: theme.palette.secondary.main, fontSize: 14 }}
            onClick={confirmDeleteHandler}
          />
        </Box>
      </div>
    </Dialog>
  );

  const renderItem = (address, idx) => (
    <div key={Math.random().toString()}>
      <ListItem style={{ position: 'relative' }}>
        <ListItemAvatar className={classes.listItemAvatar}>
          <div
            className={classes.icon}
            // onClick={() => setSelectedAddress(address)}
            onClick={() => selectAddressHandler(address)}
          >
            {address.id == selectedAddressId && <div />}
          </div>
        </ListItemAvatar>
        <ListItemText
          primary={
            <div
              style={{
                textAlign: i18n.dir() == 'rtl' && 'right',
              }}
            >
              <Typography
                color="primary"
                // onClick={() => setSelectedAddress(address)}
                onClick={() => selectAddressHandler(address)}
                className={classes.deliverTo}
              >
                {t('deliverToAddress')}
              </Typography>
              <Typography className={classes.address}>
                {address.full_name}
              </Typography>
              <Typography className={classes.address}>
                {address.address_line_1 +
                  ' ' +
                  address.address_line_2 +
                  ' ,Phone: ' +
                  address.phone}
              </Typography>
            </div>
          }
        />
        <div
          style={{
            position: 'absolute',
            left: i18n.dir() == 'rtl' && 0,
            right: i18n.dir() == 'ltr' && 0,
          }}
        >
          <>
            <div
              style={{
                // height: 80,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                // background: '#ff0',
              }}
            >
              <IconButton onClick={() => editAddressHandler(address)}>
                <img
                  src="/images/icons/Icons-18.png"
                  className={classes.actionIcon}
                />
              </IconButton>
              {/* <div style={{ height: 20 }} /> */}
              <IconButton onClick={() => removeAddressHandler(address)}>
                <img
                  src="/images/icons/Icons-17.png"
                  className={classes.actionIcon}
                />
              </IconButton>
            </div>
          </>
        </div>
      </ListItem>
      {idx + 1 < addresses.length && <Divider style={{ margin: '8px 0' }} />}
    </div>
  );

  useEffect(() => {
    dispatch(getUserAddressesAction(i18n.language.split('-')[0]));
  }, []);

  useEffect(() => {
    shippingAddress && setSelectedAddressId(shippingAddress.id);
  }, [shippingAddress]);

  useEffect(() => {
    const routePath = localStorage.getItem(localStoragePrefix + ROUTE_PATH);
    if (routePath) {
      localStorage.removeItem(localStoragePrefix + routePath);
      push(routePath);
    }
  }, []);

  if (loading || !addresses) {
    return <Loading />;
  }

  return (
    <>
      <List dense>
        {addresses?.map((address, idx) => renderItem(address, idx))}
      </List>
      {confirmDeleteDialog()}
    </>
  );

  // return <>{addresses?.map((address) => renderItem(address))}</>;
}
