import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BaseButton from 'src/components/base/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkOutAction,
  removeCartItemAction,
} from 'src/store/actions/cart.actions';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    // width: 287,
    width: '49%',
    // fontSize: 18,
    // '& span p': {
    //   fontSize: '18px !important',
    // },
    [theme.breakpoints.down('xs')]: {
      padding: 10,
      width: '47%',
      '& span p': {
        fontSize:
          window.innerWidth > 300 ? '16px !important' : '12px !important',
      },
    },
  },
  backBtn: {
    background: theme.palette.secondary.main,
    // width: 287,
    width: '49%',
    // marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: 10,
      width: '47%',
      '& span p': {
        fontSize:
          window.innerWidth > 300 ? '16px !important' : '12px !important',
      },
    },
  },
}));

function WizardActions({ activeStep, stepsLength }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useHistory();

  const { shippingAddress } = useSelector((state) => state.addressReducer);
  const { cartItems, paymentMethods, paymentMethodId, checkoutPayload } =
    useSelector((state) => state.cartReducer);
  const { currencyInfo } = useSelector((state) => state.userReducer);
  const { shippingInfo } = useSelector((state) => state.aramixReducer);

  const {
    acceptTermsAndConditions,
    // subscribeToNewsLetters,
    validCod,
    gateway_code,
  } = checkoutPayload;

  const dispatch = useDispatch();

  const placeOrderHandler = () => {
    // check order items qty, it should be > 0
    for (let item of cartItems) {
      if (item.qty <= 0) dispatch(removeCartItemAction('cart', item.id));
    }

    // if cartItems length == 0, redirect to cart
    if (cartItems.length == 0) return push('/cart');

    let payload = {
      price: checkoutPayload.total,
      other_price: checkoutPayload.otherTotal,
      amount: cartItems.length,
      cartItems: cartItems,
      is_persentage: checkoutPayload.isPersentage,
      coupon_value: checkoutPayload.couponValue,
      shipping_methods: checkoutPayload.shippingMethodId,
      gateway_code: paymentMethods?.find((item) => item.id == paymentMethodId)
        ?.gateway_code,
      paymentMethodId: paymentMethodId,
      shipping_value: checkoutPayload.shipping,
      other_shipping_value: checkoutPayload.otherShipping,
      subTotal: checkoutPayload.subTotal,
      other_subTotal: checkoutPayload.otherSubtotal,
      address_id: shippingAddress.id,
      currency: currencyInfo,
      packageData: shippingInfo.packageData,
      subscribe_newsletter: checkoutPayload.subscribe_newsletter,
    };

    // const { subscribe_newsletter } = checkoutPayload;

    if (checkoutPayload.couponCode) {
      payload['coupon_code'] = checkoutPayload.couponCode;
      payload['coupon_value'] = checkoutPayload.couponValue;
      payload['coupon_id'] = checkoutPayload.couponId;
    }

    // console.log('checkout payload', payload);
    dispatch(checkOutAction(payload, push));
  };

  const handleNext = () => {
    if (
      activeStep == 2 &&
      acceptTermsAndConditions &&
      paymentMethodId
      // &&
      // subscribeToNewsLetters
    ) {
      // console.log('checkoutPayload', checkoutPayload);
      placeOrderHandler();
    }
    switch (activeStep) {
      case 0:
        return push('/shipping-address');
      case 1:
        return push('/checkout');
      default:
        return;
    }
  };

  const handleBack = () => {
    switch (activeStep) {
      // case 0:
      //   return;
      case 1:
        return push('/cart');
      case 2:
        return push('/shipping-address');
      default:
        return;
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={2}
      // bgcolor="#f0f"
    >
      <BaseButton
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.backBtn}
        text={t('back')}
      />
      <BaseButton
        disabled={
          (activeStep == 0 && !cartItems?.length) ||
          (activeStep == 1 && !shippingAddress) ||
          (activeStep == 2 &&
            (!acceptTermsAndConditions ||
              !paymentMethodId ||
              (!validCod && gateway_code == 'cods')))
        }
        onClick={handleNext}
        className={classes.button}
        text={
          activeStep === stepsLength - 1
            ? t('placeOrder')
            : t('next') +
              ' ' +
              (activeStep == 0
                ? t(window.innerWidth > 450 ? 'shippingAddress' : 'shipping')
                : t('checkout'))
        }
      />
    </Box>
  );
}

export default WizardActions;
