export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const USER_EXIST = 'USER_EXIST';

export const RESET_REGISTER = 'RESET_REGISTER';

export const USER_SIGNOUT = 'USER_SIGNOUT';

export const SET_USER_INFO_SUCCESS = 'SET_USER_INFO_SUCCESS';
export const SET_USER_INFO_FAIL = 'SET_USER_INFO_FAIL';

export const SET_CURRENCY_INFO_SUCCESS = 'SET_CURRENCY_INFO_SUCCESS';
export const SET_CURRENCY_INFO_FAIL = 'SET_CURRENCY_INFO_FAIL';

export const TEST_USER_CREDENTIALS_REQUEST = 'TEST_USER_CREDENTIALS_REQUEST';
export const TEST_USER_CREDENTIALS_SUCCESS = 'TEST_USER_CREDENTIALS_SUCCESS';
export const TEST_USER_CREDENTIALS_FAIL = 'TEST_USER_CREDENTIALS_FAIL';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const RESET_PASSWORD_REQ_REQUEST = 'RESET_PASSWORD_REQ_REQUEST';
export const RESET_PASSWORD_REQ_SUCCESS = 'RESET_PASSWORD_REQ_SUCCESS';
export const RESET_PASSWORD_REQ_FAIL = 'RESET_PASSWORD_REQ_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const RESET_PASS_EMAIL_REQUEST = 'RESET_PASS_EMAIL_REQUEST';

export const SET_AUTH_DIALOG = 'SET_AUTH_DIALOG';
