import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Center from 'src/components/Center';
import { convertToArabicNumbers } from 'src/utils';

const useClasses = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: 38,
    height: 35,
    cursor: 'pointer',
    // border: '1px solid #f0f',
    [theme.breakpoints.down('xs')]: {
      width: 27,
      height: 28,
    },
  },
  basketIcon: {
    width: 34,
    height: 35,
    objectFit: 'contain',
    marginRight: ({ dir }) => (dir == 'ltr' ? 0 : 4),
    marginLeft: ({ dir }) => (dir == 'rtl' ? 0 : 4),
    // marginLeft: 4,
    transform: ({ dir }) => `scaleX(${dir == 'rtl' ? -1 : 1})`,
    // border: '1px solid green',
    [theme.breakpoints.down('xs')]: {
      width: 27,
      height: 28,
    },
  },
  itemsQtyContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    marginLeft: ({ dir }) => (dir == 'ltr' ? 4 : 0),
    marginRight: ({ dir }) => (dir == 'rtl' ? 5 : 0),
    // zIndex: 10,
    // border: '1px solid red',
  },
  qty: {
    color: '#fff',
    fontFamily: 'sfUiDisplayBold',
    fontSize: 12,
    marginTop: 3,
    [theme.breakpoints.down('xs')]: {
      marginTop: 2,
      fontSize: 8,
      marginLeft: ({ dir }) => (dir == 'ltr' ? 3 : 0),
      marginRight: ({ dir }) => (dir == 'rtl' ? 4 : 0),
    },
  },
}));

function ShoppingBasket() {
  const { i18n } = useTranslation();
  const { push } = useHistory();
  const classes = useClasses({ dir: i18n.dir() });
  const { cartItems } = useSelector((state) => state.cartReducer);

  return (
    <div className={classes.root} onClick={() => push('/cart')}>
      <img src="/images/Icons-05.png" className={classes.basketIcon} />
      <Center className={classes.itemsQtyContainer}>
        <span className={classes.qty}>
          {convertToArabicNumbers((cartItems.length || '') + '', i18n.dir())}
        </span>
      </Center>
    </div>
  );
}

export default ShoppingBasket;
