import React from 'react';

import { useTranslation } from 'react-i18next';
import Summary from './Summary';
import Coupon from './Coupon';
import PaymentMethod from './PaymentMethod';
import BaseButton from 'src/components/base/BaseButton';
import { Box } from '@material-ui/core';
import ShippingMethod from './ShippingMethod';
import { useSelector } from 'react-redux';

export default function OrderSummary({
  selectedAddress,
  activeStep,
  orderConfirmed,
  ...rest
}) {
  const { t } = useTranslation();
  const { shippingAddress } = useSelector((state) => state.addressReducer);

  return (
    <>
      {/* activeStep == 1 && shippingAddress) || activeStep > 1 */}
      {activeStep > 1 && shippingAddress && !orderConfirmed && (
        <>
          <ShippingMethod />

          <Coupon {...rest} />

          {activeStep > 1 && <PaymentMethod {...rest} />}
        </>
      )}

      <Summary activeStep={activeStep} {...rest} />
      {orderConfirmed && (
        <Box display="flex" flexDirection="column">
          <BaseButton
            color="secondary"
            text={t('orderDetails')}
            style={{ marginTop: 15, textTransform: 'capitalize' }}
          />
          <BaseButton
            text={t('continueShopping')}
            style={{ marginTop: 15, textTransform: 'capitalize' }}
          />
        </Box>
      )}
    </>
  );
}
