import React from 'react';

import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textfield: {
    margin: 0,
    width: '100%',
    '& input': {
      color: theme.palette.primary.main,
      padding: 10,
      fontFamily: 'sfUiDisplayRegular',
      fontSize: 15,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
    '& textarea': {
      color: theme.palette.primary.main,
      fontFamily: 'sfUiDisplayRegular',
      fontSize: 15,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
    '& ::placeholder': {
      color: theme.custom.myTheme.grey3,
    },
    '& div': {
      borderRadius: 0,
      background: 'white',
    },
  },
  wrapper: {
    width: '100%',
  },
  label: {
    // width: 150,
    color: theme.custom.myTheme.grey3,
    marginBottom: 10,
    fontFamily: 'sfUiDisplayRegular',
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
}));

export default function BaseInput({
  label,
  variant,
  inline_label,
  ...otherProps
}) {
  const classes = useStyles();

  const configTextfield = {
    fullWidth: true,
    variant: variant || 'outlined',
    margin: 'dense',
    ...otherProps,
    // rows: 5,
  };

  return (
    // <div className={classes.wrapper}>
    //   {label && <div className={classes.label}>{label}</div>}
    //   <TextField className={classes.textfield} {...configTextfield} />
    // </div>
    <div className={classes.wrapper}>
      {label && <div className={classes.label}>{label}</div>}
      <TextField className={classes.textfield} {...configTextfield} />
    </div>
    // <div className={inline_label && classes.wrapper}>
    //   {label && <div className={classes.label}>{label}</div>}
    //   <TextField className={classes.textfield} {...configTextfield} />
    // </div>
  );
}
