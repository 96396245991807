import React, { useEffect, useState, memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Center from 'src/components/Center';

import {
  addToCartAction,
  removeCartItemAction,
} from 'src/store/actions/cart.actions';
import { convertToArabicNumbers } from 'src/utils';
import ShareDialog from 'src/components/ShareDialog';
import Rating from 'src/components/Rating';
import BaseButton from 'src/components/base/BaseButton';
import ReviewForm from './ReviewForm';

const useClasses = makeStyles((theme) => ({
  imageContainer: {
    maxWidth: '100%',
    maxHeight: '100%',
    [theme.breakpoints.down('xs')]: {
      maxHeight: window.innerWidth > 280 ? '80%' : '70%',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  title: {
    fontFamily: 'sfUiDisplaySemiBold',
    fontSize: 20,
    marginBottom: 10,
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 280 ? 18 : 14,
    },
  },
  authorName: {
    color: theme.custom.myTheme.grey3,
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 280 ? 14 : 12,
    },
  },
  priceContaier: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '15px 0',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      // alignItems: 'center',
      alignItems: 'flex-start',
      fontSize: 14,
      margin: 0,
      marginTop: 10,
    },
  },
  price: {
    color: theme.palette.primary.main,
    fontFamily: 'roboto',
    fontWeight: 700,
    direction: 'ltr',
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 280 ? 14 : 13,
    },
  },
  discount: {
    color: theme.custom.myTheme.grey3,
    fontFamily: 'roboto',
    fontWeight: 400,
    textDecoration: 'line-through',
    margin: '0 30px',
    direction: 'ltr',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      fontSize: window.innerWidth > 280 ? 12 : 10,
    },
  },
  removeBtn: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textDecoration: 'underline',
    textTransform: 'capitalize',
    letterSpacing: 1,
    fontSize: 16,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 280 ? 13 : 11,
      padding: '3px 6px',
    },
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },
  qtyBtnIcon: {
    minWidth: 35,
    width: window.innerWidth > 280 ? 35 : 30,
    height: window.innerWidth > 280 ? 35 : 30,
    borderRadius: 0,
  },
  qtyIcon: {
    color: 'white',
    fontSize: 16,
  },
  quantity: {
    border: `1px solid ${theme.custom.myTheme.grey3}`,
    borderWidth: '1px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: window.innerWidth > 280 ? 35 : 30,
    color: theme.palette.primary.main,
    fontFamily: 'sfUiDisplayRegular',
    fontSize: 15,
  },
  qty: {
    color: theme.palette.primary.main,
  },
}));

function CartItem({
  product,
  userQty,
  cartItemId,
  // orderQty,
  // orderItemDetail,
  orderItemDetails,
  storeQty,
}) {
  const classes = useClasses();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { currencyInfo } = useSelector((state) => state.userReducer);

  const theme = useTheme();

  const {
    reviewPayload: { showForm },
  } = useSelector((state) => state.productReducer);

  const { loading } = useSelector((state) => state.cartReducer);

  const lang = i18n.language.split('-')[0];

  // const [qty, setQty] = useState(3);
  // const [newQty, setNewQty] = useState();
  // const [decBtnDisabled, setDecBtnDisabled] = useState(false);

  const onQuantityIncrement = () => {
    // setNewQty(userQty + 1);
    if (userQty + 1 <= storeQty) {
      dispatch(addToCartAction('cart', product.id));
    }
  };

  const onQuantityDecrement = () => {
    // setDecBtnDisabled(true);
    // if (userQty <= 1) return;
    // setNewQty(userQty - 1);
    if (userQty > 1) {
      dispatch(addToCartAction('cart', product.id, -1));
    }
    // setDecBtnDisabled(false);
  };

  // useEffect(() => {
  //   userQty && setNewQty(userQty);
  // }, [userQty]);

  const getProductTitle = () =>
    product.title
      ? product.title
      : lang == 'ar'
      ? product.product_id
        ? product.product_id.arabic_title
        : product.arabic_title
      : lang == 'tr'
      ? product.product_id
        ? product.product_id.turkish_title
        : product.turkish_title
      : product.product_id
      ? product.product_id.english_title
      : product.english_title;

  const renderQty = () => (
    <div className={classes.btnContainer}>
      <Button
        className={classes.qtyBtnIcon}
        style={{
          borderTopLeftRadius: i18n.dir() == 'ltr' && 20,
          borderBottomLeftRadius: i18n.dir() == 'ltr' && 20,
          borderTopRightRadius: i18n.dir() == 'rtl' && 20,
          borderBottomRightRadius: i18n.dir() == 'rtl' && 20,
          backgroundColor: theme.palette.primary.main,
          opacity: userQty <= 1 ? 0.8 : 1,
        }}
        onClick={onQuantityDecrement}
        color="primary"
        variant="contained"
        disableElevation
        disabled={userQty <= 1}
      >
        <RemoveIcon className={classes.qtyIcon} />
      </Button>

      <div className={classes.quantity}>
        {userQty && convertToArabicNumbers(userQty + '', i18n.dir())}
      </div>
      <Button
        className={classes.qtyBtnIcon}
        style={{
          borderTopLeftRadius: i18n.dir() == 'rtl' && 20,
          borderBottomLeftRadius: i18n.dir() == 'rtl' && 20,
          borderTopRightRadius: i18n.dir() == 'ltr' && 20,
          borderBottomRightRadius: i18n.dir() == 'ltr' && 20,
          backgroundColor: theme.palette.primary.main,
          opacity: userQty >= storeQty ? 0.8 : 1,
        }}
        onClick={onQuantityIncrement}
        color="primary"
        variant="contained"
        disabled={userQty >= storeQty}
        disableElevation
      >
        <AddIcon className={classes.qtyIcon} />
      </Button>
    </div>
  );

  const getShareInfo = () => {
    const title = product[
      lang == 'ar'
        ? 'arabic_title'
        : lang == 'tr'
        ? 'turkish_title'
        : 'english_title'
    ]?.slice(0, 100);

    const description = product[
      lang == 'ar'
        ? 'arabic_description'
        : lang == 'tr'
        ? 'turkish_description'
        : 'english_description'
    ]?.slice(0, 100);

    return { title, description };
  };

  if (!product) {
    return null;
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item container xs={5} sm={3} style={{ background: '#fff' }}>
          <RouterLink to={`/products/${product.id}`} target="_blank">
            <Center className={classes.imageContainer}>
              <img
                className={classes.image}
                src={product.image.data.full_url}
              />
            </Center>
          </RouterLink>
        </Grid>
        <Grid item xs={7} sm={5}>
          <div>
            <RouterLink
              to={`/products/${product.id}`}
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <Typography color="primary" className={classes.title}>
                {getProductTitle()}
              </Typography>
            </RouterLink>

            {/* authors */}
            <Typography
              className={classes.authorName}
              style={{ marginBottom: 5 }}
            >
              {t('authorName')}:{' '}
              {product.authors.map((author) => (
                <span key={Math.random().toString()}>
                  {
                    author?.author_id[
                      lang == 'ar'
                        ? 'arabic_title'
                        : lang == 'tr'
                        ? 'turkish_title'
                        : 'english_title'
                      // lang == 'en' ? 'english_title' : 'arabic_title'
                    ]
                  }
                </span>
              ))}
            </Typography>

            {product.years && (
              <Typography className={classes.authorName}>
                {t('prodYear')}: {product.years}
              </Typography>
            )}

            {orderItemDetails && (
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ margin: '10px 0' }}
              >
                {t('quantity')}:{' '}
                <span className={classes.qty}>
                  {convertToArabicNumbers(
                    orderItemDetails.quantity + '',
                    i18n.dir()
                  )}
                </span>
              </Typography>
            )}

            {/* price & rating */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <div
                className={classes.priceContaier}
                // style={{ backgroundColor: '#ff0' }}
              >
                <Typography className={classes.price}>
                  <span style={{ fontSize: 13 }}>
                    {currencyInfo?.title + ' '}
                  </span>
                  {convertToArabicNumbers(
                    Number(
                      (orderItemDetails?.price || product.discounted_price) *
                        currencyInfo.from ||
                        (orderItemDetails?.price || product.actual_price) *
                          currencyInfo.from
                    ).toFixed(2) + '',
                    i18n.dir()
                  )}
                </Typography>
                {product.discounted_price &&
                  !orderItemDetails &&
                  product.actual_price && (
                    <Typography variant="overline" className={classes.discount}>
                      <span style={{ fontSize: 13 }}>
                        {currencyInfo?.title + ' '}
                      </span>
                      {convertToArabicNumbers(
                        (product.actual_price * currencyInfo.from).toFixed(2) +
                          '',
                        i18n.dir()
                      )}
                    </Typography>
                  )}
              </div>

              {orderItemDetails ? (
                orderItemDetails.rating.length == 0 && (
                  <Box>
                    <Rating rating={4.5} readOnly={false} />
                  </Box>
                )
              ) : (
                <div />
              )}
            </Box>

            {!orderItemDetails && (
              <Button
                className={classes.removeBtn}
                onClick={() =>
                  dispatch(removeCartItemAction('cart', cartItemId))
                }
              >
                {t('remove')}
              </Button>
            )}
          </div>
          {showForm && (
            <ReviewForm
              orderItemId={orderItemDetails.id}
              productId={orderItemDetails.product_id.id}
            />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          container
          alignItems="flex-start"
          justifyContent="center"
        >
          {!orderItemDetails ? (
            <Center>
              {renderQty()}
              <Typography className={classes.price}>
                <span style={{ fontSize: 13 }}>
                  {currencyInfo?.title + ' '}
                </span>
                <span style={{ fontSize: 14 }}>
                  {convertToArabicNumbers(
                    Number(
                      (product.discounted_price * currencyInfo.from ||
                        product.actual_price * currencyInfo.from) * userQty
                    ).toFixed(2) + '',
                    i18n.dir()
                  )}
                  {/* {convertToArabicNumbers(
                    Number(
                      product.discounted_price * currencyInfo.from ||
                        product.actual_price * currencyInfo.from
                    ).toFixed(2) *
                      userQty +
                      '',
                    i18n.dir()
                  )} */}
                </span>
              </Typography>
            </Center>
          ) : (
            <ShareDialog shareInfo={getShareInfo()} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(CartItem);
