import React, { useEffect, useMemo, useState } from 'react';

import { RESET_PAGINATION } from 'src/store/constants/product.types';
import { useDispatch, useSelector } from 'react-redux';
import ProductItem from 'src/components/ProductItem';
import { filterProductsAction } from 'src/store/actions/product.actions';
import { useTranslation } from 'react-i18next';

import { useHistory, useParams } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import BasePage from 'src/components/base/BasePage';
import BaseContainer from 'src/components/base/BaseContainer';
import SearchResult from './SearchResult';
import Loading from 'src/components/base/Loading';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#fff',
  },
}));

export default function FilterScreen() {
  const { t, i18n } = useTranslation();
  const { table, filter_by, eq } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const productReducer = useSelector((state) => state.productReducer);
  const {
    products_page,
    products_page_count,
    filteredProducts,
    searchResult,
    loading,
  } = productReducer;

  const dispatch = useDispatch();
  const [sort, setSort] = useState(false);
  const [tempParams, setTempParams] = useState();

  const renderSearch = history.location.pathname.indexOf('search');
  const query = { table, filter_by, eq };

  useEffect(() => {
    const temp = query.table + query.filter_by + query.eq;
    // temp !== tempParams && setTempParams(temp);
    if (renderSearch == -1 && temp !== tempParams) {
      setTempParams(temp);
      dispatch(filterProductsAction(query));
    }
  }, [query, tempParams]);

  const toggleModal = (mode) => {
    if (mode == 'sort') {
      setSort(true);
    } else {
      setSort(false);
    }
  };

  const sortData = (sortBy) => {
    dispatch({
      type: RESET_PAGINATION,
    });

    switch (sortBy) {
      case '1':
        dispatch(
          filterProductsAction(query, undefined, {
            by: i18n.language == 'ar' ? 'arabic_title' : 'english_title',
            order: '+',
          })
        );

        break;

      case '2':
        dispatch(
          filterProductsAction(query, undefined, {
            by: i18n.language == 'ar' ? 'arabic_title' : 'english_title',
            order: '-',
          })
        );

        break;

      case '3':
        dispatch(
          filterProductsAction(query, undefined, {
            by: 'discounted_price',
            order: '-',
          })
        );

        break;

      case '4':
        dispatch(
          filterProductsAction(query, undefined, {
            by: 'discounted_price',
            order: '+',
          })
        );
    }
    toggleModal();
    // toggleBottomsheet();
  };

  const memoizedValue = useMemo(
    () => (item) => <ProductItem item={item} />,
    []
  );

  const renderContent = () => (
    <div className={classes.container}>
      <div>
        {filteredProducts && (
          <Grid container spacing={2}>
            {filteredProducts.map((item, index) => (
              <Grid
                item
                container
                justifyContent="center"
                xs={6}
                sm={3}
                md={2}
                key={Math.random().toString()}
              >
                {memoizedValue(item)}
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );

  // if ((!filteredProducts && !searchResult) || loading) {
  //   return <Loading />;
  // }
  return (
    <BasePage>
      <BaseContainer>
        {renderSearch !== -1 ? <SearchResult /> : renderContent()}
      </BaseContainer>
    </BasePage>
  );
}
