import { Button, IconButton, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { useDispatch } from 'react-redux';

import { socialAuthAction } from 'src/store/actions/auth.actions';

// const platforms = [
//   { icon: 'Icons-25', btn: '' },
//   { icon: 'Icons-26', btn: '' },
//   { icon: 'Icons-27', btn: '' },
//   { icon: 'Icons-28', btn: '' },
// ];

const useClasses = makeStyles((theme) => ({
  root: {
    boxShadow: 'none !important',
    display: 'flex !important',
    justifyContent: 'center !important',
    background: 'none ',
    // width: 50,
    '& div': {
      justifyContent: 'center !important',
    },
  },
}));

function SocialLogin() {
  const classes = useClasses();
  const dispatch = useDispatch();

  const onSuccess = (response) => {
    // console.log(response);
    if (response.tokenId) {
      const payload = {
        email: response.profileObj.email,
        first_name: response.profileObj.givenName,
        last_name: response.profileObj.familyName,
        from: 'google',
      };

      dispatch(socialAuthAction(payload));
    }
  };

  const onFailure = async () => null;

  return (
    <div style={{ display: 'flex' }}>
      {/* {platforms.map(({ icon, btn: SocialButton }) => (
        <div key={icon}>
          <SocialButton
            onClick={handleClick}
            icon=""
            className={classes.root}
            // style={{ boxShadow: 'none', justifyContent: 'center' }}
          >
            <img
              style={{
                width: 50,
                height: 50,
                objectFit: 'contain',
              }}
              src={`/images/icons/${icon}.png`}
              alt={icon}
            />
          </SocialButton>
          
        </div>
      ))} */}
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        // clientId="904309728437-lr685qoog43e629ke07hfdpo47t3jsq4.apps.googleusercontent.com"
        // buttonText=""
        render={(renderProps) => (
          <IconButton
            size="small"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <img
              style={{
                width: 50,
                height: 50,
                objectFit: 'contain',
              }}
              src={`/images/icons/Icons-28.png`}
            />
          </IconButton>
        )}
        onSuccess={onSuccess}
        onFailure={onFailure}
        // cookiePolicy={'single_host_origin'}
      />
    </div>
  );
}

export default SocialLogin;
