import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BaseButton from 'src/components/base/BaseButton';
import Center from 'src/components/Center';
import { reviewProductAction } from 'src/store/actions/product.actions';
import { SET_REVIEW_PAYLOAD } from 'src/store/constants/product.types';

function ReviewForm({ productId, orderItemId }) {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [review, setReview] = useState('');
  const [titleError, setTitleError] = useState(null);
  const [reviewError, setReviewError] = useState(null);

  const dispatch = useDispatch();
  const {
    reviewPayload: { rating },
  } = useSelector((state) => state.productReducer);
  const { userInfo } = useSelector((state) => state.authReducer);
  const { orderDetail } = useSelector((state) => state.orderReducer);

  const submitHandler = async () => {
    setTitleError(null);
    setReviewError(null);

    // if (!title.length) {
    //   return setTitleError('title is required');
    // } else if (!review.length) {
    //   return setReviewError('review is required');
    // }

    // =========
    const payload = {
      customer_id: userInfo.id,
      product_id: productId,
      order_item_id: orderItemId,
      order_id: orderDetail.id,
      rating,
      ...(title && { title }),
      ...(review && { review }),
    };

    console.log('payload', payload);
    // dispatch(reviewProductAction(payload))
  };

  const cancelHandler = () => {
    dispatch({
      type: SET_REVIEW_PAYLOAD,
      payload: { showForm: false, rating: 0 },
    });
  };

  return (
    <div>
      <TextField
        placeholder={t('reviewTitle')}
        variant="standard"
        margin="dense"
        style={{ marginBottom: 10 }}
        fullWidth
        color="primary"
        onChange={(e) => setTitle(e.target.value)}
        value={title}
        error={titleError?.length > 0}
        // error={errorMsg?.field == 'title'}
        helperText={titleError}
      />
      <TextField
        placeholder={t('description')}
        variant="outlined"
        margin="dense"
        fullWidth
        multiline
        color="primary"
        minRows={5}
        onChange={(e) => setReview(e.target.value)}
        value={review}
        error={reviewError?.length > 0}
        helperText={reviewError}
      />
      <Center style={{ marginTop: 10 }}>
        <BaseButton
          text={t('cancel')}
          style={{ width: 150, margin: '0 10px' }}
          textStyle={{ fontSize: window.innerWidth <= 370 && 12 }}
          onClick={cancelHandler}
        />
        <BaseButton
          text={t('submit')}
          style={{ width: 150, margin: '0 10px' }}
          textStyle={{ fontSize: window.innerWidth <= 370 && 12 }}
          color="secondary"
          onClick={submitHandler}
        />
      </Center>
    </div>
  );
}

export default ReviewForm;
