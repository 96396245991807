import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FormikButton from 'src/components/FormUI/FormikButton';
import FormikInput from 'src/components/FormUI/FormikInput';
import FormikSelect from 'src/components/FormUI/FormikSelect';
import FormikPicker from 'src/components/FormUI/FormikPicker';
import { updateProfileAction } from 'src/store/actions/user.actions';
import Loading from 'src/components/base/Loading';

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  birthdate: '',
  gender: '',
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),

  last_name: Yup.string().required('Required'),

  // country: Yup.string().required('Required'),
  // birthdate: Yup.string().required('Required'),

  birthdate: Yup.date()
    // .transform(parseDateString)
    // .max(new Date())
    .nullable()
    .required('Required'),

  // gender: Yup.string(),
  gender: Yup.string().required('Required'),

  email: Yup.string().email('Invalid email format').required('Required'),

  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
});

export default function UserInfo() {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.authReducer);
  const { loading } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState([]);

  const onSubmit = async (values) => {
    if (userInfo) {
      let payload = {};
      Object.entries(values).forEach(([key, value]) => {
        if (values[key] !== userInfo[key]) payload[key] = value;
      });
      // console.log('payload', payload);
      Object.keys(payload).length &&
        dispatch(updateProfileAction(payload, userInfo.id));
    }
  };

  useEffect(() => {
    if (userInfo) {
      Object.keys(initialValues).forEach(
        (key) => userInfo[key] && (initialValues[key] = userInfo[key])
      );
    }
  }, [userInfo]);

  useEffect(() => {
    const genderOptions = [
      {
        name: t('male'),
        value: 'M',
      },
      {
        name: t('female'),
        value: 'F',
      },
    ];

    const fields = [
      {
        name: 'first_name',
        label: 'fname',
        Component: FormikInput,
      },
      {
        name: 'last_name',
        label: 'lname',
        Component: FormikInput,
      },
      {
        name: 'email',
        label: 'email',
        Component: FormikInput,
      },
      {
        name: 'phone',
        label: 'phone',
        Component: FormikInput,
      },
      {
        name: 'birthdate',
        label: 'birthDate',
        Component: FormikPicker,
      },
      {
        name: 'gender',
        label: 'gender',
        Component: FormikSelect,
        options: genderOptions,
      },
    ];

    setFormFields(fields);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form>
          <Grid container spacing={2}>
            {formFields.map(({ label, name, Component, options }) => (
              <Grid key={name} item xs={12} sm={6}>
                <Component label={t(label)} name={name} options={options} />
              </Grid>
            ))}
          </Grid>
          <Box display="flex" mt={2}>
            <Box flexGrow={1} />
            <FormikButton
              text={t('updateDetails')}
              style={{
                width: window.innerWidth > 599 ? 230 : '100%',
                margin: '10px 0',
              }}
              textStyle={{ fontSize: window.innerWidth <= 599 && 16 }}
            />
          </Box>
        </Form>
      </Formik>
    </>
  );
}
