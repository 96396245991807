import { localStoragePrefix, USER_LANG, DEFAULT_LANG } from 'src/config';
import {
  GET_USER_ORDERS_REQUEST,
  GET_USER_ORDERS_SUCCESS,
  GET_USER_ORDERS_FAIL,
  GET_COUNTRY_INFO_SUCCESS,
  GET_CURRENCY_INFO_REQUEST,
  GET_CURRENCY_INFO_SUCCESS,
  GET_CURRENCY_INFO_FAIL,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  CONTACT_TECH_SUPPORT_REQUEST,
  CONTACT_TECH_SUPPORT_SUCCESS,
  CONTACT_TECH_SUPPORT_FAIL,
} from '../constants/user.types';

const initialState = {
  orders: null,
  currency: false,
  error: null,

  loading: false,
  loadCurrency: false,
  currencyInfo: null,
  currencyError: null,
  modalVisible: false,
  deviceType: 1,
  lang: localStorage.getItem(localStoragePrefix + USER_LANG) || DEFAULT_LANG,
  accountTabs: [
    {
      label: 'editProfile',
      iconName: 'edit',
      // icon: Edit,
    },
    {
      label: 'orderHistory',
      // icon: Shop,
      iconName: 'history',
    },
    {
      label: 'returnExchange',
      // icon: Edit,
      iconName: 'return',
    },
    {
      label: 'myAddresses',
      // icon: Room,
      iconName: 'mark',
    },
    {
      label: 'wishlist',
      // icon: Edit,
      iconName: 'wishlist',
    },
    {
      label: 'support',
      // icon: Info,
      iconName: 'support',
    },
  ],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_ORDERS_REQUEST:
      return { ...state, loading: true };

    case GET_USER_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
      };

    case GET_COUNTRY_INFO_SUCCESS:
      return {
        currency: true,
      };

    case GET_USER_ORDERS_FAIL:
      return { ...state, error: action.payload, loading: false };

    case GET_CURRENCY_INFO_REQUEST:
      return { ...state, loadCurrency: true };

    case GET_CURRENCY_INFO_SUCCESS:
      return {
        ...state,
        currencyInfo: action.payload,
        loadCurrency: false,
      };

    case GET_CURRENCY_INFO_FAIL:
      return { ...state, currencyError: action.payload, loadCurrency: false };

    // update user info
    case UPDATE_PROFILE_REQUEST:
      return { ...state, loading: true };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case UPDATE_PROFILE_FAIL:
      return { ...state, error: action.payload, loading: false };

    // contact message
    case CONTACT_TECH_SUPPORT_REQUEST:
      return { ...state, loading: true };

    case CONTACT_TECH_SUPPORT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case CONTACT_TECH_SUPPORT_FAIL:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};

export default userReducer;
