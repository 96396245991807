import {
  Box,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'src/components/base/Loading';
import { SET_CHECKOUT_PAYLOAD } from 'src/store/constants/cart.types';
import { convertToArabicNumbers } from 'src/utils';
import ContentCard from './ContentCard';

const useStyles = makeStyles((theme) => ({
  summaryLabel: {
    fontSize: 14,
    fontFamily: 'sfUiDisplayRegular',
    color: theme.custom.myTheme.grey3,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 300 ? 14 : 12,
    },
  },
  summaryInfo: {
    fontSize: 14,
    fontFamily: 'sfUiDisplayRegular',
    direction: 'ltr',
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 300 ? 14 : 12,
    },

    color: theme.palette.primary.main,
  },
  appliedCoupon: {
    fontSize: 14,
    fontFamily: 'sfUiDisplayBold',
    color: '#74CE58',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  itemsQty: {
    fontSize: 16,
    marginBottom: 3,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 300 ? 14 : 12,
    },
  },
}));

function Summary({ activeStep, orderDetail }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currencyInfo } = useSelector((state) => state.userReducer);
  const { cartItems, checkoutPayload } = useSelector(
    (state) => state.cartReducer
  );
  const { shippingInfo, loadingShipping } = useSelector(
    (state) => state.aramixReducer
  );

  const { coupon } = useSelector((state) => state.couponReducer);

  const {
    shipping,
    otherShipping,
    total,
    subTotal,
    otherSubtotal,
    resetShipping,
    discountValue,
    shippingCalsLoading,
    validCode,
  } = checkoutPayload;

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.success.main,
      color: '#fff',
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
    arrow: {
      color: theme.palette.success.main,
    },
  }))(Tooltip);

  const renderRow = (key, value, isCoupon) => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={1}
    >
      <LightTooltip
        arrow
        // classes={classes.arrow}
        title={isCoupon ? coupon[0].code : ''}
      >
        <Typography
          className={classes[isCoupon ? 'appliedCoupon' : 'summaryLabel']}
        >
          {key}
        </Typography>
      </LightTooltip>

      <Typography
        color="primary"
        className={classes[isCoupon ? 'appliedCoupon' : 'summaryInfo']}
        style={{ direction: 'ltr' }}
      >
        {value}
      </Typography>
    </Box>
  );

  const trimedCouponName = (couponName) => {
    if (couponName.length > 10) return couponName.slice(0, 10) + '...';
    else return couponName;
  };

  useEffect(() => {
    if (
      shippingInfo &&
      !shippingInfo.HasErrors &&
      resetShipping &&
      !orderDetail
    ) {
      const shipping =
        coupon?.length && coupon[0].free_shipping
          ? 0
          : (shippingInfo.TotalAmount.Value * currencyInfo.to).toFixed(2);
      const otherShipping = shippingInfo.TotalAmount.Value.toFixed(2);
      dispatch({
        type: SET_CHECKOUT_PAYLOAD,
        payload: {
          shipping,
          otherShipping,
          resetShipping: false,
        },
      });
    }
  }, [shippingInfo, resetShipping]);

  useEffect(() => {
    if (cartItems.length && !orderDetail) {
      const subtotal = cartItems.reduce(
        (a, c) =>
          a + c.product.discounted_price * c.quantity * currencyInfo.from,
        0
      );

      dispatch({
        type: SET_CHECKOUT_PAYLOAD,
        payload: {
          subTotal: subtotal.toFixed(2),
          otherSubtotal: (subtotal / currencyInfo.to).toFixed(2),
        },
      });
    } else if (cartItems?.length == 0) {
      dispatch({
        type: SET_CHECKOUT_PAYLOAD,
        payload: {
          subTotal: 0,
          otherSubtotal: 0,
        },
      });
    }
  }, [cartItems]);

  useEffect(() => {
    if (!orderDetail) {
      dispatch({
        type: SET_CHECKOUT_PAYLOAD,
        payload: {
          total: (+subTotal + +shipping - discountValue).toFixed(2),
          otherTotal: (+otherSubtotal + +otherShipping).toFixed(2),
        },
      });
    }
  }, [subTotal, shipping, discountValue]);

  if (loadingShipping || shippingCalsLoading) {
    return <Loading />;
  }

  return (
    <ContentCard title="orderSummary">
      <Typography color="secondary" className={classes.itemsQty}>
        {convertToArabicNumbers(
          (orderDetail?.quantity || cartItems.length) + '',
          i18n.dir()
        ) +
          ' ' +
          t('items')}
      </Typography>
      {renderRow(
        t('subtotal'),
        convertToArabicNumbers(
          (orderDetail?.subtotal || subTotal) + '',
          i18n.dir()
        )
      )}
      {renderRow(
        t('shipping'),
        orderDetail
          ? convertToArabicNumbers(orderDetail.shipping_value + '', i18n.dir())
          : activeStep <= 1
          ? t('calculatedAtCheckout')
          : convertToArabicNumbers(shipping + '', i18n.dir())
      )}
      {coupon?.length > 0 &&
        validCode &&
        activeStep == 2 &&
        renderRow(
          t('couponCode') +
            ' (' +
            trimedCouponName(coupon[0].code) +
            // coupon[0].code.slice(0, 10) +
            ') ' +
            t('added'),
          orderDetail
            ? orderDetail.coupon
            : coupon[0].free_shipping
            ? ' ' + t('freeShipping')
            : -convertToArabicNumbers(discountValue + '', i18n.dir()),

          true
        )}

      {coupon?.length > 0 &&
        validCode &&
        activeStep == 2 &&
        coupon[0].persentage &&
        renderRow(
          t('couponCode') +
            ' (' +
            trimedCouponName(coupon[0].code) +
            // coupon[0].code.slice(0, 10) +
            ') ' +
            t('added'),
          convertToArabicNumbers(discountValue * -1 + '', i18n.dir()),

          true
        )}
      {renderRow(
        t('total'),
        orderDetail
          ? convertToArabicNumbers(orderDetail.price + '', i18n.dir())
          : activeStep <= 1
          ? t('calculatedAtCheckout')
          : convertToArabicNumbers(total + '', i18n.dir())
      )}
    </ContentCard>
  );
}

export default memo(Summary);
