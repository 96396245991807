import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loading from 'src/components/base/Loading';
import { getOrderHistoryDetailAction } from 'src/store/actions/order.actions';

import TrackingCard from './TrackingCard';

const OrderTracking = () => {
  const dispatch = useDispatch();
  const { loadingDetail } = useSelector((state) => state.orderReducer);

  const searchParams = new URLSearchParams(useLocation().search);
  const orderId = searchParams.get('orderId');

  useEffect(() => {
    dispatch(getOrderHistoryDetailAction(orderId));
  }, [orderId]);

  if (loadingDetail) {
    return <Loading />;
  }

  return (
    <div style={{ height: '90%' }}>
      <TrackingCard />
    </div>
  );
};

export default OrderTracking;
