import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useClasses = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.custom.myTheme.grey2}`,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
    // width: '40%',
  },
  titleCell: {
    backgroundColor: theme.custom.myTheme.grey1,
    padding: 10,
    width: '40%',
  },
  contentCell: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 20px',
  },
  text: {
    color: theme.custom.myTheme.grey2,
    fontFamily: 'roboto',
    fontWeight: 400,
    // fontSize: 15,
  },
}));

export default function ProductFeatures({ ...rest }) {
  const { t, i18n } = useTranslation();
  const classes = useClasses();
  const { product } = useSelector((state) => state.productReducer);
  const [featuresDetails, setFeaturesDetails] = useState([]);

  const lang = i18n.language.split('-')[0];

  const TblCell = ({ title, content }) => (
    <div className={classes.root}>
      <div className={classes.titleCell}>
        <Typography variant="body1" color="textSecondary">
          {title}
        </Typography>
      </div>

      <div className={classes.contentCell}>
        <Typography variant="body1" color="textSecondary">
          {content}
        </Typography>
      </div>
    </div>
  );

  useEffect(() => {
    if (product) {
      const featuresDetails = [
        {
          title: t('publisher'),
          content:
            lang == 'en'
              ? product.publisher.english_title
              : lang == 'ar'
              ? product.publisher.arabic_title
              : null,
        },
        {
          title: t('publishDate'),
          content: product.years,
        },
        {
          title: t('language'),
          content:
            lang == 'en'
              ? product.book_language.english_title
              : lang == 'ar'
              ? product.book_language.arabic_title
              : null,
        },
        {
          title: t('bookDimensions'),
          content: product.dimensions,
        },
        {
          title: t('shoppingWeight'),

          content: product.shipping_weight,
        },
        {
          title: 'ISBN-13',
          content: product.isbn,
        },
        {
          title: 'SKU',
          content: product.sku,
        },
      ];

      setFeaturesDetails(featuresDetails);
    }
  }, [product]);

  return (
    <div {...rest}>
      <Typography variant="h6" color="primary" style={{ marginBottom: 10 }}>
        {t('featuresDetails')}
      </Typography>
      {featuresDetails.map((feature) => (
        <TblCell
          key={Math.random().toString()}
          title={feature.title}
          content={feature.content}
        />
      ))}
    </div>
  );
}
