import { Box, Typography } from '@material-ui/core';
import { FiberManualRecordRounded } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'src/components/base/Loading';
// import {
//   getAppSettingsActions,
//   getRefundsPolicyActions,
//   getReturnsPolicyActions,
// } from 'src/store/actions/app.settings.actions';

export default function ReturnExchange({ title }) {
  const { t, i18n } = useTranslation();

  const {
    loading,
    appSettings,
    refundsPolicy,
    arReturnsPolicy,
    returnsPolicy,
    arRefundsPolicy,
  } = useSelector((state) => state.appSettingsReducer);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   !returnsPolicy && dispatch(getReturnsPolicyActions());
  //   !refundsPolicy && dispatch(getRefundsPolicyActions());
  // }, []);

  // useEffect(() => {
  //   appSettings?.length == 0 && dispatch(getAppSettingsActions());
  // }, []);

  const titleStyle = {
    fontSize:
      window.innerWidth >= 600 ? 28 : window.innerWidth >= 400 ? 25 : 20,
  };

  if (loading || !returnsPolicy || !refundsPolicy) {
    return <Loading />;
  }

  return (
    <div style={{ height: '100%' }}>
      <Typography
        variant="h4"
        style={{
          fontFamily: 'sfUiTextRegular',
          fontSize:
            window.innerWidth >= 600 ? 34 : window.innerWidth >= 400 ? 25 : 20,
        }}
        color="primary"
      >
        {title}
      </Typography>
      <div style={{ marginTop: window.innerWidth > 375 ? 50 : 20 }}>
        <Box>
          <Typography style={titleStyle} color="primary" gutterBottom>
            {t('returns')}
          </Typography>
          <div style={{ display: 'flex' }}>
            <FiberManualRecordRounded
              style={{ color: 'black', marginRight: 30 }}
            />
            <Typography variant="body1" color="textSecondary">
              {i18n.language == 'en'
                ? returnsPolicy?.value
                : arReturnsPolicy?.value}

              {/* {returnsPolicy.value} */}
            </Typography>
          </div>
        </Box>

        <Box mt={2}>
          <Typography style={titleStyle} color="primary" gutterBottom>
            {t('refunds')}
          </Typography>
          <div style={{ display: 'flex' }}>
            <FiberManualRecordRounded
              style={{ color: 'black', marginRight: 30 }}
            />
            <Typography variant="body1" color="textSecondary">
              {i18n.language == 'en'
                ? refundsPolicy?.value
                : arRefundsPolicy?.value}
              {/* {refundsPolicy.value} */}
            </Typography>
          </div>
        </Box>
      </div>
    </div>
  );
}
