import {
  GET_PRODUCT_BY_ID_FAIL,
  GET_PRODUCT_BY_ID_REQUEST,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_AUTHORS_REQUEST,
  GET_AUTHORS_SUCCESS,
  GET_AUTHORS_FAIL,
  FILTER_PRODUCTS_REQUEST,
  FILTER_PRODUCTS_SUCCESS,
  FILTER_PRODUCTS_FAIL,
  GET_PRODUCT_BY_AUTHOR_ID_REQUEST,
  GET_PRODUCT_BY_AUTHOR_ID_SUCCESS,
  GET_PRODUCT_BY_AUTHOR_ID_FAIL,
  SEARCH_PRODUCTS_REQUEST,
  SEARCH_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS_FAIL,
  INC_SEARCH_PAGE,
  RESET_SEARCH_PAGE,
  // TRIGGER_REFRESHING,
  SET_SEARCH_PAGE_COUNT,
  SET_SEARCH_PAGE,
  RESET_SEARCH_PAGE_COUNT,
  INC_AUTHORS_PRODUCTS_PAGE,
  RESET_AUTHORS_PRODUCTS_PAGE,
  // TRIGGER_REFRESHING,
  SET_AUTHORS_PRODUCTS_PAGE_COUNT,
  SET_AUTHORS_PRODUCTS_PAGE,
  RESET_AUTHORS_PRODUCTS_PAGE_COUNT,
  RESET_PAGINATION,
  SET_AUTHORS_CURRENT_PAGE,
  RESET_AUTHORS_CURRENT_PAGE,
  SET_AUTHORS_PAGE_COUNT,
  GET_RELATED_PRODUCTS_REQUEST,
  GET_RELATED_PRODUCTS_SUCCESS,
  GET_RELATED_PRODUCTS_FAIL,
  GET_AUTHOR_INFO_REQUEST,
  GET_AUTHOR_INFO_SUCCESS,
  GET_AUTHOR_INFO_FAIL,
  SET_REVIEW_PAYLOAD,
  POST_REVIEW_REQ,
  POST_REVIEW_SUCCESS,
  POST_REVIEW_FAIL,
} from '../constants/product.types';

const initialState = {
  loading: false,
  error: null,
  product: null,
  relatedProducts: null,

  // products
  products_limit: 20,
  products_page: 0,
  products_page_count: 1,
  filteredProducts: null,

  // authors
  limit: 50,
  authors_current_page: 0,
  authors_page_count: 1,
  authorsList: null,
  // authorProducts: null,

  // authors products
  // limit: 50,
  page: 0,
  page_count: 1,
  // authors: null,
  authorProducts: null,

  reviewPayload: {
    rating: 0,
    showForm: false,
    reset: false,
  },
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PAGINATION:
      return {
        limit: 50,
        page: 0,
        page_count: 1,
        products_limit: 20,
        products_page: 0,
        products_page_count: 1,
        filteredProducts: null,

        authors_current_page: 0,
        authors_page_count: 1,
        authorsList: null,
      };

    // get product by id
    case GET_PRODUCT_BY_ID_REQUEST:
      return { ...state, loading: true, product: null };

    case GET_PRODUCT_BY_ID_SUCCESS:
      return { ...state, product: action.payload, loading: false };

    case GET_PRODUCT_BY_ID_FAIL:
      return { ...state, error: action.error, loading: false };

    // get related Products
    case GET_RELATED_PRODUCTS_REQUEST:
      return { ...state, loading: true, relatedProducts: null };

    case GET_RELATED_PRODUCTS_SUCCESS:
      return { ...state, relatedProducts: action.payload, loading: false };

    case GET_RELATED_PRODUCTS_FAIL:
      return { ...state, error: action.error, loading: false };

    // get product by author id
    case GET_PRODUCT_BY_AUTHOR_ID_REQUEST:
      return { ...state, loading: true, product: null };

    case GET_PRODUCT_BY_AUTHOR_ID_SUCCESS:
      let newObj = {};
      newObj = {
        ...state,
        // authorProducts:
        //   !state.authorProducts || action.reset
        //     ? action.payload
        //     : [...state.authorProducts, ...action.payload],
        authorProducts: action.payload,
        loading: false,
      };

      // newObj['authorProducts' + action.page] = action.payload;
      return newObj;

    case GET_PRODUCT_BY_AUTHOR_ID_FAIL:
      return { ...state, error: action.error, loading: false };

    // get authors
    case GET_AUTHORS_REQUEST:
      return { ...state, loading: true };

    case GET_AUTHORS_SUCCESS:
      return {
        ...state,
        // authorsList:
        //   !state.authorsList || action.reset
        //     ? action.payload
        //     : [...state.authorsList, ...action.payload],
        authorsList: action.payload,

        loading: false,
      };

    case GET_AUTHORS_FAIL:
      return { ...state, error: action.error, loading: false };

    // filter products
    case FILTER_PRODUCTS_REQUEST:
      return { ...state, products: null, loading: true };

    case FILTER_PRODUCTS_SUCCESS:
      let newObj1 = {};
      newObj1 = {
        ...state,
        // filteredProducts:
        //   !state.filteredProducts || action.reset
        //     ? action.payload
        //     : [...state.filteredProducts, ...action.payload],
        filteredProducts: action.payload,
        loading: false,
      };
      newObj1[action.page] = action.payload;

      return newObj1;

    case FILTER_PRODUCTS_FAIL:
      return { ...state, error: action.error, loading: false };

    // search products
    case SEARCH_PRODUCTS_REQUEST:
      return { ...state, loading: true, product: null };

    case SEARCH_PRODUCTS_SUCCESS:
      return { ...state, searchResult: action.payload, loading: false };

    case SEARCH_PRODUCTS_FAIL:
      return { ...state, error: action.error, loading: false };

    // search pagination
    case INC_SEARCH_PAGE:
      return { ...state, page: state.page + 1 };

    case RESET_SEARCH_PAGE:
      return { ...state, page: 0 };

    case SET_SEARCH_PAGE:
      return { ...state, page: action.payload };

    case SET_SEARCH_PAGE_COUNT:
      return { ...state, page_count: action.payload };

    case RESET_SEARCH_PAGE_COUNT:
      return { ...state, page_count: 1 };

    // case TRIGGER_REFRESHING:
    //   return { ...state, refreshing: true };

    // author's products pagination
    // case INC_AUTHORS_PRODUCTS_PAGE:
    //   return { ...state, page: state.products_page + 1 };

    // ======= authors List =========
    case SET_AUTHORS_CURRENT_PAGE:
      console.log('--', action.payload);
      return { ...state, authors_current_page: action.payload };

    case RESET_AUTHORS_CURRENT_PAGE:
      return { ...state, authors_current_page: 0 };

    case SET_AUTHORS_PAGE_COUNT:
      return { ...state, authors_page_count: action.payload };

    // ======= authors products =========
    case RESET_AUTHORS_PRODUCTS_PAGE:
      return { ...state, products_page: 0 };
    // return { ...state, products_page: 1 };

    case SET_AUTHORS_PRODUCTS_PAGE:
      return { ...state, products_page: action.payload };

    case SET_AUTHORS_PRODUCTS_PAGE_COUNT:
      return { ...state, products_page_count: action.payload };

    // get author info
    case GET_AUTHOR_INFO_REQUEST:
      return { ...state, loading: true };

    case GET_AUTHOR_INFO_SUCCESS:
      return {
        ...state,
        authorInfo: action.payload,
        loading: false,
      };

    case GET_AUTHOR_INFO_FAIL:
      return { ...state, error: action.error, loading: false };

    case SET_REVIEW_PAYLOAD:
      return {
        ...state,
        reviewPayload: { ...state.reviewPayload, ...action.payload },
      };

    // post review
    case POST_REVIEW_REQ:
      return { ...state, loading: true };

    case POST_REVIEW_SUCCESS:
      return { ...state, loading: false };

    case POST_REVIEW_FAIL:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
};
export default productReducer;
