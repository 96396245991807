import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import BaseContainer from 'src/components/base/BaseContainer';
// import BasePage from 'src/components/base/BasePage';
import { RESET_PAGINATION } from 'src/store/constants/product.types';
import { searchProductsAction } from 'src/store/actions/search.actions';
// import {
//   // INC_SEARCH_PAGE,
//   RESET_SEARCH_PAGE,
//   TRIGGER_REFRESHING,
//   SET_SEARCH_PAGE,
// } from 'src/store/constants/search.types';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from 'src/components/base/Loading';
import { Grid, Typography } from '@material-ui/core';
import ProductItem from 'src/components/ProductItem';
import Center from 'src/components/Center';

function SearchResult() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation();
  // const {
  //   loading,
  //   authorsList,
  //   authorProducts,
  //   authorInfo,
  //   authors_page_count,
  //   // authors_current_page,
  // } = useSelector((state) => state.productReducer);

  const { loading, error, searchResult, page, page_count, refreshing } =
    useSelector((state) => state.searchReducer);

  const lang = i18n.language.split('-')[0];
  // const [searchText, setSearchText] = useState('');

  const searchParams = new URLSearchParams(useLocation().search);
  const [current_page, setCurrent_page] = useState(
    searchParams.get('page') || 1
  );

  const searchQuery = searchParams.get('keyword');

  const handlePageChange = (_, page) => {
    setCurrent_page(page);

    let url = `/search?page=${page}`;
    searchQuery?.trim() && (url += `&keyword=${searchQuery?.trim()}`);
    history.push(url);
    // history.push(`/authors?page=${page}`);
  };

  useEffect(() => {
    dispatch(
      searchProductsAction({
        searchText: searchQuery,
        reset: true,
        lang,
        startPage: 1,
      })
    );
  }, [searchQuery]);

  // useEffect(() => {
  //   // if (!authorId) {
  //     // if (!reset) {

  //     // current_page &&
  // dispatch(
  //   searchProductsAction({ search, reset: true, lang })
  //   getAuthorsAction({
  //     searchText: searchQuery?.trim(),
  //     lang,
  //     startPage: current_page,
  //   })
  //       // );
  //     // }
  //     // !authorsList?.length && dispatch(getAuthorsAction());
  //   // }
  // }, [ current_page]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_PAGINATION });
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={2}>
      {searchResult?.length ? (
        searchResult?.map((item) => (
          <Grid key={Math.random().toString()} item xs={12} md={3}>
            <ProductItem item={item.product_id || item} />
          </Grid>
        ))
      ) : (
        <Center style={{ flex: 1, height: 300 }}>
          <Typography variant="h5" color="secondary">
            no products found
          </Typography>
        </Center>
      )}
    </Grid>
  );
}

export default SearchResult;
