import React, { memo } from 'react';
import { MenuItem, makeStyles } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import BaseInput from '../base/BaseInput';

const useStyles = makeStyles((theme) => ({
  option: {
    color: theme.palette.primary.main,
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}));

const FormikSelect = ({
  name,
  options,
  setCountryCode,
  autoComplete,
  ...otherProps
}) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
    setCountryCode && setCountryCode(value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  const renderSelect = () => {
    return (
      <BaseInput {...configSelect}>
        {options?.map((option) => (
          <MenuItem
            key={Math.random().toString()}
            value={option.id || option.value || option.Code || option}
            // style={{ border: '1px solid red' }}
          >
            <span className={classes.option}>
              {option.name || option.Name || option.IsoCode || option}
            </span>
          </MenuItem>
        ))}
      </BaseInput>
    );
  };

  return renderSelect();
};

export default memo(FormikSelect);
