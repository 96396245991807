import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMainPageAction } from '../../store/actions/page.action';
import { useHistory } from 'react-router-dom';

import RenderPage from 'src/components/RenderPage';
import Loading from '../../components/base/Loading';

import BasePage from 'src/components/base/BasePage';
import BaseCarousel from 'src/components/base/BaseCarousel';
import { SwiperSlide } from 'swiper/react/swiper-react';

export default function HomePage() {
  const pageReducer = useSelector((state) => state.pageReducer);
  const { content } = pageReducer;
  const dispatch = useDispatch();
  const { push } = useHistory();

  const navigationHandler = (slide) => {
    if (slide.navigate == 'Details') push(`/products/${slide.id}`);
    else if (slide.navigate == 'categories')
      push(`/category-details/${slide.id}?page=sub_category_detail`);
    else if (slide.navigate == 'Celebrities')
      push(`/category-details/${slide.id}?page=celebrity_detail`);
    else if (slide.navigate == 'tags')
      push(`/filter/product_tag/tag_id/${slide.id}`);
  };

  useEffect(() => {
    dispatch(getMainPageAction('main'));
  }, []);

  const renderBanner = (data) => (
    <BaseCarousel
      key={Math.random().toString()}
      style={{
        marginBottom: 20,
        // maxHeight: 540,
        padding: 0,
      }}
      showNavigation={false}
      showPagination={true}
      slidesPerView={1}
      // autoplay={{ delay: 100 }}
      autoplay
    >
      {data?.map((slide) => (
        <SwiperSlide key={Math.random().toString()}>
          <img
            onClick={() => navigationHandler(slide)}
            style={{
              width: '100%',
              maxHeight: 500,
              objectFit: 'cover',
              cursor: 'pointer',
              marginBottom: 40,
            }}
            src={slide[window.innerWidth > 1000 ? 'wsdata' : 'data']}
            // src={slide.data}
          />
        </SwiperSlide>
      ))}
    </BaseCarousel>
  );

  if (!content) {
    return <Loading />;
  }

  return (
    <BasePage
      style={{
        paddingTop: 0,
      }}
    >
      {content &&
        content?.map((item) =>
          item.type == 'banner' ? (
            renderBanner(item.data)
          ) : (
            <RenderPage
              key={Math.random().toString()}
              section={item}
              from="home"
            />
          )
        )}
    </BasePage>
  );
}
