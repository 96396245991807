import React from 'react';
import { useField } from 'formik';
import BaseInput from '../base/BaseInput';

function FormikInput(props) {
  const { name, ...otherProps } = props;
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return <BaseInput {...configTextfield} />;
}

export default FormikInput;
