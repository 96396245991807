import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { successPayment } from 'src/store/actions/cart.actions';

export default function PaymentStatusRedirect() {
  const dispatch = useDispatch();
  const { order_no, reference_number } = useParams();
  const { push } = useHistory();

  const updateNavigationParams = () => {
    if (order_no && reference_number) {
      let data = {
        order_no,
        reference_number,
      };

      dispatch(successPayment(data, push));
    }
  };

  useEffect(() => {
    updateNavigationParams();
  }, []);
  return <div></div>;
}
