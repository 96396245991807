export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAIL = "GET_CITIES_FAIL";

export const CALCULATE_SHIPPING_REQUEST = "CALCULATE_SHIPPING_REQUEST";
export const CALCULATE_SHIPPING_SUCCESS = "CALCULATE_SHIPPING_SUCCESS";
export const CALCULATE_SHIPPING_FAIL = "CALCULATE_SHIPPING_FAIL";

export const GET_ORDER_TRACKING_REQUEST = "GET_ORDER_TRACKING_REQUEST";
export const GET_ORDER_TRACKING_SUCCESS = "GET_ORDER_TRACKING_SUCCESS";
export const GET_ORDER_TRACKING_FAIL = "GET_ORDER_TRACKING_FAIL";
