import ApiService from 'src/services/api.service';
import {
  GET_MAIN_FAIL,
  GET_MAIN_REQUEST,
  GET_MAIN_SUCCESS,
  SET_LOADER,
  GET_NAV_CATS_REQUEST,
  GET_NAV_CATS_SUCCESS,
  GET_NAV_CATS_FAIL,
} from '../constants/main.type';
// import { getFromAsyncStorage } from '../../helpers/getFromAsyncStorage';

export const getMainPageAction = (page, id) => async (dispatch, getState) => {
  try {
    // const langInfo = await getFromAsyncStorage('langInfo');
    // const lang = langInfo;
    const {
      userReducer: { lang },
    } = getState();
    // const lang = "ar";
    // let url;
    // console.log(await Api);

    // if (lang == "ar") url = `custom/main_ar?page=${page}&id=${id}`;
    // else if (lang == "en") url = `custom/main?page=${page}&id=${id}`;
    let url = `custom/${
      lang == 'ar' ? 'main_ar' : 'main'
    }?page=${page}&id=${id}`;
    dispatch({ type: GET_MAIN_REQUEST });
    const { data } = await ApiService.get(url);

    let pageTitle;
    if (id) pageTitle = `${page}_${id}`;
    else pageTitle = `${page}`;

    dispatch({ type: GET_MAIN_SUCCESS, payload: data, page: pageTitle });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_MAIN_FAIL, error: message });
  }
};

export const resetContent = () => async (dispatch) => {
  try {
    dispatch({ type: GET_MAIN_REQUEST });
  } catch (error) {}
};

export const setLoaderAction = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER });
  } catch (error) {}
};

export const getNavCatsAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_NAV_CATS_REQUEST });
    // filter[id][neq]=103&
    let url = `items/categories?fields=*,child_categories.*&filter[parent_category][null]&sort=sequence`;
    const { data } = await ApiService.get(url);

    let orderedCats = data.data.sort(function (a, b) {
      return a.sequence - b.sequence;
    });

    orderedCats = orderedCats.map((child) => {
      let ordered_children = child.child_categories.sort(
        (a, b) => a.sequence - b.sequence
      );

      return { ...child, child_categories: ordered_children };
    });

    // console.log(orderedCats);

    dispatch({ type: GET_NAV_CATS_SUCCESS, payload: orderedCats });
    // dispatch({ type: GET_NAV_CATS_SUCCESS, payload: data.data });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_NAV_CATS_FAIL, error: message });
  }
};
