import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      color: 'white',
      height: 0,
    },
  });

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, closeIcon, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        variant="button"
        style={{
          color: 'white',
          fontFamily: 'roboto',
          fontWeight: 400,
          fontSize: window.innerWidth > 400 ? 15 : 13,
        }}
      >
        {children}
      </Typography>
      {closeIcon && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    // width: 400,
    display: 'flex',
    justifyContent: 'center',
  },
}))(MuiDialogContent);

export default function Dialog(props) {
  const {
    open,
    closeDialog,
    title,
    maxWidth,
    closeIcon = true,
    children,
    ...rest
  } = props;

  const handleClose = () => {
    closeDialog();
    // setOpen(false);
  };

  return (
    <MuiDialog
      open={open}
      maxWidth={maxWidth || 'sm'}
      // fullWidth={true}
      onClose={!title ? handleClose : () => null}
      style={{ zIndex: 301 }}
      {...rest}
      // style={{ display: 'flex', justifyContent: 'center' }}
    >
      <>
        {title && (
          <DialogTitle onClose={handleClose} closeIcon={closeIcon}>
            {title}
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
      </>
    </MuiDialog>
  );
}
