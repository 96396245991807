import { makeStyles, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getOrderTrackingAction } from 'src/store/actions/aramix.actions';
import Center from 'src/components/Center';

const useClasses = makeStyles((theme) => ({
  cartItem: {
    width: '100%',
    border: `.5px solid ${theme.custom.myTheme.grey3}`,
    borderTop: 0,
    borderRadius: 10,
  },
  cartTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 3%',
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    height: 50,
    [theme.breakpoints.down('xs')]: {
      padding: '0 7%',
      height: 42,
      borderRadius: 8,
    },
  },
  titleTxt: {
    fontFamily: 'roboto',
    fontSize: 15,
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth <= 350 && 13,
    },
  },
  cartBody: {
    padding: '1.2% 3%',
    padding: '3%',
    [theme.breakpoints.down('xs')]: {
      padding: '7%',
    },
  },
  active: {
    backgroundColor: theme.custom.myTheme.grey3,
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
  },
  trackShipmentBtn: {
    backgroundColor: theme.palette.secondary.main,
    padding: '4px 0',
    padding: '0 12px',
    borderRadius: 10,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textSf15: {
    fontFamily: 'sfUiDisplayMedium',
    fontSize: 15,
    color: theme.custom.myTheme.grey3,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth <= 350 && 12,
    },
  },

  rowJustifyCenter: {
    display: 'flex',

    justifyContent: 'center',
  },
  rowAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  stepLabel: {
    fontFamily: 'sfUiDisplayMedium',
    fontSize: 10,
    color: theme.custom.myTheme.grey3,
    marginTop: 3,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth <= 350 && 8,
    },
  },
  shippedStepContentContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0',
  },
  trackShipmentTxt: {
    fontFamily: 'sfUiDisplaySemiBold',
    fontSize: 15,
    color: 'white',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth <= 350 && 12,
    },
  },
  textSfBold: {
    fontFamily: 'sfUiDisplaySemiBold',
    fontSize: 16,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth <= 350 && 12,
    },
  },
  descText: {
    fontFamily: 'sfUiDisplayMedium',
    fontSize: 14,
    color: theme.custom.myTheme.grey3,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth <= 350 && 10,
    },
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: theme.custom.myTheme.grey2,
    margin: '10px 0',
  },
}));

const STEP_ICON_SIZE = window.innerWidth > 350 ? 55 : 30;
export default function TrackingCard() {
  const { t } = useTranslation();
  const classes = useClasses();
  const [activeStep, setActiveStep] = useState(1);
  const dispatch = useDispatch();
  const theme = useTheme();

  const { orderDetail: order } = useSelector((state) => state.orderReducer);
  const { trackingData, error } = useSelector((state) => state.aramixReducer);

  useEffect(() => {
    if (order) {
      setActiveStep(
        order.order_status == 'shipped'
          ? 2
          : order.order_status == 'complete'
          ? 3
          : 1
      );

      dispatch(getOrderTrackingAction(order.id));
    }
  }, [order]);

  const renderOrderDesc = (key, dateTime, country, idx) => {
    const d = parseInt(dateTime.slice(6, -2));
    const event = new Date(d);

    return (
      <div key={Math.random().toString()}>
        <Typography className={classes.textSfBold} style={{ marginBottom: 5 }}>
          {key}
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography className={classes.descText}>
            {format(
              event,
              window.innerWidth > 370 ? 'MMMM do yyyy' : 'MMM do yyyy'
            )}
          </Typography>
          <Typography className={classes.descText}>
            {format(event, 'HH:MM:SS')}
          </Typography>
          <Typography className={classes.descText}>{country}</Typography>
        </div>
        {idx + 1 !== trackingData.length && (
          <div className={classes.separator} />
        )}
      </div>
    );
  };

  const getSteps = () => ['processing', 'shipped', 'complete'];
  // const getSteps = () => ['orderPlaced', 'shipped', 'delivered'];

  const StepIcon = ({ currentStep, label }) => {
    const separatorStyle = {
      width: window.innerWidth > 350 ? 50 : 30,
      height: 2,
      backgroundColor:
        (currentStep == 1 && activeStep > 1) ||
        (currentStep == 2 && activeStep > 2)
          ? theme.palette.secondary.main
          : theme.custom.myTheme.grey2,
      margin: '0 3px',
      marginTop: -10,
    };

    const RenderIcon = () => (
      <img
        style={{
          height: STEP_ICON_SIZE,
          width: STEP_ICON_SIZE,
          objectFit: 'cover',
        }}
        src={
          currentStep == 1
            ? '/images/icons/Icons-61.png'
            : currentStep == 2
            ? activeStep > 1
              ? '/images/icons/Icons-63.png'
              : '/images/icons/Icons-62.png'
            : activeStep > 2
            ? '/images/icons/Icons-63.png'
            : '/images/icons/Icons-64.png'
        }
      />
    );

    return (
      <div className={classes.rowAlignCenter}>
        <Center style={{ flexDirection: 'column' }}>
          <RenderIcon />

          <Typography className={classes.stepLabel}>{t(label)}</Typography>
        </Center>
        {currentStep < 3 && <div style={separatorStyle} />}
      </div>
    );
  };

  const ShippedStep = () => (
    <div>
      <div className={classes.shippedStepContentContainer}>
        <div style={{ width: 100, height: 42 }}>
          <img
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            src="/images/icons/Icons-76.png"
          />
        </div>
        <div style={{ width: 10 }} />
        <Typography className={classes.textSf15}>
          {/* {order.shipment_id} */}
          {t('trackingNumber')}: {order.shipment_id}
        </Typography>
      </div>
      <div>
        <div className={classes.separator} />
        <Typography
          style={{ fontSize: 15, color: theme.custom.myTheme.grey3 }}
          className={classes.textSfBold}
        >
          {t('currentStatus')}:
        </Typography>
        <div className={classes.separator} />

        {trackingData?.map((value, index) =>
          renderOrderDesc(
            value.UpdateDescription,
            value.UpdateDateTime,
            value.UpdateLocation,
            index
          )
        )}
      </div>
    </div>
  );

  function getStepContent(step) {
    switch (step) {
      case 1:
        return null;
      case 2:
      case 3:
        return <ShippedStep />;

      default:
        return <Typography color="error">something went wrong</Typography>;
    }
  }

  const steps = getSteps();

  if (!order) {
    return null;
  }
  return (
    <div style={{ height: '100%', marginTop: 50 }}>
      {error ? (
        <Center style={{ height: '100%' }}>
          <Typography color="error">Something went wrong</Typography>
        </Center>
      ) : (
        <div className={classes.cartItem}>
          <div className={classes.cartTitle}>
            <Typography className={classes.titleTxt} noWrap>
              {t('orderNum')}: {order.id}
            </Typography>
            <Typography className={classes.titleTxt} noWrap>
              {order.date.slice(0, 10)}
            </Typography>
          </div>

          <div className={classes.cartBody}>
            {activeStep == 1 && (
              <Typography
                className={classes.textSf15}
                style={{ marginBottom: 10 }}
              >
                {t('orderIsBeingProcessed')}
              </Typography>
            )}

            {/* stepper */}
            <div className={classes.rowJustifyCenter}>
              <div style={{ width: '100%' }}>
                <div className={classes.rowJustifyCenter}>
                  {steps.map((label, idx) => (
                    <StepIcon key={label} currentStep={idx + 1} label={label} />
                  ))}
                </div>

                {getStepContent(activeStep)}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
