import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    background: theme.custom.myTheme.grey1,
    borderRadius: 10,
  },
  divider: {
    background: theme.palette.primary.main,
    margin: window.innerWidth > 300 ? '12px 0' : '8px 0',
  },
  title: {
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 300 ? 16 : 14,
    },
  },
}));

export default function ContentCard({ title, children }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.root} mb={1}>
      {title && (
        <>
          <Typography color="primary" className={classes.title}>
            {t(title)}
          </Typography>
          <Divider className={classes.divider} />
        </>
      )}
      {children}
    </Box>
  );
}
