import React, { useEffect, useState, memo } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Hidden,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  NavLink as RouterLink,
  useLocation,
  useParams,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Rating from 'src/components/Rating';
import BaseButton from './base/BaseButton';
import {
  addToCartAction,
  removeCartItemAction,
} from 'src/store/actions/cart.actions';
import { convertToArabicNumbers } from 'src/utils';
import {
  Favorite,
  FavoriteBorder,
  Notifications,
  NotificationsActive,
  ShoppingCart,
} from '@material-ui/icons';
import { SET_AUTH_DIALOG } from 'src/store/constants/auth.types';

const useclasses = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    height: window.innerWidth <= 280 ? 240 : 358,
    maxWidth: 180,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '&:hover': {
      '& $actions': {
        opacity: 1,
        transform: 'translateY(-126%)',
      },
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 170,
    },
    [theme.breakpoints.up('sm')]: {
      '& a': {
        backgroundSize: '125% 100%',
      },
    },
  },
  media: {
    display: 'flex',
    justifyContent: 'center',
    height: '75%',

    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: '70%',
    },
  },
  cardContent: {
    paddingTop: 0,
    height: '25%',
    [theme.breakpoints.down('xs')]: {
      height: '30%',
      // height: '45%',
    },
    // background: '#ff0',
  },
  productInfo: {
    // padding: '0px 20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },

  title: {
    fontFamily: 'sfUiDisplayMedium',
    fontSize: window.innerWidth <= 280 ? 11 : 15,
    textAlign: 'center',
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: 12,
    // },
  },
  priceContaier: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ({ showDiscountedPrice }) =>
      showDiscountedPrice ? 'space-between' : 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  discountPrice: {
    fontSize: window.innerWidth <= 280 ? 10 : 14,
    color: theme.palette.primary.main,
    fontFamily: 'roboto',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      // fontSize: 10,
    },
  },
  price: {
    fontSize: window.innerWidth <= 280 ? 9 : 11,
    color: theme.custom.myTheme.grey3,
    fontFamily: 'roboto',
    fontWeight: 400,
    textDecoration: 'line-through',
    [theme.breakpoints.down('xs')]: {
      // fontSize: 9,
    },
  },
  basket: {
    // width: 34,
    // height: 35,
    // objectFit: 'contain',
    // marginLeft: 4,
    margin: '0 6px',
    transform: ({ dir }) => `scaleX(${dir == 'rtl' ? -1 : 1})`,
    color: '#fff',
  },
  actions: {
    background: '#fff',
    padding: '5px 0',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    // width: '84%',
    // left: '8%',
    zIndex: 100,
    opacity: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all ease-in-out .2s',
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ProductItem = ({ item }) => {
  const { t, i18n } = useTranslation();
  const { notifymeProducts, wishlistProducts, productId, loading } =
    useSelector((state) => state.cartReducer);
  const { currencyInfo } = useSelector((state) => state.userReducer);
  const { userInfo, authDialogOpen } = useSelector(
    (state) => state.authReducer
  );

  const showDiscountedPrice =
    (item?.product_id?.actual_price || item.actual_price) !== null &&
    (item?.product_id?.discounted_price || item.discounted_price) > 0;

  // const { push } = useHistory();
  const classes = useclasses({ dir: i18n.dir(), showDiscountedPrice });
  const dispatch = useDispatch();
  const [showProgress, setShowProgress] = useState(false);

  const searchParams = new URLSearchParams(useLocation().search);
  const page = searchParams.get('page');
  const { id: celebrityId } = useParams();

  const checkExistance = (type) => {
    const getItem = (data) =>
      data?.find(
        (productItem) =>
          productItem?.product_id?.id == (item?.product_id?.id || item?.id)
      );

    if (type == 'notifyMe') return getItem(notifymeProducts);
    else return getItem(wishlistProducts);
  };

  const cartHandler = () => {
    if (!userInfo?.id) {
      dispatch({ type: SET_AUTH_DIALOG, payload: true });
    } else {
      if (item.quantity > 0) {
        let celebId;
        if (page == 'celebrity_detail') {
          celebId = celebrityId;
        }

        dispatch(
          addToCartAction('cart', item?.product_id?.id || item.id, 1, celebId)
        );
      } else {
        const productExists = checkExistance('notifyMe');

        productExists?.length
          ? dispatch(removeCartItemAction('notify_me', productExists[0]?.id))
          : dispatch(
              addToCartAction('notify_me', item?.product_id?.id || item.id)
            );
      }
    }
  };

  const whishlistHandler = () => {
    if (!userInfo?.id) {
      dispatch({ type: SET_AUTH_DIALOG, payload: true });
    } else {
      if (wishlistProducts) {
        const productExists = checkExistance();
        productExists
          ? dispatch(
              removeCartItemAction(
                'favorite',
                productExists.id,
                item?.product_id?.id || item.id
              )
            )
          : dispatch(
              addToCartAction('favorite', item?.product_id?.id || item.id)
            );
      }
    }
  };

  const getProductName = () => {
    let name = item.title
      ? item.title.substr(0, 19)
      : i18n.language == 'ar'
      ? (item?.product_id?.arabic_title || item.arabic_title).substr(0, 19)
      : i18n.language == 'tr'
      ? (item?.product_id?.turkish_title || item.turkish_title).substr(0, 19)
      : (item?.product_id?.english_title || item.english_title).substr(0, 19);

    window.innerWidth <= 280 && (name = name.slice(0, 18));

    return name;
  };

  const getStartIcon = () => {
    let Icon;
    if ((item?.product_id?.quantity || item.quantity) > 0) {
      Icon = ShoppingCart;
    } else {
      if (checkExistance('notifyMe')?.id) Icon = NotificationsActive;
      else Icon = Notifications;
    }

    return <Icon className={classes.basket} />;
  };

  useEffect(() => {
    if (productId == (item?.product_id?.id || item?.id)) {
      setShowProgress(
        productId == (item?.product_id?.id || item?.id) && loading
      );
    }
  }, [productId, item, loading]);

  return (
    <Card className={classes.root} elevation={0}>
      <CardMedia
        className={classes.media}
        component={RouterLink}
        image={
          item.data
            ? item.data
            : item.image
            ? item.image.data.full_url
            : // ? item.image.data.thumbnails[3].url
            item.product_id
            ? item.product_id.image
              ? item.product_id.image.data.full_url
              : // ? item.product_id.image.data.thumbnails[3].url
                null
            : null
        }
        to={`/products/${
          (item.product_id?.id || item.id) +
          (celebrityId ? '?celebrityId=' + celebrityId : '')
        }`}
      />
      <CardContent className={classes.cardContent}>
        <Rating
          oldRating={item?.product_id?.rate || item?.rate}
          className={{ marginVertical: 0 }}
        />

        <div className={classes.productInfo}>
          <Typography color="primary" noWrap className={classes.title}>
            {getProductName()}
          </Typography>
          <div className={classes.priceContaier}>
            <Typography
              className={classes.discountPrice}
              style={{ direction: 'ltr' }}
            >
              {currencyInfo?.title + ' '}
              {convertToArabicNumbers(
                Number(
                  (item?.product_id?.discounted_price ||
                    item.discounted_price ||
                    item?.product_id?.actual_price ||
                    item.actual_price) * currencyInfo.from
                ).toFixed(2) + '',
                i18n.dir()
              )}
            </Typography>

            {showDiscountedPrice &&
              (item?.product_id?.actual_price || item.actual_price) && (
                <Typography
                  className={classes.price}
                  style={{ direction: 'ltr' }}
                >
                  {currencyInfo?.title + ' '}
                  {convertToArabicNumbers(
                    (
                      (item?.product_id?.actual_price || item.actual_price) *
                      currencyInfo.from
                    ).toFixed(2) + '',
                    i18n.dir()
                  )}
                </Typography>
              )}
          </div>
        </div>
      </CardContent>
      <Hidden xsDown>
        <div className={classes.actions}>
          <BaseButton
            text={t(
              (item?.product_id?.quantity || item.quantity) > 0
                ? 'addToCart'
                : checkExistance('notifyMe')?.id
                ? 'willNotify'
                : 'notifyMe'
            )}
            startIcon={getStartIcon()}
            style={{
              width: '100%',
              borderRadius: 0,
              marginBottom: 5,
              background:
                [0, undefined, null].includes(
                  item?.product_id?.quantity || item.quantity
                ) && '#8b8b8b',
              // (item?.product_id?.quantity || item.quantity) <= 0 && '#8b8b8b',
              opacity: showProgress && 0.4,

              height: 35,
            }}
            variant="contained"
            onClick={cartHandler}
            textStyle={{ fontWeight: 'bold', fontSize: 12 }}
            disabled={showProgress}
          />
          <BaseButton
            text={t(checkExistance() ? 'removeFromWishlist' : 'addToWishlist')}
            startIcon={
              checkExistance() ? (
                <Favorite className={classes.basket} />
              ) : (
                <FavoriteBorder className={classes.basket} />
              )
            }
            style={{ width: '100%', borderRadius: 0, height: 35 }}
            color="secondary"
            variant="contained"
            onClick={whishlistHandler}
            textStyle={{ fontWeight: 'bold', fontSize: 12 }}
            disabled={showProgress}
          />

          {showProgress && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Hidden>
    </Card>
  );
};

export default memo(ProductItem);
