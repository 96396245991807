import { Button, makeStyles, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import BaseButton from 'src/components/base/BaseButton';
import BasePage from 'src/components/base/BasePage';
import Center from 'src/components/Center';
import {
  sendResetPasswordEmail,
  passwordResetRequestAction,
} from 'src/store/actions/auth.actions';

const useClasses = makeStyles((theme) => ({
  logo: {
    width: '100%',
    width: 318,
    height: 318,
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      width: window.innerWidth > 300 ? 70 : 60,
      height: window.innerWidth > 300 ? 100 : 80,
    },
  },
  input: {
    height: 50,
    borderRadius: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    width: '100%',
    padding: 12,
    outline: 0,
    color: theme.palette.primary.main,
    fontSize: 15,
    // transition: 'all .1s ease-in-out',
    // [theme.breakpoints.down('sm')]: {
    //   border: `2px solid ${theme.palette.primary.main}`,
    // },
    [theme.breakpoints.down('xs')]: {
      // width: '100%',
      height: 38,
    },
  },
  resendBtn: {
    marginTop: 20,
    '&:hover': {
      // background: 'transparent',
    },
  },
}));

let emailSchema = yup.object().shape({
  email: yup.string().email().required('Required'),
});

// let passwordSchema = yup.object().shape({
//   password: yup.string().required('Required'),
//   confirmPassword: yup
//     .string()
//     .oneOf([yup.ref('password'), ''], 'Passwords must match')
//     .required('Required'),
// });

function ForgotPassword() {
  const classes = useClasses();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetpassword, setResetpassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [token, setToken] = useState();
  // const [userEmail, setUserEmail] = useState();
  const [wrongRedirect, setWrongRedirect] = useState(false);
  const { loading, error } = useSelector((state) => state.authReducer);

  const theme = useTheme();

  const setError = (field) => {
    const error =
      field == 'email'
        ? 'invalid email address'
        : field == 'password'
        ? 'password is required'
        : field == 'confirmPassword'
        ? 'passwords not match'
        : 'something went wrong, please try again';
    setErrorMsg({ error, field });
  };

  const submitHandler = async () => {
    setErrorMsg(null);
    if (!resetpassword) {
      if (await emailSchema.isValid({ email })) {
        const emailSent = await dispatch(sendResetPasswordEmail({ email }));
        emailSent ? push('/resetpassword') : setError('email');
      } else {
        return setError('email');
      }
    } else {
      if (!password.length) {
        return setError('password');
      } else if (!confirmPassword.length || password !== confirmPassword) {
        return setError('confirmPassword');
      }

      const payload = {
        password,
        token,
      };

      const passSet = await dispatch(passwordResetRequestAction(payload));
      passSet && push('/profile');
      // console.log('valid cred');
    }
  };

  const renderForm = () =>
    !resetpassword ? (
      <>
        <input
          className={classes.input}
          placeholder={t('email')}
          style={{
            border: `1px solid ${
              errorMsg?.field == 'email' ? 'red' : theme.palette.primary.main
            }`,
          }}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        {errorMsg?.field == 'email' && (
          <Typography
            variant="body2"
            color="error"
            style={{ alignSelf: 'flex-start' }}
          >
            {errorMsg?.error}
          </Typography>
        )}
      </>
    ) : (
      <>
        <input
          className={classes.input}
          style={{
            border: `1px solid  ${
              errorMsg?.field == 'password' ? 'red' : theme.palette.primary.main
            }`,
          }}
          placeholder={t('password')}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type="password"
        />
        {errorMsg?.field == 'password' && (
          <Typography
            variant="body2"
            color="error"
            style={{ alignSelf: 'flex-start' }}
          >
            {errorMsg?.error}
          </Typography>
        )}
        <input
          className={classes.input}
          placeholder={t('confirmPassword')}
          style={{
            marginTop: 20,
            border: `1px solid  ${
              errorMsg?.field == 'confirmPassword'
                ? 'red'
                : theme.palette.primary.main
            }`,
          }}
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          type="password"
        />
        {errorMsg?.field == 'confirmPassword' && (
          <Typography
            variant="body2"
            color="error"
            style={{ alignSelf: 'flex-start' }}
          >
            {errorMsg?.error}
          </Typography>
        )}
      </>
    );

  const searchParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const token = searchParams.get('token');
    // const email = searchParams.get('email');
    if (pathname.indexOf('resetpassword') > -1) {
      if (token) {
        setToken(token);
        // setUserEmail(email);
        setResetpassword(true);
      } else {
        setWrongRedirect(true);
      }
    }
    // setResetpassword(pathname.indexOf('resetpassword') > -1);
  }, [pathname]);
  return (
    <BasePage>
      <Center style={{ width: '100%', minHeight: '70vh' }}>
        <Center style={{ width: 400, flexDirection: 'column' }}>
          <img className={classes.logo} src="/svg/logo-primary-text.svg" />

          <Typography
            color="primary"
            variant="body1"
            align="center"
            style={{
              margin: '20px 0',
              width: '65%',
              fontFamily: 'sfUiDisplayRegular',
              fontSize: 18,
            }}
          >
            {t(
              wrongRedirect
                ? 'wrongRedirectMsg'
                : resetpassword
                ? ''
                : 'enterYouEmailToResetPass'
            )}
          </Typography>
          {error && (
            <Center>
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </Center>
          )}
          {!wrongRedirect && renderForm()}

          {!wrongRedirect && (
            <BaseButton
              onClick={submitHandler}
              // onClick={() => push('/forgot-password/validate')}
              text={t('submit')}
              style={{ width: '100%', marginTop: 30, height: 50 }}
              disabled={loading}
            />
          )}

          {resetpassword ||
            (wrongRedirect && (
              <BaseButton
                color="secondary"
                href="/forgot-password"
                style={{ width: '100%', marginTop: 10, height: 50 }}
                text={t('resend')}
                disabled={loading}
              />
            ))}
        </Center>
      </Center>
    </BasePage>
  );
}

export default ForgotPassword;
