import ApiService from 'src/services/api.service';
import {
  GET_PRODUCT_BY_ID_FAIL,
  GET_PRODUCT_BY_ID_REQUEST,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_AUTHORS_REQUEST,
  GET_AUTHORS_SUCCESS,
  GET_AUTHORS_FAIL,
  FILTER_PRODUCTS_REQUEST,
  FILTER_PRODUCTS_SUCCESS,
  FILTER_PRODUCTS_FAIL,
  GET_PRODUCT_BY_AUTHOR_ID_SUCCESS,
  GET_PRODUCT_BY_AUTHOR_ID_FAIL,
  GET_PRODUCT_BY_AUTHOR_ID_REQUEST,
  SET_SEARCH_PAGE_COUNT,
  SET_SEARCH_PAGE,
  SET_AUTHORS_PRODUCTS_PAGE,
  SET_AUTHORS_PRODUCTS_PAGE_COUNT,
  SET_AUTHORS_PAGE_COUNT,
  SET_AUTHORS_CURRENT_PAGE,
  GET_RELATED_PRODUCTS_REQUEST,
  GET_RELATED_PRODUCTS_SUCCESS,
  GET_RELATED_PRODUCTS_FAIL,
  GET_AUTHOR_INFO_REQUEST,
  GET_AUTHOR_INFO_SUCCESS,
  GET_AUTHOR_INFO_FAIL,
  POST_REVIEW_REQ,
  POST_REVIEW_SUCCESS,
  POST_REVIEW_FAIL,
} from '../constants/product.types';

export const getProductByIdAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRODUCT_BY_ID_REQUEST });
    const { data } = await ApiService.get(
      `items/products?fields=*,authors.*,author.*,book_language.arabic_title,book_language.english_title,publisher.arabic_title,publisher.english_title,authors.author_id.arabic_title,authors.author_id.english_title,rating.id,rating.rating,rating.title,rating.review,rating.customer_id.first_name,rating.customer_id.last_name,rating.status,rating.created_on,media.image.data.data.full_url,media.video_link,media.type,categories.category_id&filter[id]=${id}&limit=10`
    );
    const productImg = await ApiService.get(`files/${data.data[0].image.id}`);

    let cats = [];
    data.data[0].categories.forEach((cat) => cats.push(cat.category_id));
    dispatch(getRelatedProductsAction(cats));

    dispatch({
      type: GET_PRODUCT_BY_ID_SUCCESS,
      payload: {
        ...data.data[0],
        imageUrl: productImg.data.data.data.full_url,
      },
    });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_PRODUCT_BY_ID_FAIL, error: message });
  }
};

const getRelatedProductsAction = (cats) => async (dispatch) => {
  try {
    dispatch({ type: GET_RELATED_PRODUCTS_REQUEST });
    const { data } = await ApiService.get(
      `items/products?fields=*,authors.*,author.*,book_language.arabic_title,book_language.english_title,publisher.arabic_title,publisher.english_title,authors.author_id.arabic_title,authors.author_id.english_title,rating.id,rating.rating,rating.title,rating.review,rating.customer_id.first_name,rating.customer_id.last_name,rating.status,rating.date,image.data.data.full_url&filter[status]=published&filter[categories.category_id][in]=${cats}&limit=10`
    );

    dispatch({
      type: GET_RELATED_PRODUCTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_RELATED_PRODUCTS_FAIL, error: message });
  }
};

export const getProductByAuthorIdAction = (authorId) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRODUCT_BY_AUTHOR_ID_REQUEST });
    const { data } = await ApiService.get(
      `items/products?fields=*,image.data.data.full_url,authors.*,book_language.arabic_title,book_language.english_title,publisher.arabic_title,publisher.english_title,authors.author_id.id,authors.author_id.arabic_title,authors.author_id.english_title,rating.id,rating.rating,rating.title,rating.review,rating.customer_id.first_name,rating.customer_id.last_name,rating.status,rating.date&filter[authors.author_id.id]=${authorId}&filter[status]=published`
    );

    dispatch({
      type: GET_PRODUCT_BY_AUTHOR_ID_SUCCESS,
      payload: data.data,
      page: authorId,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_PRODUCT_BY_AUTHOR_ID_FAIL, error: message });
  }
};

export const filterProductsAction =
  (query, filter, sort) => async (dispatch, getState) => {
    try {
      dispatch({ type: FILTER_PRODUCTS_REQUEST });
      const {
        productReducer: { products_page, products_limit },
      } = getState();
      let url = '';

      if (query.table == 'product_tag') {
        !sort
          ? (url = `items/product_tag?fields=*,product_id.status,product_id.quantity,product_id.authors.author_id,product_id.*,product_id.image.data.full_url&filter[tag_id.id]=${query.eq}&sort=sequence&filter[product_id.status]=published`)
          : (url = `items/products?fields=*,status,quantity,authors.author_id,image.data.full_url,tags.*&filter[tags.tag_id.id]=${query.eq}&sort=sequence&filter[status]=published`);
        filter &&
          (sort
            ? (url += `&filter[${filter.filterBy}][in]=${filter.value}`)
            : (url += `&filter[product_id.${filter.filterBy}][in]=${filter.value}`));
        sort && (url += `&sort=${sort.order}${sort.by}`);
      } else if (query.table == 'product_category') {
        url = `items/products?fields=*,status,quantity,authors.author_id,*,image.data.full_url,categories.*&filter[categories.category_id.id]=${query.eq}&filter[status]=published`;
        filter && (url += `&filter[${filter.filterBy}][in]=${filter.value}`);
        sort && (url += `&sort=${sort.order}${sort.by}`);
      } else if (query.table == 'celebrity_products') {
        url = `items/products?fields=*,status,quantity,authors.author_id,*,image.data.full_url,celebrity_pro.*&filter[celebrity_pro.${query.filter_by}]=${query.eq}&filter[status]=published&sort=sequence`;
        filter && (url += `&filter[${filter.filterBy}][in]=${filter.value}`);
        sort && (url += `&sort=${sort.order}${sort.by}`);
        console.log(url);
      } else if (query.table == 'celebrity_favourities') {
        url = `items/products?fields=*,status,quantity,authors.author_id,*,image.data.full_url,celebrity_fav.*&filter[celebrity_fav.${query.filter_by}]=${query.eq}&filter[status]=published&sort=sequence`;

        filter && (url += `&filter[${filter.filterBy}][in]=${filter.value}`);
        sort && (url += `&sort=${sort.order}${sort.by}`);
      } else {
        if (query.filterCatId) {
          url = `items/products?fields=*,quantity,image.data.full_url,categories.category_id&filter[book_type_id]=${query.eq}&filter[categories.category_id]=${query.filterCatId}&filter[status]=published&sort=sequence`;
          sort && (url += `&sort=${sort.order}${sort.by}`);
        } else {
          url = `items/products?fields=*,quantity,image.data.full_url,categories.category_id&filter[book_type_id]=${query.eq}&filter[status]=published&sort=sequence`;
          sort && (url += `&sort=${sort.order}${sort.by}`);
        }
        filter && (url += `&filter[${filter.filterBy}][in]=${filter.value}`);
      }
      const { data } = await ApiService.get(
        url + `&meta=*&limit=${products_limit}&page=${1}`
        // url + `&meta=*&limit=${products_limit}&page=${products_page + 1}`
      );

      let page = query.table + '_' + query.filter_by + '_' + query.eq;
      dispatch({
        type: SET_AUTHORS_PRODUCTS_PAGE_COUNT,
        payload: data.meta.page_count,
      });

      dispatch({
        type: SET_AUTHORS_PRODUCTS_PAGE,
        payload: data.meta.page,
      });

      dispatch({
        type: FILTER_PRODUCTS_SUCCESS,
        payload: data.data,
        page: page,
        reset: filter ? true : false,
      });
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: FILTER_PRODUCTS_FAIL, error: message });
    }
  };

export const getAuthorsAction =
  ({ searchText = '', lang, startPage = 1, reset }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_AUTHORS_REQUEST });

      const {
        productReducer: { authors_current_page, limit },
      } = getState();

      // let url = `items/author?sort=arabic_title,english_title`;
      let url = `items/author?sort=arabic_title,english_title&meta=*&limit=${limit}&page=${
        reset ? 1 : startPage
      }`;
      // let url = `items/author?sort=arabic_title,english_title&meta=*&limit=${limit}&page=${
      //   reset ? 1 : authors_current_page + 1
      // }`;

      searchText &&
        (url += `&filter[${
          lang == 'ar' ? 'arabic' : lang == 'tr' ? 'turkish' : 'english'
        }_title][like]=${searchText}`);

      const { data } = await ApiService.get(url);

      // const response = data.data;

      dispatch({
        type: SET_AUTHORS_PAGE_COUNT,
        payload: data.meta.page_count,
      });

      dispatch({
        type: SET_AUTHORS_CURRENT_PAGE,
        payload: reset ? 1 : startPage,
        // payload: (reset ? 1 : authors_current_page) + 1,
      });

      dispatch({
        type: GET_AUTHORS_SUCCESS,
        payload: data.data,
        reset,
      });
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: GET_AUTHORS_FAIL, error: message });
    }
  };

export const getAuthorInfoAction = (authorId) => async (dispatch) => {
  try {
    dispatch({ type: GET_AUTHOR_INFO_REQUEST });

    let url = `items/author/${authorId}`;

    const { data } = await ApiService.get(url);

    dispatch({
      type: GET_AUTHOR_INFO_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_AUTHOR_INFO_FAIL, error: message });
  }
};

export const reviewProductAction = (payload) => async () => {
  try {
    dispatch({ type: POST_REVIEW_REQ });
    await ApiService.post('items/review', payload);
    dispatch({ type: POST_REVIEW_SUCCESS });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: POST_REVIEW_FAIL });
  }
};
export { getRelatedProductsAction };
