import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Button,
  Hidden,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Close, Search as SearchIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Search from './NavBar/Search';
import { SET_USER_UI_SETTINGS } from 'src/store/constants/app.settings.types';
import { useDispatch, useSelector } from 'react-redux';
import SocialMedial from './TopBar/SocialMedial';
import ShoppingBasket from './NavBar/ShoppingBasket';
import Center from 'src/components/Center';
import LanguageMenu from './TopBar/LanguageMenue';
import BaseContainer from 'src/components/base/BaseContainer';

const useClasses = makeStyles((theme) => ({
  root: {
    background: '#f8f8f8',
  },
  innerBarContainer: {
    height: 80,

    width: '100%',
    background: '#f8f8f8',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between !important',
    },
    [theme.breakpoints.down('xs')]: {
      height: 60,
    },
  },
  btn: {
    background: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  logo: {
    width: 60,
    height: 60,
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 50,
    },
    [theme.breakpoints.down('xs')]: {
      width: window.innerWidth > 300 ? 45 : 40,
      height: window.innerWidth > 300 ? 45 : 40,
    },
  },
  name: {
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  iconsWrapper: {
    justifyContent: 'flex-end',
    maxWidth: '30%',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  searchIconContainer: {
    // background: '#fff',
    margin: '0 3px',
    // width: 40,
    // height: 35,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      // height: 20,
      // width: 15,
    },
  },
  searchIcon: {
    width: 14.8,
    height: 14,
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      // height: 15.8,
      // width: 15,
    },
  },
  closeIconWrapper: {
    background: ({ showSearchInput }) =>
      showSearchInput && theme.palette.primary.main,
    width: 60,
    height: 60,
  },
  closeIcon: {
    fontSize: 35,
    color: ({ showSearchInput }) => showSearchInput && '#fff',
  },
}));

export default function InnerBar() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    userUISettings: { showSearchInput },
  } = useSelector((state) => state.appSettingsReducer);

  const classes = useClasses({ showSearchInput });

  return (
    <div className={classes.root}>
      <BaseContainer>
        <Center className={classes.innerBarContainer} id="main-logo">
          <Hidden smUp>
            <div style={{ width: '32%' }}>
              <SocialMedial />
            </div>
          </Hidden>

          <Button
            className={classes.btn}
            to="/"
            component={RouterLink}
            disableElevation
            startIcon={
              <img
                className={classes.logo}
                src="/svg/top-logo.svg"
                // src="/logo/logo.png"
                alt="Diwan"
              />
            }
          >
            <Typography color="primary" className={classes.name}>
              {t('diwanWorld')}
            </Typography>
          </Button>

          <Center className={classes.iconsWrapper}>
            <Hidden smUp>
              <Center className={classes.searchIconContainer}>
                <IconButton
                  onClick={() =>
                    dispatch({
                      type: SET_USER_UI_SETTINGS,
                      payload: { showSearchInput: !showSearchInput },
                    })
                  }
                  style={{ padding: 0 }}
                >
                  {showSearchInput ? (
                    <Center className={classes.closeIconWrapper}>
                      <Close color="primary" className={classes.closeIcon} />
                    </Center>
                  ) : (
                    <SearchIcon color="primary" fontSize="small" />
                    // <img
                    //   src="/images/Icons-03.png"
                    //   className={classes.searchIcon}
                    // />
                  )}
                </IconButton>
              </Center>
            </Hidden>

            {/* <Hidden mdUp xsDown>
              <Search />
            </Hidden> */}

            <Hidden smUp>
              <ShoppingBasket />
              <LanguageMenu />
            </Hidden>
          </Center>
        </Center>
      </BaseContainer>
    </div>
  );
}
