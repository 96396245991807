import { Box, Divider, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseContainer from 'src/components/base/BaseContainer';
import BasePage from 'src/components/base/BasePage';
import Loading from 'src/components/base/Loading';
import { getCartItemsAction } from 'src/store/actions/cart.actions';
import CheckoutWizard from '../CheckoutWizard';
import WizardActions from '../WizardActions';
import UserCart from '../UserCart';
import OrderSummary from '../OrderSummary';
import { localStoragePrefix, ROUTE_PATH } from 'src/config';
import { useHistory } from 'react-router-dom';

export default function Cart() {
  const dispatch = useDispatch();
  const { loading, cartItems } = useSelector((state) => state.cartReducer);
  const { push } = useHistory();

  useEffect(() => {
    // !cartItems.length && dispatch(getCartItemsAction('cart'));

    const routePath = localStorage.getItem(localStoragePrefix + ROUTE_PATH);
    if (routePath) {
      localStorage.removeItem(localStoragePrefix + ROUTE_PATH);
      push(routePath);
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <BasePage>
      <BaseContainer>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <CheckoutWizard activeStep={0} />
            <UserCart style={{ marginTop: 20 }} />
            <WizardActions activeStep={0} stepsLength={3} />
          </Grid>
          <Grid item xs={12} md={4}>
            <OrderSummary activeStep={0} />
          </Grid>
        </Grid>
      </BaseContainer>
    </BasePage>
  );
}
