export const GET_MAIN_REQUEST = "GET_MAIN_REQUEST";
export const GET_MAIN_SUCCESS = "GET_MAIN_SUCCESS";
export const GET_MAIN_FAIL = "GET_MAIN_FAIL";

export const GET_OLD_DATA = "GET_OLD_DATA";
export const SET_LOADER = "SET_LOADER";

export const GET_NAV_CATS_REQUEST = "GET_NAV_CATS_REQUEST";
export const GET_NAV_CATS_SUCCESS = "GET_NAV_CATS_SUCCESS";
export const GET_NAV_CATS_FAIL = "GET_NAV_CATS_FAIL";
