import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMainPageAction } from 'src/store/actions/page.action';
import RenderPage from 'src/components/RenderPage';
import Loading from 'src/components/base/Loading';
// import EmptyContent from 'src/components/EmptyContent';
import { useParams } from 'react-router-dom';
import BasePage from 'src/components/base/BasePage';

export default function CatsScreen() {
  const pageReducer = useSelector((state) => state.pageReducer);

  const [contentData, setContentData] = useState(null);
  const dispatch = useDispatch();
  const { catName } = useParams();
  // const history = useHistory();

  // let page;
  // let catDetails;
  // let to;

  const renderItem = useMemo(
    () => (item) =>
      (
        <RenderPage
          key={Math.random().toString()}
          section={item}
          noSlider={true}
          customRoute="sub_category_detail"
        />
      ),
    [contentData]
  );

  const data = pageReducer[catName];

  useEffect(() => {
    if (!data) dispatch(getMainPageAction(catName));
    else {
      setContentData(data);
    }
  }, [data]);

  useEffect(() => {
    console.log(contentData);
    return () => {
      setContentData(null);
    };
  }, []);

  if (!contentData) {
    return <Loading />;
  }
  return (
    <BasePage>
      {contentData && contentData.map((item) => renderItem(item))}
    </BasePage>
  );
}
