import ApiService from 'src/services/api.service';
import {
  // INC_SEARCH_PAGE,
  RESET_SEARCH_PAGE,
  RESET_SEARCH_PAGE_COUNT,
  SEARCH_PRODUCTS_REQUEST,
  SEARCH_PRODUCTS_SUCCESS,
  SET_SEARCH_PAGE,
  SET_SEARCH_PAGE_COUNT,
  SEARCH_PRODUCTS_FAIL,
} from '../constants/search.types';

// search products
export const searchProductsAction =
  ({ searchText, reset, lang, startPage = 1 }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SEARCH_PRODUCTS_REQUEST });

      const {
        searchReducer: { page, limit },
      } = getState();

      if (reset) {
        dispatch({ type: RESET_SEARCH_PAGE });
        dispatch({ type: RESET_SEARCH_PAGE_COUNT });
      }

      let url = `items/products?fields=id,arabic_title,english_title,quantity,stock_quantity,actual_price,discounted_price,image.data.full_url&filter[${
        lang == 'ar' ? 'arabic' : lang == 'tr' ? 'turkish' : 'english'
      }_title][like]=${searchText}&meta=*&limit=${limit}&page=${
        reset ? 1 : startPage
        // reset ? page : page + 1
      }&filter[status]=published`;

      const { data } = await ApiService.get(url);

      dispatch({
        type: SET_SEARCH_PAGE_COUNT,
        payload: data.meta.page_count,
      });

      dispatch({
        type: SET_SEARCH_PAGE,
        payload: reset ? 1 : startPage,
        // payload: (reset ? 1 : page) + 1,
      });
      // console.log('success');

      dispatch({
        type: SEARCH_PRODUCTS_SUCCESS,
        payload: data.data,
        reset,
      });
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: SEARCH_PRODUCTS_FAIL, error: message });
    }
  };
