import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const useclasses = makeStyles((theme) => ({
  container: {
    maxWidth: ({ pathname }) =>
      ['book_pharmacy', 'library', 'celebrities'].includes(pathname)
        ? '100%'
        : 375,

    height: ({ pathname }) =>
      ['book_pharmacy', 'library'].includes(pathname)
        ? '100%'
        : pathname == 'celebrities'
        ? 320
        : 230,
    backgroundColor: '#fff',

    borderRadius: ({ pathname }) => (pathname == 'celebrities' ? 0 : 12),
    cursor: 'pointer',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: ({ pathname }) =>
        ['book_pharmacy', 'library'].includes(pathname)
          ? '100%'
          : pathname == 'celebrities'
          ? 300
          : 180,
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: ({ pathname }) => (pathname == 'celebrities' ? 0 : 8),
      height: ({ pathname }) =>
        ['book_pharmacy', 'library'].includes(pathname)
          ? '100%'
          : pathname == 'celebrities'
          ? 250
          : window.innerWidth > 300
          ? 200
          : 140,
    },
  },
  imgContainer: {
    width: '100%',
    height: ({ pathname }) => (pathname == 'celebrities' ? '85%' : '80%'),
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  titleContainer: {
    backgroundColor: theme.custom.myTheme.grey1,
    height: ({ pathname }) => (pathname == 'celebrities' ? '15%' : '20%'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 16,
    fontFamily: 'sfUiDisplaySemibold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}));

const UnderlineTitle = ({ item, style, type }) => {
  const { pathname } = useLocation();
  const classes = useclasses({ type, pathname: pathname.split('/').at(-1) });

  return (
    <div className={classes.container} style={style}>
      <div className={classes.imgContainer}>
        <img className={classes.image} src={item?.data} />
      </div>
      <div className={classes.titleContainer}>
        <Typography color="primary" className={classes.title}>
          {item.title}
        </Typography>
      </div>
    </div>
  );
};

export default UnderlineTitle;
