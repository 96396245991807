import React from 'react';
import { Grid } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { SwiperSlide } from 'swiper/react/swiper-react';

import Section from 'src/components/Section';
import FeaturedImage from './FeaturedImage';
import UnderlineTitle from './UnderlineTitle';
import HImageTitle from './HImageTitle';
import BaseCarousel from 'src/components/base/BaseCarousel';

export default function RenderCard({
  items,
  title,
  from,
  showSection = true,
  type,
  noSlider,
  itemPerRow,
  customRoute,
}) {
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);
  const page = searchParams.get('page');
  // console.log(history.location.pathname);

  const navigationHandler = (item) => {
    if (from == 'home') {
      if (item.custom_link) {
        if (item.custom_link == 'library') {
          history.push(`/category-details/${item.id}?page=sub_category_detail`);
        } else {
          history.push(`/category-details/${item.id}?page=${item.custom_link}`);
        }
      }
    } else if (from == 'filter') {
      history.push(`/filter/${item.table}/${item.filter_by}/${item.eq}`);
    } else {
      if (item.custom_link) {
        // console.log(item);
        history.push(
          `/category-details/${item.id}?page=${customRoute || item.custom_link}`
        );
        // history.push(
        //   `/category-details/${item.id}?page=${
        //     history.location.pathname?.indexOf('category-details')
        //       ? 'sub_category_detail'
        //       : item.custom_link
        //   }`
        // );
      }
    }
  };

  const renderGridItems = (items) => (
    <Grid container spacing={4}>
      {items.map((item) => (
        <Grid
          key={Math.random().toString()}
          onClick={() => navigationHandler(item)}
          item
          xs={12}
          md={Math.ceil(12 / itemPerRow) || 4}
          sm={6}
        >
          <UnderlineTitle item={item} type={type} />
        </Grid>
      ))}
    </Grid>
  );

  const renderCard = (item) => {
    if (
      type == 'image' &&
      (page == 'sub_category_detail' || page == 'category_detail')
    )
      return <FeaturedImage item={item} />;
    else if (type == 'listImage' || type == 'image')
      return <HImageTitle item={item} style={{ borderRadius: 15 }} />;
    else return <UnderlineTitle item={item} />;
  };

  const memoizedValue = (item) => (
    <div key={Math.random().toString()} onClick={() => navigationHandler(item)}>
      {renderCard(item)}
    </div>
  );

  return (
    <div>
      {showSection && <Section route={items[0].custom_link} title={title} />}

      {noSlider ? (
        type == 'image' ? (
          items.map((item) => memoizedValue(item))
        ) : (
          renderGridItems(items)
        )
      ) : (
        <BaseCarousel
          breakpoints={{
            0: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 40,
            },

            480: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 40,
            },
            780: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 40,
            },
          }}
        >
          {items.map((item) => (
            <SwiperSlide
              key={Math.random().toString()}
              style={{ overflow: 'hidden' }}
            >
              {memoizedValue(item)}
            </SwiperSlide>
          ))}
        </BaseCarousel>
      )}
    </div>
  );
}
