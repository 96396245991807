import React from 'react';
import { MenuItem, makeStyles } from '@material-ui/core';
import BaseInput from 'src/components/base/BaseInput';

const useStyles = makeStyles((theme) => ({
  option: {
    color: theme.palette.primary.main,
  },
}));

const Select = ({
  selectedValuelabel,
  label,
  setSelectValue,
  options,
  ...otherProps
}) => {
  const classes = useStyles();

  const handleChange = (evt) => {
    const { value } = evt.target;
    setSelectValue(value);
  };

  const configSelect = {
    ...otherProps,
    select: true,
    label,
    onChange: handleChange,
  };

  return (
    <BaseInput {...configSelect}>
      {options?.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          <span className={classes.option}>{option.english_title}</span>
        </MenuItem>
      ))}
    </BaseInput>
  );
};

export default Select;
