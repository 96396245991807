import { localStoragePrefix } from 'src/config';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_SIGNOUT,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  RESET_REGISTER,
  USER_EXIST,
  SET_USER_INFO_SUCCESS,
  SET_USER_INFO_FAIL,
  TEST_USER_CREDENTIALS_REQUEST,
  TEST_USER_CREDENTIALS_SUCCESS,
  TEST_USER_CREDENTIALS_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  SET_CURRENCY_INFO_SUCCESS,
  SET_CURRENCY_INFO_FAIL,
  SET_AUTH_DIALOG,
  RESET_PASS_EMAIL_REQUEST,
} from '../constants/auth.types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  successReg: false,
  currencyInfo: null,
  userInfo: localStorage.getItem(localStoragePrefix + 'userInfo')
    ? JSON.parse(localStorage.getItem(localStoragePrefix + 'userInfo'))
    : null,
  token: localStorage.getItem(localStoragePrefix + 'token'),
  authDialogOpen: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, error: null, loading: true };

    case LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: action.payload.user,
        token: action.payload.token,
        // isAuth: true,
        // success: true,
        error: null,
        loading: false,
        // exist: false,
      };

    case LOGIN_FAIL:
      return { ...state, loading: false, error: action.error };

    case USER_SIGNOUT:
      return {
        ...state,
        userInfo: null,
        token: null,
        isAuth: false,
      };

    case SIGNUP_REQUEST:
      return { ...state, error: null, loading: true };

    case SIGNUP_SUCCESS:
      return {
        ...state,
        successReg: true,
        error: null,
        loading: false,
      };

    // case USER_EXIST:
    //   return {
    //     ...state,
    //     exist: true,
    //     error: null,
    //     loading: false,
    //   };

    case SIGNUP_FAIL:
      return {
        ...state,
        successReg: false,
        loading: false,
        error: action.payload,
      };

    case RESET_REGISTER:
      return {
        ...state,
        successReg: false,
        success: false,
        loading: false,
        exist: false,
      };

    // set user info
    case SET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        error: null,
      };

    case SET_USER_INFO_FAIL:
      return { ...state, error: action.payload };

    // set currency info
    case SET_CURRENCY_INFO_SUCCESS:
      return {
        ...state,
        currencyInfo: action.payload,
        error: null,
      };

    case SET_CURRENCY_INFO_FAIL:
      return { ...state, error: action.payload };

    // test user credentials
    case TEST_USER_CREDENTIALS_REQUEST:
      return { ...state, loading: true };

    case TEST_USER_CREDENTIALS_SUCCESS:
      return {
        ...state,

        error: null,
        loading: false,
      };

    case TEST_USER_CREDENTIALS_FAIL:
      return { ...state, loading: false };

    // change password
    case RESET_PASS_EMAIL_REQUEST:
      return { ...state, error: null, loading: true };

    case CHANGE_PASSWORD_REQUEST:
      return { ...state, error: null, loading: true };

    // case CHANGE_PASSWORD_SUCCESS:
    //   return {
    //     ...state,

    //     error: null,
    //     loading: false,
    //   };

    case CHANGE_PASSWORD_FAIL:
      return { ...state, error: action.payload, loading: false };

    case SET_AUTH_DIALOG:
      return { ...state, authDialogOpen: action.payload };

    default:
      return state;
  }
};

export default authReducer;
