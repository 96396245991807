import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BaseButton from 'src/components/base/BaseButton';
import BaseContainer from 'src/components/base/BaseContainer';
import BasePage from 'src/components/base/BasePage';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.error.main,
    fontSize: 150,
    marginRight: 30,
  },
  msg: {
    color: theme.custom.myTheme.grey3,
    fontSize: 18,
    width: '85%',
  },
  orderInfo: {
    color: theme.custom.myTheme.grey3,
    '& span': {
      color: theme.palette.primary.main,
    },
  },
}));

export default function OrderFaild() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <BasePage>
      <BaseContainer>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Box display="flex" alignItems="center">
              <Cancel className={classes.icon} />

              <Box>
                <Typography variant="h4" color="primary">
                  {t('orderFaild')}
                </Typography>
                <Typography className={classes.msg}>
                  {t('orderFaildMsg')}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={8}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={4}>
              <BaseButton
                text={t('continueShopping')}
                style={{
                  textTransform: 'capitalize',
                  width: '100%',
                }}
                onClick={() => push(`/`)}
              />
            </Grid>
          </Grid>
        </Grid>
      </BaseContainer>
    </BasePage>
  );
}
