import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AddressForm from 'src/components/AddressForm';
import BaseButton from 'src/components/base/BaseButton';
import BasePage from 'src/components/base/BasePage';
import Loading from 'src/components/base/Loading';
import Center from 'src/components/Center';
import {
  getUserAddressesAction,
  removeAddressItemAction,
} from 'src/store/actions/address.actions';

const useClasses = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.custom.myTheme.grey3}`,
    // width: 260,
    height: 400,
    borderRadius: 10,
    padding: '45px 15px 15px',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'space-between',
  },
  roomIcon: {
    width: 237,
    height: 237,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.error.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // marginTop: 30,
    // margin: '30px 0',
  },
  emptyaddress: {
    fontFamily: 'sfUiDisplayRegular',
    fontSize: 15,
    color: theme.custom.myTheme.grey3,
  },
}));

export default function MyAddresses({ title }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const classes = useClasses();
  const { addresses, loading } = useSelector((state) => state.addressReducer);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState();

  const editAddressHandler = (address) => {
    setAddressToEdit(address);
    setShowAddressForm(true);
  };

  const renderItem = (address) => (
    <Grid item xs={12} sm={6} key={Math.random().toString()}>
      <div className={classes.container}>
        <Box>
          <Typography variant="h6" color="primary">
            {address.full_name}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {address.address_line_1 +
              ' ' +
              address.address_line_2 +
              ' ,Phone: ' +
              address.phone}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <BaseButton
            color="primary"
            text={t('edit')}
            style={{ width: 150, height: 44 }}
            onClick={() => editAddressHandler(address)}
          />
          <BaseButton
            color="secondary"
            text={t('delete')}
            style={{ width: 150, height: 44 }}
            onClick={() => removeAddressItemAction(address.id)}
          />
        </Box>
      </div>
    </Grid>
  );

  const addNewAddress = () => (
    <div className={classes.container}>
      <Box display="flex" justifyContent="center">
        <div className={classes.roomIcon}>
          <Room color="primary" style={{ fontSize: 160 }} />
        </div>
      </Box>
      <BaseButton
        color="primary"
        text={t('addNewAddress')}
        style={{ height: 44 }}
        onClick={() => setShowAddressForm(true)}
      />
    </div>
  );

  const reloadData = () => {
    setShowAddressForm(false);
    dispatch(getUserAddressesAction(i18n.language.split('-')[0]));
  };

  useEffect(() => {
    // reloadData();
    dispatch(getUserAddressesAction(i18n.language.split('-')[0]));
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ height: '100%' }}>
      <Typography
        variant="h4"
        style={{
          fontFamily: 'sfUiTextRegular',
          fontSize: window.innerWidth <= 599 && 25,
        }}
        color="primary"
      >
        {title}
      </Typography>

      <div style={{ marginTop: window.innerWidth > 599 ? 50 : 20 }}>
        {showAddressForm ? (
          <Box width="80%">
            <AddressForm
              record={addressToEdit}
              reloadData={reloadData}
              // setShowAddressForm={setShowAddressForm}
            />
          </Box>
        ) : (
          <Grid
            container
            spacing={2}
            // style={{ marginTop: window.innerWidth > 599 ? 50 : 20 }}
          >
            {addresses?.map((address, idx) => renderItem(address, idx))}
            {!addresses?.length && (
              <Grid item xs={12} sm={6}>
                <Center style={{ height: '90%' }}>
                  <Typography className={classes.emptyaddress}>
                    {t('noOrdersFound')}!
                  </Typography>
                </Center>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              {addNewAddress()}
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}
