import React, { useEffect, useState } from 'react';
import { Hidden, IconButton, makeStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import BaseContainer from 'src/components/base/BaseContainer';
import BasePage from 'src/components/base/BasePage';
import { RESET_PAGINATION } from 'src/store/constants/product.types';
import { searchProductsAction } from 'src/store/actions/search.actions';
import {
  // INC_SEARCH_PAGE,
  RESET_SEARCH_PAGE,
  TRIGGER_REFRESHING,
  SET_SEARCH_PAGE,
} from 'src/store/constants/search.types';
import { useHistory, useLocation } from 'react-router-dom';
import Center from 'src/components/Center';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    with: 260,
    height: 35,
    zIndex: 109,

    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      bottom: 5,
      height: 35,
      left: 0,
      right: 0,
    },
  },
  searchInput: {
    height: 35,
    borderRadius: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    borderTop: 0,
    width: 220,
    padding: 12,
    outline: 0,
    color: theme.palette.primary.main,
    transition: 'all .1s ease-in-out',
    zIndex: 299,
    [theme.breakpoints.down('sm')]: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 38,
    },
  },
  searchIcon: {
    background: theme.palette.primary.main,
    width: 40,
    height: 35,
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      opacity: 0.9,
    },
    [theme.breakpoints.down('xs')]: {
      height: 38,
      width: 50,
    },
  },
}));

function Search() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputFullWidth, setInputFullWidth] = useState(false);
  const classes = useStyles({ inputFullWidth });

  const {
    userUISettings: { showSearchInput },
  } = useSelector((state) => state.appSettingsReducer);

  // const location = useLocation();
  // const {
  //   loading,
  //   authorsList,
  //   authorProducts,
  //   authorInfo,
  //   authors_page_count,
  //   // authors_current_page,
  // } = useSelector((state) => state.productReducer);

  // const { loading, error, searchResult, page, page_count, refreshing } =
  //   useSelector((state) => state.searchReducer);

  const lang = i18n.language.split('-')[0];
  const [searchText, setSearchText] = useState('');

  const searchParams = new URLSearchParams(useLocation().search);
  const searchQuery = searchParams.get('keyword');

  const handleSearch = () => {
    const trimedText = searchText.trim();
    if (trimedText) {
      history.push(`/search?keyword=${trimedText}`);

      // dispatch({ type: SET_SEARCH_TEXT, payload: trimedText });
      // setCurrent_page(1);
      // dispatch(
      //   getAuthorsAction({
      //     searchText: trimedText,
      //     lang,
      //     startPage: 1,
      //     reset: true,
      //   })
      // );
    } else {
      // history.push(`/authors?page=1`);
      // // setCurrent_page(1);
      // dispatch(getAuthorsAction({ lang }));
    }
  };

  useEffect(() => {
    history.location.pathname.indexOf('search') &&
      setSearchText(searchQuery || '');
  }, [searchQuery]);

  return (
    <>
      <Center className={classes.searchWrapper}>
        <input
          className={classes.searchInput}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
        <Center className={classes.searchIcon}>
          <IconButton onClick={handleSearch} style={{ padding: 0 }}>
            <img src="/svg/search.svg" style={{ width: 20.8, height: 20 }} />
            {/* <Hidden xsDown>
            </Hidden> */}
            {/* <Hidden smUp>
              <img
                src="./images/Icons-03.png"
                style={{ width: 20.8, height: 20 }}
              />
            </Hidden> */}
            {/* <SearchIcon style={{ color: '#fff', fontSize: 30 }} /> */}
          </IconButton>
        </Center>
      </Center>
    </>
  );
}

export default Search;
