import { ThemeProvider } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import withRoot from './withRoot';
import { theme } from './theme';
import { CssBaseline, makeStyles } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import AppBar from './components/core/AppBar';
import HomePage from './views/home';
import Footer from './components/core/Footer';
import SignupPage from './views/SignupPage';
import NotFound from './views/NotFound';
import CatDetailsScreen from './views/CatDetailsScreen';
import CatsScreen from './views/CatsScreen';
import FilterScreen from './views/FilterScreen';
import ProductDetailsScreen from './views/ProductDetailsScreen';
import AccountScreen from './views/AccountScreen';
import PrivateRoute from './components/PrivateRoute';
import OrderDetails from './views/AccountScreen/OrderHistory/OrderDetails';
import OrderFaild from './views/shopping/Checkout/OrderFaild';
import PaymentStatusRedirect from './views/shopping/Checkout/PaymentStatusRedirect';
import OrderConfirmed from './views/shopping/Checkout/OrderConfirmed';
import SelectCountry from './components/SelectCountry';
import { useSelector } from 'react-redux';
import Cart from './views/shopping/Cart';
import ShippingAddress from './views/shopping/ShippingAddress';
import Checkout from './views/shopping/Checkout';
import AuthorsPage from './views/AuthorsPage';
import StoreScreen from './views/StoreScreen';
import HelmetMetaData from './components/HelmetMetaData';
import ForgotPassword from './views/AccountScreen/ForgotPassword';
import { useState } from 'react';
// import { useEffect, useState } from 'react';

const useClasses = makeStyles((theme) => ({
  separator: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      // height: 110,
      height: ({ mobileOS }) => (mobileOS !== 'unknown' ? 210 : 110),
    },
  },
}));

function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  // const dispatch = useDispatch()
  const { currencyInfo } = useSelector((state) => state.userReducer);
  // const { cartItems } = useSelector((state) => state.cartReducer);

  const [mobileOS, setMobileOS] = useState();
  const classes = useClasses({ mobileOS });

  // const [passToShopping, setPassToShopping] = useState(false);

  // useEffect(() => {
  //   setPassToShopping(cartItems?.length > 0 ? true : false);
  // }, [cartItems]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppBar mobileOS={mobileOS} setMobileOS={setMobileOS} />
        <div className={classes.separator} id="app-bar-clearer" />
        <HelmetMetaData />
        {!currencyInfo ? (
          <SelectCountry />
        ) : (
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/categories/:catName" component={CatsScreen} />
            <Route path="/authors/:authorId?" component={AuthorsPage} />
            <Route path="/category-details/:id" component={CatDetailsScreen} />
            <Route
              path="/filter/:table/:filter_by/:eq"
              component={FilterScreen}
            />
            <Route path="/search" component={FilterScreen} />
            <Route
              path="/products/:productId"
              component={ProductDetailsScreen}
            />

            <PrivateRoute
              path="/forgot-password"
              component={ForgotPassword}
              notAuthOnly={true}
            />
            <PrivateRoute
              path="/resetpassword"
              component={ForgotPassword}
              notAuthOnly={true}
            />

            <PrivateRoute
              path="/signup"
              component={SignupPage}
              notAuthOnly={true}
            />
            <PrivateRoute
              path="/profile/:tabIndex?"
              component={AccountScreen}
            />

            <PrivateRoute path="/cart" component={Cart} />
            <PrivateRoute
              path="/shipping-address"
              component={ShippingAddress}
              shopping={true}
              // passToShopping={passToShopping}
              // to="/cart"
            />
            <PrivateRoute
              path="/checkout"
              component={Checkout}
              shopping={true}
              // to="/cart"
            />

            <PrivateRoute
              path="/order-success/:orderId/:date/:shipping_value/:subtotal/:price"
              component={OrderConfirmed}
            />
            <PrivateRoute path="/order-faild" component={OrderFaild} />

            <PrivateRoute
              path="/payment-status-check"
              component={PaymentStatusRedirect}
            />

            <PrivateRoute
              path="/order-details/:orderId"
              component={OrderDetails}
            />

            <Route path="/store" component={StoreScreen} />
            <Route path="/brands" component={StoreScreen} />

            <Route component={NotFound} />
          </Switch>
        )}
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default withRoot(App);
