import {
  GET_MAIN_FAIL,
  GET_MAIN_REQUEST,
  GET_MAIN_SUCCESS,
  SET_LOADER,
  GET_NAV_CATS_REQUEST,
  GET_NAV_CATS_SUCCESS,
  GET_NAV_CATS_FAIL,
} from '../constants/main.type';

let navItems = [
  {
    text: 'celebrities',
    href: '/categories/celebrities',
  },
  {
    text: 'bookPharmacy',
    href: '/categories/book_pharmacy',
    submenu: [],
  },
  {
    text: 'library',
    href: '/categories/library',
    submenu: [],
  },
  {
    text: 'authors',
    href: '/authors?page=1',
  },
  {
    text: 'store',
    href: '/store',
  },
  {
    text: 'brands',
    href: '/brands',
  },
];

const initialState = {
  loading: false,
  error: null,
  content: null,
  main: null,
};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    // get school list
    case GET_MAIN_REQUEST:
      return { ...state, loading: true, content: null };

    case GET_MAIN_SUCCESS:
      let newObj = {};
      newObj = { ...state, content: action.payload, loading: false };
      newObj[action.page] = action.payload;
      return newObj;

    case GET_MAIN_FAIL:
      return { ...state, error: action.error, loading: false };

    // get nav cats
    case GET_NAV_CATS_REQUEST:
      return { ...state, error: null, loading: true };

    case GET_NAV_CATS_SUCCESS:
      const submenu_1 = action.payload.filter((item) => item.id !== 103);

      const submenu_2 = (navItems[2].submenu = action.payload.filter(
        (item) => item.id == 103
      ));

      let newNavItems = navItems;
      newNavItems[1].submenu = submenu_1;
      newNavItems[2].submenu = submenu_2;

      return {
        ...state,
        navItems: newNavItems,
        error: null,
        loading: false,
      };

    case GET_NAV_CATS_FAIL:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
};
export default pageReducer;
