import React from 'react';
import Review from './Review';

export default function ProductReviews({ reviews }) {
  return (
    <>
      {reviews.map(
        (review) =>
          review.status == 'published' && (
            <Review key={review.id} review={review} />
          )
      )}
    </>
  );
}
