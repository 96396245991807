import { Box, makeStyles, Step, Stepper, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BaseButton from 'src/components/base/BaseButton';
import Center from 'src/components/Center';

const useStyles = makeStyles((theme) => ({
  StepIconContainer: {
    // width: '100%',
    // width: 311,
    width: '40%',
    height: window.innerWidth > 280 ? 46 : 34,
    position: 'absolute',
    top: 0,
    padding: 2,
    background: '#fff',
    borderTopRightRadius: ({ dir }) => (dir == 'ltr' ? 100 : 0),
    borderBottomRightRadius: ({ dir }) => (dir == 'ltr' ? 100 : 0),

    borderTopLeftRadius: ({ dir }) => (dir == 'rtl' ? 100 : 0),
    borderBottomLeftRadius: ({ dir }) => (dir == 'rtl' ? 100 : 0),
  },
  StepIconContent: {
    width: '100%',
    height: window.innerWidth > 280 ? 42 : 30,
    // textAlign: 'center',
    // lineHeight: 3,
    color: 'white',
    borderTopRightRadius: ({ dir }) => (dir == 'ltr' ? 100 : 0),
    borderBottomRightRadius: ({ dir }) => (dir == 'ltr' ? 100 : 0),
    borderTopLeftRadius: ({ dir }) => (dir == 'rtl' ? 100 : 0),
    borderBottomLeftRadius: ({ dir }) => (dir == 'rtl' ? 100 : 0),

    fontFamily: 'roboto',
    fontWeight: 'regular',
    fontSize: 16,
  },
  arrowIcon: {
    color: 'white',
    position: 'absolute',
    right: ({ dir }) => dir == 'ltr' && 10,
    left: ({ dir }) => dir == 'rtl' && 10,
    top: window.innerWidth > 280 ? 5 : 0,
    margin: 'auto 0',
    fontSize: 34,
    [theme.breakpoints.down('xs')]: {
      right: ({ dir }) => dir == 'ltr' && window.innerWidth <= 280 && 2,
      left: ({ dir }) => dir == 'rtl' && window.innerWidth <= 280 && 2,
      fontSize: window.innerWidth <= 280 && 28,
      top: window.innerWidth <= 280 && 2,
    },
  },
  label: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 280 ? 14 : 11,
    },
  },
}));

function getSteps() {
  const stepLabels = [
    {
      label: 'myCart',
      route: '/cart',
    },
    {
      label: window.innerWidth > 550 ? 'shippingAddress' : 'shipping',
      route: '/shipping-address',
    },
    {
      label: 'checkout',
      route: '/checkout',
    },
  ];

  return stepLabels;
}

function CheckoutWizard({ activeStep }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles({ dir: i18n.dir() });
  const history = useHistory();
  const steps = getSteps();

  const { cartItems } = useSelector((state) => state.cartReducer);
  const { shippingAddress } = useSelector((state) => state.addressReducer);
  const { paymentMethodId, checkoutPayload } = useSelector(
    (state) => state.cartReducer
  );
  const { acceptTermsAndConditions, validCod, gateway_code } = checkoutPayload;

  const isTabDisabled = (targetStep) => {
    let disabled = false;
    if (targetStep > activeStep) {
      disabled =
        (activeStep == 0 && !cartItems?.length) ||
        (activeStep == 1 && !shippingAddress) ||
        (activeStep == 2 &&
          (!acceptTermsAndConditions ||
            !paymentMethodId ||
            (!validCod && gateway_code == 'cods')));
    }

    return disabled;
  };

  const handleNavigation = (route, targetStep) => {
    const disabled = isTabDisabled(targetStep);

    if (!disabled) {
      history.location.pathname !== route && history.push(route);
    }
  };

  const StepIcon = ({ idx, label, route }) => {
    const dir = i18n.dir() == 'ltr' ? 'left' : 'right';
    let stepStyle = {
      [dir]: idx == 0 ? 0 : idx == 1 ? '30%' : '60%',
      // left: idx == 0 ? 0 : idx == 1 ? 244 : 491,
      zIndex: (steps.length - idx) * 2,
      cursor: !isTabDisabled(idx) ? 'pointer' : 'not-allowed',
      // height: 25,
    };

    return (
      <div
        className={classes.StepIconContainer}
        onClick={() => handleNavigation(route, idx)}
        style={stepStyle}
      >
        <Center
          className={classes.StepIconContent}
          style={{
            background: activeStep == idx ? '#004A87' : '#C7C7C7',
          }}
        >
          {i18n.dir() == 'rtl' ? (
            <ChevronLeft className={classes.arrowIcon} />
          ) : (
            <ChevronRight className={classes.arrowIcon} />
          )}
          <Typography className={classes.label}>{t(label)}</Typography>
        </Center>
      </div>
    );
  };

  const renderStepper = () => (
    <Box mb={3} position="relative">
      <Stepper
        activeStep={activeStep}
        connector={null}
        style={{
          background: '#fff',
          width: '100%',
        }}
      >
        {steps.map(({ label, route }, idx) => (
          <Step key={label}>
            <StepIcon idx={idx} label={label} route={route} />
          </Step>
        ))}
      </Stepper>
    </Box>
  );

  return <div>{activeStep !== steps.length && renderStepper()}</div>;
}

export default CheckoutWizard;
