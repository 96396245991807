import React, { useMemo } from 'react';
import CelebrityItem from './CelebrityItem';
import BaseCarousel from '../base/BaseCarousel';
import { SwiperSlide } from 'swiper/react/swiper-react';

export default function Celebrities({ data, from }) {
  const renderCelebrityItem = useMemo(
    () => (item) => {
      return (
        <div key={Math.random().toString()}>
          <CelebrityItem item={item} from={from} />
        </div>
      );
    },
    [data]
  );

  return (
    <>
      <BaseCarousel
        breakpoints={{
          0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 40,
          },
          450: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 40,
          },
          601: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 40,
          },
          // 751: {
          //   slidesPerView: 4,
          //   slidesPerGroup: 4,
          //   spaceBetween: 20,
          // },
          940: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 40,
          },
        }}
      >
        {data?.map((item) => (
          <SwiperSlide key={Math.random().toString()}>
            {renderCelebrityItem(item)}
          </SwiperSlide>
        ))}
      </BaseCarousel>
    </>
  );
}
