import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SET_AUTH_DIALOG } from 'src/store/constants/auth.types';
import { signoutAction } from 'src/store/actions/auth.actions';

const useStyles = makeStyles((theme) => ({
  navItemSeparator: {
    height: 15,
    width: 1,
    background: theme.palette.primary.main,
    margin: '0 6px',
    [theme.breakpoints.down('xs')]: {
      margin: '0 2px',
    },
  },
  startIcon: {
    marginRight: ({ dir }) => (dir !== 'ltr' ? 0 : 3),
    marginLeft: ({ dir }) => (dir !== 'rtl' ? 0 : 3),
    transform: ({ dir }) => `scaleX(${dir == 'rtl' ? -1 : 1})`,
  },
  btnName: {
    fontSize: 13,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth <= 350 && 10,
    },
  },
}));

function AccountBtns() {
  const { t, i18n } = useTranslation();
  const classes = useStyles({ dir: i18n.dir() });
  const { userInfo } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {userInfo ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            color="primary"
            component={RouterLink}
            to="/profile"
            startIcon={
              <img
                src="/svg/user.svg"
                style={{
                  width: 15,
                  height: 15.75,
                  objectFit: 'cover',
                }}
              />
            }
            classes={{ startIcon: classes.startIcon }}
            className={classes.btnName}
          >
            {t('account')}
          </Button>
          <Typography color="primary" className={classes.btnName}>
            /
          </Typography>
          <Button
            color="primary"
            onClick={() => dispatch(signoutAction())}
            className={classes.btnName}
          >
            {t('signout')}
          </Button>
        </div>
      ) : (
        <Button
          onClick={() => dispatch({ type: SET_AUTH_DIALOG, payload: true })}
          color="primary"
          startIcon={<PersonIcon />}
          className={classes.btnName}
        >
          {t('signinSignup')}
        </Button>
      )}

      <div className={classes.navItemSeparator} />

      <div>
        <Button
          color="primary"
          component={RouterLink}
          to={`/profile/${4}`}
          className={classes.btnName}
          startIcon={
            <img
              src="/images/Icons-02.png"
              style={{ width: 15.48, height: 15.67, objectFit: 'cover' }}
            />
          }
          classes={{ startIcon: classes.startIcon }}
        >
          {t('wishlist')}
        </Button>
      </div>
    </Box>
  );
}

export default AccountBtns;
