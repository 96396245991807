import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BaseContainer from '../../components/base/BaseContainer';
import BasePage from '../../components/base/BasePage';
// import BootstrapInput from '../../components/base/BootstrapInput';
import Center from '../../components/Center';
import FormikButton from '../../components/FormUI/FormikButton';
import FormikInput from 'src/components/FormUI/FormikInput';
import FormikSelect from 'src/components/FormUI/FormikSelect';
import FormikPicker from 'src/components/FormUI/FormikPicker';
import BaseButton from 'src/components/base/BaseButton';
import { signupAction } from 'src/store/actions/auth.actions';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  // country_code: '',
  birthdate: '',
  gender: '',
  password: '',
  confirmPassword: '',
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),

  lastName: Yup.string().required('Required'),

  // country_code: Yup.string().required('Required'),

  birthdate: Yup.date().nullable().required('Required'),
  // birthdate: Yup.date().required('Required').nullable(),

  gender: Yup.string(),
  // gender: Yup.string().required('Required'),

  email: Yup.string().email('Invalid email format').required('Required'),

  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),

  password: Yup.string().required('Required'),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Passwords must match')
    .required('Required'),
});

export default function SignupPage() {
  const { t } = useTranslation();
  const [formFields, setFormFields] = useState([]);
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.authReducer);

  const onSubmit = (values) => {
    const payload = {
      first_name: values['firstName'],
      last_name: values['lastName'],
      email: values['email'],
      phone: values['phone'],
      password: values['password'],
      birthdate: values['birthdate'],
      gender: values['gender'],
      // country_code: values['country_code'],
      role: 4,
    };

    dispatch(signupAction(payload));
  };

  useEffect(() => {
    const genderOptions = [
      {
        name: t('male'),
        value: 'M',
      },
      {
        name: t('female'),
        value: 'F',
      },
    ];

    const fields = [
      {
        name: 'firstName',
        label: 'fname',
        Component: FormikInput,
      },
      {
        name: 'lastName',
        label: 'lname',
        Component: FormikInput,
      },
      {
        name: 'email',
        label: 'email',
        Component: FormikInput,
        type: 'email',
      },
      {
        name: 'phone',
        label: 'phone',
        Component: FormikInput,
      },
      {
        name: 'birthdate',
        label: 'birthdate',
        Component: FormikPicker,
      },
      {
        name: 'gender',
        label: 'gender',
        Component: FormikSelect,
        options: genderOptions,
      },
      {
        name: 'password',
        label: 'password',
        Component: FormikInput,
        type: 'password',
      },
      {
        name: 'confirmPassword',
        label: 'confirmPassword',
        Component: FormikInput,
        type: 'password',
      },
    ];

    setFormFields(fields);
  }, []);

  return (
    <BasePage style={{ marginBottom: 60 }}>
      <BaseContainer>
        <Center style={{ flexDirection: 'column', marginBottom: 50 }}>
          <Typography
            variant="h2"
            color="primary"
            style={{ fontFamily: 'sfUiTextRegular', fontSize: 53 }}
            align="center"
          >
            {t('beOurFriend')} <br />
            {t('signupNow')}
          </Typography>
          {/* <Typography variant="h2" color="primary">
            {t('signupNow')}
          </Typography> */}
        </Center>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          <Form noValidate>
            <Grid container spacing={2}>
              {formFields.map(({ Component, label, ...rest }) => (
                <Grid key={Math.random().toString()} item xs={12} sm={6}>
                  <Component label={t(label)} {...rest} />
                  {/* <FormikInput label={t(field.label)} name={field.name} /> */}
                </Grid>
              ))}
            </Grid>
            <Center style={{ flexDirection: 'column' }}>
              {error && (
                <Typography
                  variant="body2"
                  color="error"
                  style={{
                    marginTop: 20,
                  }}
                >
                  {error}
                </Typography>
              )}

              <FormikButton
                text={t('signup')}
                disabled={loading}
                style={{
                  width: '30%',
                  borderRadius: 6,
                  marginTop: 50,
                  fontFamily: 'roboto',
                }}
              />
            </Center>
          </Form>
        </Formik>
      </BaseContainer>
    </BasePage>
  );
}
