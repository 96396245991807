import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BasePage from 'src/components/base/BasePage';

import FormikButton from 'src/components/FormUI/FormikButton';
import FormikInput from 'src/components/FormUI/FormikInput';
import { testUserCredentialsAction } from 'src/store/actions/auth.actions';
import { updateProfileAction } from 'src/store/actions/user.actions';

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Required'),

  newPassword: Yup.string().required('Required'),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
    .required('Required'),
});

const fields = [
  {
    name: 'currentPassword',
    label: 'currentPassword',
    offset: true,
  },
  {
    name: 'newPassword',
    label: 'newPassword',
  },
  {
    name: 'confirmPassword',
    label: 'confirmPassword',
  },
];

export default function ChangePassword() {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const payload = {
      password: values['currentPassword'],
    };
    // login user to validate credentials
    const isValid = await dispatch(
      testUserCredentialsAction(values['currentPassword'])
    );
    // console.log('isValid', isValid);
    if (isValid.token) {
      console.log('change pass');
      dispatch(updateProfileAction(payload, userInfo.id));
      // dispatch(changePasswordAction(values['newPassword'], navigation));
    } else {
      console.log('wrong pass');
    }
  };

  return (
    <Box mt={2}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form>
          <Grid container spacing={2}>
            {fields.map(({ label, name, offset }) => (
              <Grid key={name} item xs={12} sm={offset ? 12 : 6}>
                <Box
                  width={offset && window.innerWidth >= 600 ? '49%' : '100%'}
                >
                  <FormikInput type="password" label={t(label)} name={name} />
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" mt={2}>
            <Box flexGrow={1} />
            <FormikButton
              text={t('updatePassword')}
              style={{
                width: window.innerWidth > 599 ? 230 : '100%',
                marginTop: 10,
              }}
              textStyle={{ fontSize: window.innerWidth <= 599 && 16 }}
            />
          </Box>
        </Form>
      </Formik>
    </Box>
  );
}
