export const GET_COUNTRY_INFO_REQUEST = "GET_COUNTRY_INFO_REQUEST";
export const GET_COUNTRY_INFO_SUCCESS = "GET_COUNTRY_INFO_SUCCESS";
export const GET_COUNTRY_INFO_FAIL = "GET_COUNTRY_INFO_FAIL";

export const GET_CURRENCY_INFO_REQUEST = "GET_CURRENCY_INFO_REQUEST";
export const GET_CURRENCY_INFO_SUCCESS = "GET_CURRENCY_INFO_SUCCESS";
export const GET_CURRENCY_INFO_FAIL = "GET_CURRENCY_INFO_FAIL";

export const GET_USER_ORDERS_REQUEST = "GET_USER_ORDERS_REQUEST";
export const GET_USER_ORDERS_SUCCESS = "GET_USER_ORDERS_SUCCESS";
export const GET_USER_ORDERS_FAIL = "GET_USER_ORDERS_FAIL";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const CONTACT_TECH_SUPPORT_REQUEST = "CONTACT_TECH_SUPPORT_REQUEST";
export const CONTACT_TECH_SUPPORT_SUCCESS = "CONTACT_TECH_SUPPORT_SUCCESS";
export const CONTACT_TECH_SUPPORT_FAIL = "CONTACT_TECH_SUPPORT_FAIL";

export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const SET_DEVICE_TYPE = "SET_DEVICE_TYPE";
