import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  Cancel,
  CheckCircle,
  Close,
  Favorite,
  FavoriteBorder,
  Notifications,
  NotificationsActive,
  ShoppingCart,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BaseButton from 'src/components/base/BaseButton';
import Rating from 'src/components/Rating';
import ShareDialog from 'src/components/ShareDialog';
import {
  addToCartAction,
  removeCartItemAction,
} from 'src/store/actions/cart.actions';
import { convertToArabicNumbers } from 'src/utils';

const useClasses = makeStyles((theme) => ({
  actionsWrapper: {
    width: '41.6%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  priceContaier: {
    display: 'flex',
    alignItems: 'center',
  },
  price: {
    fontSize: 17,
    color: theme.palette.primary.main,
    fontFamily: 'roboto',
    fontWeight: 700,
    direction: 'ltr',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  discount: {
    fontSize: 13,
    color: theme.custom.myTheme.grey3,
    fontFamily: 'roboto',
    fontWeight: 400,
    textDecoration: 'line-through',
    margin: '0 30px',
    direction: 'ltr',
  },

  startIcon: {
    marginRight: ({ dir }) => (dir == 'ltr' ? 0 : 3),
    marginLeft: ({ dir }) => (dir == 'ltr' ? 0 : 3),
    transform: ({ dir }) => `scaleX(${dir == 'rtl' ? -1 : 1})`,
    transform: ({ dir }) => `scaleX(${dir == 'rtl' ? -1 : 1})`,
  },
  basket: {
    transform: ({ dir }) => `scaleX(${dir == 'rtl' ? -1 : 1})`,
    color: '#fff',
    marginRight: ({ dir }) => (dir == 'ltr' ? 4 : 0),
    marginLeft: ({ dir }) => (dir == 'rtl' ? 4 : 0),
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  name: {
    fontSize: 28,
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
}));

export default function ProductMainInfo() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const dispatch = useDispatch();
  const classes = useClasses({ dir: i18n.dir() });

  // const [rating, setRating] = useState(0);
  const [review, setReview] = useState(0);

  const { product } = useSelector((state) => state.productReducer);
  const { currencyInfo } = useSelector((state) => state.userReducer);
  const { wishlistProducts, notifymeProducts, productId, loading } =
    useSelector((state) => state.cartReducer);

  const [showProgress, setShowProgress] = useState(false);

  const searchParams = new URLSearchParams(useLocation().search);
  const celebrityId = searchParams.get('celebrityId');

  const checkExistance = (type) => {
    const getItem = (data) =>
      data?.find((productItem) => productItem.product_id?.id == product.id);

    if (type == 'notifyMe') return getItem(notifymeProducts);
    else return getItem(wishlistProducts);
  };

  const cartHandler = () => {
    if (product.quantity > 0) {
      dispatch(addToCartAction('cart', product.id, 1, celebrityId));
    } else {
      if (notifymeProducts) {
        const productExists = notifymeProducts.find(
          (notifymeItem) => notifymeItem.product_id?.id == product.id
        );

        productExists
          ? dispatch(removeCartItemAction('notify_me', productExists.id))
          : dispatch(addToCartAction('notify_me', product.id));
      }
    }
  };

  const whishlistHandler = () => {
    if (wishlistProducts) {
      const productExists = wishlistProducts.find(
        (item) => item.product_id?.id == product.id
      );
      productExists
        ? dispatch(
            removeCartItemAction('favorite', productExists.id, product.id)
          )
        : dispatch(addToCartAction('favorite', product.id));
    }
  };

  useEffect(() => {
    if (productId == product.id) {
      setShowProgress(productId == product.id && loading);
    }
  }, [productId, product.id, loading]);

  useEffect(() => {
    let ratingItem = 0;
    let reviewItem = 0;

    if (product) {
      product.rating.forEach((item) => {
        if (item.status == 'published') {
          ratingItem += item.rating;

          if (item.review) reviewItem += 1;
        }
      });

      setReview(reviewItem);
      // setRating(ratingItem);
    }
  }, [product]);

  const getShareInfo = () => {
    const title = product[
      lang == 'ar'
        ? 'arabic_title'
        : lang == 'tr'
        ? 'turkish_title'
        : 'english_title'
    ]?.slice(0, 100);

    const description = product[
      lang == 'ar'
        ? 'arabic_description'
        : lang == 'tr'
        ? 'turkish_description'
        : 'english_description'
    ]?.slice(0, 100);

    return { title, description };
  };

  const getStartIcon = () => {
    let Icon;
    if (product.quantity > 0) {
      Icon = ShoppingCart;
    } else {
      if (checkExistance('notifyMe')?.id) Icon = NotificationsActive;
      else Icon = Notifications;
    }

    return <Icon className={classes.basket} />;
  };

  return (
    <>
      <Typography color="primary" className={classes.name}>
        {
          product[
            lang == 'ar'
              ? 'arabic_title'
              : lang == 'tr'
              ? 'turkish_title'
              : 'english_title'
          ]
        }
      </Typography>

      <Box mt={3} mb={3}>
        <Typography variant="body1" color="textSecondary">
          {t('authorName')}:{' '}
          {product.authors.map((author) => (
            <span key={Math.random().toString()}>
              {
                author?.author_id[
                  lang == 'ar'
                    ? 'arabic_title'
                    : lang == 'tr'
                    ? 'turkish_title'
                    : 'english_title'
                ]
              }
            </span>
          ))}
        </Typography>

        <Typography variant="body1" color="textSecondary">
          {t('prodYear')}: {product.years}
        </Typography>
      </Box>
      <Grid container justifyContent="space-between">
        <Grid item xs={7}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              {product.quantity > 0 ? (
                <>
                  <CheckCircle color="secondary" />
                  <Typography
                    variant="body1"
                    color="secondary"
                    style={{ margin: '0 10px' }}
                  >
                    {t('inStock')}
                  </Typography>
                </>
              ) : (
                <>
                  <Cancel color="error" />
                  <Typography
                    variant="body1"
                    color="error"
                    style={{ margin: '0 10px' }}
                  >
                    {t('outOfStock')}
                  </Typography>
                </>
              )}
            </Box>

            {/* price */}
            <div className={classes.priceContaier}>
              <Typography className={classes.price}>
                {currencyInfo?.title + ' '}
                {convertToArabicNumbers(
                  Number(
                    (product.discounted_price || product.actual_price) *
                      currencyInfo.from
                  ).toFixed(2) + '',
                  i18n.dir()
                )}
              </Typography>
              {product.discounted_price && product.actual_price && (
                <Typography className={classes.discount}>
                  {currencyInfo?.title + ' '}
                  {convertToArabicNumbers(
                    (product.actual_price * currencyInfo.from).toFixed(2) + '',
                    i18n.dir()
                  )}
                </Typography>
              )}
            </div>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Rating rating={4} />

            <Typography variant="body2" color="textSecondary">
              {review ? review + ' ' + t('reviews') : t('noReviews')}
            </Typography>
            <ShareDialog shareInfo={getShareInfo()} />
          </div>
        </Grid>
        <Grid item container justifyContent="flex-end" xs={12}>
          <div className={classes.actionsWrapper}>
            <BaseButton
              color="primary"
              onClick={cartHandler}
              text={t(
                product.quantity > 0
                  ? 'addToCart'
                  : checkExistance('notifyMe')?.id
                  ? 'willNotify'
                  : 'notifyMe'
              )}
              style={{
                width: '100%',
                borderRadius: 0,
                marginBottom: 5,
                background: product.quantity <= 0 && '#8b8b8b',
              }}
              // text={t(product.quantity > 0 ? 'addToCart' : 'notifyMe')}
              // startIcon={<ShoppingCart />}
              startIcon={getStartIcon()}
              classes={{ startIcon: classes.startIcon }}
              disabled={showProgress}
              textStyle={{ fontWeight: 'bold', fontSize: 15 }}
            />
            <BaseButton
              style={{ width: '100%', borderRadius: 0, color: 'white' }}
              color="secondary"
              onClick={whishlistHandler}
              text={t(
                checkExistance() ? 'removeFromWishlist' : 'addToWishlist'
              )}
              startIcon={
                checkExistance() ? (
                  <Favorite className={classes.basket} />
                ) : (
                  <FavoriteBorder className={classes.basket} />
                )
              }
              classes={{ startIcon: classes.startIcon }}
              textStyle={{ fontWeight: 'bold', fontSize: 15 }}
              disabled={showProgress}
            />
            {showProgress && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
