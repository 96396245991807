import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { format } from 'date-fns';

import Rating from 'src/components/Rating';

export default function Review({ review }) {
  return (
    <div>
      <Typography variant="body1" color="textSecondary">
        {review.customer_id.first_name + ' ' + review.customer_id.last_name}
      </Typography>

      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <Rating oldRating={review.rating} />
          {/* <Box mx={2}>
            <Typography variant="body1" color="secondary">
              {review.created_at}
            </Typography>
          </Box> */}
        </Box>

        <Typography variant="body2" color="textSecondary">
          On{' '}
          {format(
            new Date(review.created_on),
            window.innerWidth > 370 ? 'MMMM do yyyy' : 'MMM do yyyy'
          )}
        </Typography>
      </Box>

      <Box mx={1}>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ fontFamily: 'Roboto', fontWeight: 600 }}
        >
          {review.title}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ fontFamily: 'Roboto', fontWeight: 200 }}
        >
          {review.review}
        </Typography>
      </Box>
    </div>
  );
}
