import { Box, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import FormikButton from 'src/components/FormUI/FormikButton';
import FormikInput from 'src/components/FormUI/FormikInput';
import {
  getCitiesAction,
  getCountriesAction,
} from 'src/store/actions/aramix.actions';
import FormikSelect from './FormUI/FormikSelect';
import {
  createAddressAction,
  updateAddressAction,
} from 'src/store/actions/address.actions';
import FormikSearchSelect from './FormUI/FormikSearchSelect';
import { useHistory } from 'react-router-dom';
import Loading from './base/Loading';

const initialValues = {
  first_name: '',
  last_name: '',
  countryCode: '',
  city: '',
  detail: '',
  post_code: '',
  address_line_1: '',
  address_line_2: '',
  phone: '',
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),

  last_name: Yup.string().required('Required'),

  countryCode: Yup.string().required('Required'),
  city: Yup.string().required('Required'),

  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),

  detail: Yup.string(),
  post_code: Yup.string().required('Required'),
  address_line_1: Yup.string().required('Required'),
  address_line_2: Yup.string().required('Required'),
});

const useClasses = makeStyles((theme) => ({
  label: {
    color: theme.custom.myTheme.grey3,
    // [theme.breakpoints.down('xs')]: {

    // }
  },
}));

export default function AddressForm({ record, routePath }) {
  const { t } = useTranslation();
  const { aramixCountries, aramixCities } = useSelector(
    (state) => state.aramixReducer
  );

  const { loading, error } = useSelector((state) => state.addressReducer);
  const { userInfo } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const classes = useClasses();
  const [countryCode, setCountryCode] = useState();
  const { push } = useHistory();

  const onSubmit = async (values) => {
    const country = aramixCountries.find(
      (item) => item.Code == values.countryCode
    );

    const payload = {
      full_name: values['first_name'] + ' ' + values['last_name'],
      country_name: country.Name,
      country_code: country.Code,
      country_iso_code: country.IsoCode,
      city: values['city'],
      detail: values['detail'].trim(),
      post_code: values['post_code'],
      phone: values['phone'],
      address_line_1: values['address_line_1'],
      address_line_2: values['address_line_2'],
      // customer_data: +userInfo.id,
    };
    // ==================

    let updatePayload = {};
    // let updatedAddress;
    if (record) {
      Object.entries(payload).forEach(([key, value]) => {
        if (payload[key] && payload[key] !== record[key])
          updatePayload[key] = value;
      });
      if (Object.keys(updatePayload).length) {
        dispatch(updateAddressAction(updatePayload, record.id, routePath));
      }
    } else {
      payload['customer_data'] = +userInfo.id;
      dispatch(createAddressAction(payload, routePath));
    }

    // if (redirect && updatedAddress) {
    //   dispatch(saveAdressToLocalStorageAction(updatedAddress));
    //   window.location.reload();
    //   // push('/checkout');
    // }
  };

  const fields = [
    {
      name: 'first_name',
      label: t('fname'),
      Component: FormikInput,
      cols: 6,
    },
    {
      name: 'last_name',
      label: t('lname'),
      Component: FormikInput,
      cols: 6,
    },
    {
      name: 'countryCode',
      label: t('country'),
      Component: FormikSelect,
      options: aramixCountries || [],
      setCountryCode: setCountryCode,
      cols: 6,
    },
    {
      name: 'city',
      label: t('city'),
      Component: FormikSearchSelect,
      // Component: FormikSelect,
      options: aramixCities || [],
      // initialConfigSelect: { label: 'city' },
      // autoComplete: true,
      cols: 6,
    },
    {
      name: 'phone',
      label: t('phone'),
      Component: FormikInput,
    },
    {
      name: 'post_code',
      label: t('postCode'),
      Component: FormikInput,
    },
    {
      name: 'address_line_1',
      label: t('addressLine1'),
      Component: FormikInput,
    },
    {
      name: 'address_line_2',
      label: t('addressLine2'),
      Component: FormikInput,
    },
    {
      name: 'detail',
      label: t('moreInfo'),
      Component: FormikInput,
      multiline: true,
      rows: 5,
    },
  ];

  useEffect(() => {
    !aramixCountries && dispatch(getCountriesAction());
  }, [aramixCountries]);

  useEffect(() => {
    countryCode && dispatch(getCitiesAction(countryCode));
  }, [countryCode, dispatch]);

  useEffect(() => {
    if (record) {
      Object.keys(initialValues).forEach((key) => {
        record[key]
          ? (initialValues[key] = record[key]?.Code || record[key])
          : (initialValues[key] = '');
      });
      record.country_code &&
        (initialValues['countryCode'] = record.country_code);
      initialValues['first_name'] = record['full_name'].split(' ')[0];
      initialValues['last_name'] = record['full_name'].split(' ')[1];
      setCountryCode(record.country_code);
    }
  }, [record]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        <Form>
          <Grid container spacing={2}>
            {fields.map(({ Component, label, cols, ...rest }) => (
              <Grid key={Math.random().toString()} item xs={12} sm={cols || 12}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Hidden xsDown>
                    <div
                      style={{ width: cols == 6 ? '45%' : '18%' }}
                      className={classes.label}
                    >
                      {label}
                    </div>
                    <Component {...rest} />
                  </Hidden>

                  <Hidden smUp>
                    <Component label={t(label)} {...rest} />
                  </Hidden>
                </div>
                {/* <Component {...rest} /> */}
              </Grid>
            ))}
          </Grid>

          <Box display="flex" alignItems="center">
            <Box flexGrow={1} />
            {/* {true && (
              <Typography
                variant="body2"
                color="error"
                style={{
                  margin: '0 20px',
                  padding: 12,
                  marginTop: 20,
                  width: '30%',
                }}
              >
                {'error'}
              </Typography>
            )} */}
            <FormikButton
              text={t('save')}
              style={{
                width: 150,
                borderRadius: 6,
                marginTop: 20,
              }}
            />
          </Box>
        </Form>
      </Formik>

      {error && (
        <Typography
          variant="body2"
          color="error"
          align="center"
          style={{
            marginTop: 20,
          }}
        >
          {error}
        </Typography>
      )}
    </>
  );
}
