export const GET_ADDRESS_ITEMS_REQUEST = "GET_ADDRESS_ITEMS_REQUEST";
export const GET_ADDRESS_ITEMS_SUCCESS = "GET_ADDRESS_ITEMS_SUCCESS";
export const GET_ADDRESS_ITEMS_FAIL = "GET_ADDRESS_ITEMS_FAIL";

export const ADD_TO_ADDRESS_REQUEST = "ADD_TO_ADDRESS_REQUEST";
export const ADD_TO_ADDRESS_SUCCESS = "ADD_TO_ADDRESS_SUCCESS";
export const ADD_TO_ADDRESS_FAIL = "ADD_TO_ADDRESS_FAIL";

export const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAIL = "UPDATE_ADDRESS_FAIL";

export const REMOVE_FROM_ADDRESS_REQUEST = "REMOVE_FROM_ADDRESS_REQUEST";
export const REMOVE_FROM_ADDRESS_SUCCESS = "REMOVE_FROM_ADDRESS_SUCCESS";
export const REMOVE_FROM_ADDRESS_FAIL = "REMOVE_FROM_ADDRESS_FAIL";

export const RESET_ADDRESS_REQUEST = "RESET_ADDRESS_REQUEST";
export const RESET_ADDRESS_SUCCESS = "RESET_ADDRESS_SUCCESS";
export const RESET_ADDRESS_FAIL = "RESET_ADDRESS_FAIL";

export const REMOVE_ADDRESS_ITEM_FAIL = "REMOVE_ADDRESS_ITEM_FAIL";
export const REMOVE_ADDRESS_ITEM_REQUEST = "REMOVE_ADDRESS_ITEM_REQUEST";
export const REMOVE_ADDRESS_ITEM_SUCCESS = "REMOVE_ADDRESS_ITEM_SUCCESS";

export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAIL = "GET_CITIES_FAIL";

export const VALIDATE_ADDRESS_FAIL = "VALIDATE_ADDRESS_FAIL";
export const VALIDATE_ADDRESS_REQUEST = "VALIDATE_ADDRESS_REQUEST";
export const VALIDATE_ADDRESS_SUCCESS = "VALIDATE_ADDRESS_SUCCESS";

export const RESET_HAS_ERROR = "RESET_HAS_ERROR";

export const CALCULATE_SHIPPING__FAIL = "CALCULATE_SHIPPING__FAIL";
export const CALCULATE_SHIPPING__REQUEST = "CALCULATE_SHIPPING__REQUEST";
export const CALCULATE_SHIPPING__SUCCESS = "CALCULATE_SHIPPING__SUCCESS";

export const CALCULATE_SHIPPING_REQUEST = "CALCULATE_SHIPPING_REQUEST";
export const CALCULATE_SHIPPING_SUCCESS = "CALCULATE_SHIPPING_SUCCESS";
export const CALCULATE_SHIPPING_FAIL = "CALCULATE_SHIPPING_FAIL";

export const RESET_ADDRESS = "RESET_ADDRESS";

export const SET_SHIPPING_ADDRESS = "SET_SHIPPING_ADDRESS";
