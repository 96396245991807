import React, { useState } from 'react';
import Dialog from 'src/components/Dialog';
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  TelegramIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import { IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Share } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HelmetMetaData from './HelmetMetaData';

const sharePlateforms = [
  {
    btn: FacebookShareButton,
    icon: FacebookIcon,
  },
  {
    btn: TwitterShareButton,
    icon: TwitterIcon,
  },
  {
    btn: FacebookMessengerShareButton,
    icon: FacebookMessengerIcon,
  },
  {
    btn: WhatsappShareButton,
    icon: WhatsappIcon,
  },
  {
    btn: TelegramShareButton,
    icon: TelegramIcon,
  },
];

const useClasses = makeStyles(() => ({
  share: {
    transform: ({ dir }) => `scaleX(${dir == 'rtl' ? -1 : 1})`,
  },
}));

function ShareDialog({ shareInfo }) {
  const { t, i18n } = useTranslation();
  const classes = useClasses({ dir: i18n.dir() });
  const history = useHistory();
  const params = useParams();
  const [shareDialog, setShareDialog] = useState(false);
  const { product } = useSelector((state) => state.productReducer);
  const lang = i18n.language.split('-')[0];

  const getShareUrl = () => {
    const SHARE_URL = process.env.REACT_APP_SHARE_URL;

    // return SHARE_URL + history.location.pathname`;

    const { productId } = params;
    return `${SHARE_URL}?productId=${productId}&quantity=${product?.quantity}`;
  };

  const renderShareDialog = () => (
    <Dialog
      open={shareDialog}
      closeDialog={() => setShareDialog(false)}
      title={t('selectPlateform')}
    >
      <div
        style={{
          display: 'flex',
          width: 400,
          justifyContent: 'space-around',
          padding: window.innerWidth >= 375 ? 20 : 0,
        }}
      >
        {sharePlateforms.map(({ btn: ShareButton, icon: ShareIcon }) => (
          <div key={Math.random().toString()}>
            <ShareButton
              url={getShareUrl()}
              title={shareInfo.title}
              description="this is my description"
              quote={'quote - i like this part'}
              hashtag="#my_book"

              // quote={'some quote'}
              // hashtag={'#hashtag'}
              // hashtags={['#hashtag1', '#hashtag2']}
            >
              <ShareIcon size={window.innerWidth >= 375 ? 44 : 25} round />
            </ShareButton>
          </div>
        ))}
      </div>
    </Dialog>
  );

  return (
    <>
      <HelmetMetaData
        title={shareInfo.title}
        description={shareInfo.description}
        // image={
        //   'https://diwanworld.app/diwanapi/api/public/uploads/diwan/originals/355792b1-cf10-443a-8316-47630012fe88.jpg'
        // }
        quote={'Dardiwan - my lovely book'}
        // hashtag="#camperstribe"
      />
      <IconButton color="primary" onClick={() => setShareDialog(true)}>
        <Share color="primary" fontSize="small" className={classes.share} />
      </IconButton>

      {renderShareDialog()}
    </>
  );
}

export default ShareDialog;
