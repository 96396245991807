import ApiService from 'src/services/api.service';
import { localStoragePrefix, ROUTE_PATH, SHIPPING_ADDRESS } from 'src/config';
import {
  ADD_TO_ADDRESS_FAIL,
  ADD_TO_ADDRESS_REQUEST,
  ADD_TO_ADDRESS_SUCCESS,
  GET_ADDRESS_ITEMS_FAIL,
  GET_ADDRESS_ITEMS_REQUEST,
  GET_ADDRESS_ITEMS_SUCCESS,
  GET_CITIES_FAIL,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  REMOVE_ADDRESS_ITEM_FAIL,
  REMOVE_ADDRESS_ITEM_REQUEST,
  REMOVE_ADDRESS_ITEM_SUCCESS,
  RESET_ADDRESS,
  UPDATE_ADDRESS_FAIL,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  VALIDATE_ADDRESS_FAIL,
  VALIDATE_ADDRESS_REQUEST,
  RESET_HAS_ERROR,
  SET_SHIPPING_ADDRESS,
} from '../constants/address.types';

const getUserAddressesAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ADDRESS_ITEMS_REQUEST });

    const {
      authReducer: { userInfo: user },
    } = getState();

    const {
      userReducer: { lang },
    } = getState();

    // let lang = "en";
    let url;
    if (lang == 'ar')
      url = `items/customers_addresses?fields=id,address_line_1,address_line_2,phone,full_name,post_code,countery_id.id,countery_id.arabic_title,city_id.id,city_id.arabic_title,detail,country_name,country_code,country_iso_code,city&filter[customer_data]=${user.id}&filter[status]=published`;
    else if (lang == 'en')
      url = `items/customers_addresses?fields=id,address_line_1,address_line_2,phone,full_name,post_code,countery_id.id,countery_id.english_title,city_id.id,city_id.english_title,detail,country_name,country_code,country_iso_code,city&filter[customer_data]=${user.id}&filter[status]=published`;
    else if (lang == 'tr')
      url = `items/customers_addresses?fields=id,address_line_1,address_line_2,phone,full_name,post_code,countery_id.id,countery_id.english_title,city_id.id,city_id.english_title,detail,country_name,country_code,country_iso_code,city&filter[customer_data]=${user.id}&filter[status]=published`;

    if (lang) {
      const { data } = await ApiService.get(url);
      dispatch({ type: GET_ADDRESS_ITEMS_SUCCESS, payload: data.data });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_ADDRESS_ITEMS_FAIL, error: message });
  }
};

export const removeAddressItemAction =
  (addressId, routePath) => async (dispatch) => {
    try {
      dispatch({ type: REMOVE_ADDRESS_ITEM_REQUEST });
      // await ApiService.delete(`items/customers_addresses/${addressId}`);
      await ApiService.patch(`items/customers_addresses/${addressId}`, {
        status: 'draft',
      });
      dispatch({ type: REMOVE_ADDRESS_ITEM_SUCCESS });
      dispatch(getUserAddressesAction());

      const shippingAddress = JSON.parse(
        localStorage.getItem(localStoragePrefix + SHIPPING_ADDRESS)
      );
      if (shippingAddress.id == addressId) {
        localStorage.removeItem(localStoragePrefix + SHIPPING_ADDRESS);
      }

      if (routePath) {
        localStorage.setItem(localStoragePrefix + ROUTE_PATH, routePath);
      }

      window.location.reload();
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: REMOVE_ADDRESS_ITEM_FAIL, error: message });
    }
  };

export const resetAddressAction = () => async (dispatch) => {
  try {
    localStorage.removeItem(localStoragePrefix + 'address');
    dispatch({ type: RESET_ADDRESS });
  } catch (error) {
    dispatch({ type: ADD_TO_ADDRESS_FAIL, error });
  }
};

const saveAdressToLocalStorageAction =
  (selectedAddress) => async (dispatch) => {
    localStorage.setItem(
      localStoragePrefix + SHIPPING_ADDRESS,
      JSON.stringify(selectedAddress)
    );
    dispatch({
      type: SET_SHIPPING_ADDRESS,
      payload: selectedAddress,
    });
  };

export const createAddressAction = (payload, routePath) => async (dispatch) => {
  try {
    dispatch({ type: ADD_TO_ADDRESS_REQUEST });
    const validateAddress = await dispatch(validateAddressAction(payload));
    if (!validateAddress.HasErrors) {
      const { data } = await ApiService.post(
        `items/customers_addresses`,
        payload
      );
      // dispatch({ type: ADD_TO_ADDRESS_SUCCESS });
      dispatch(saveAdressToLocalStorageAction(data.data));

      if (routePath) {
        localStorage.setItem(localStoragePrefix + ROUTE_PATH, routePath);
      }

      window.location.reload();
      // return data.data;
    } else {
      console.log(validateAddress.Notifications[0].Message);
      dispatch({
        type: ADD_TO_ADDRESS_FAIL,
        error: validateAddress.Notifications[0].Message,
      });
      // return false;
    }
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ADD_TO_ADDRESS_FAIL, error: message });
    // return false;
  }
};

export const updateAddressAction =
  (payload, addressId, routePath) => async (dispatch, getState) => {
    try {
      let validateAddress = null;
      dispatch({ type: UPDATE_ADDRESS_REQUEST });

      if (payload.country_code) {
        validateAddress = await dispatch(validateAddressAction(payload));
      }

      if (validateAddress?.HasErrors) {
        dispatch({
          type: UPDATE_ADDRESS_FAIL,
          error: validateAddress.Notifications[0],
        });
      } else {
        // const {addressReducer: {shippingAddress}} = getState()
        await ApiService.patch(
          `items/customers_addresses/${addressId}`,
          payload
        );
        // dispatch({ type: UPDATE_ADDRESS_SUCCESS });
        // if (routePath) {
        //   localStorage.setItem(localStoragePrefix + ROUTE_PATH, routePath);
        // }

        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: UPDATE_ADDRESS_FAIL, error: message });
    }
  };

export const validateAddressAction =
  ({ country_code, city, post_code }) =>
  async (dispatch) => {
    try {
      dispatch({ type: VALIDATE_ADDRESS_REQUEST });

      const { data } = await ApiService.get(
        `custom/validateAddress?CountryCode=${country_code}&City=${city}&PostCode=${post_code}`
      );

      return data;
      // return data.HasErrors;

      // dispatch({ type: VALIDATE_ADDRESS_SUCCESS, payload: data.data.HasErrors });
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: VALIDATE_ADDRESS_FAIL, error: message });
    }
  };

export const getCountriesAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_COUNTRIES_REQUEST });
    const { data } = await ApiService.get('items/counteries?fields=*');
    dispatch({ type: GET_COUNTRIES_SUCCESS, payload: data.data });
    console.log('end data.data', data.data);
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_COUNTRIES_FAIL, error: message });
  }
};

export const getCitiesAction = (countryId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CITIES_REQUEST });

    const { data } = await ApiService.get(
      `items/cities?fields=*&filter[country_id]=${countryId}`
    );
    dispatch({ type: GET_CITIES_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_CITIES_FAIL, error: message });
  }
};

export const resetHasErrorAction = () => async (dispatch) => {
  dispatch({ type: RESET_HAS_ERROR });
};

export { getUserAddressesAction, saveAdressToLocalStorageAction };
