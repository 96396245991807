import {
  GET_COUPON_FAIL,
  GET_COUPON_REQUEST,
  GET_COUPON_SUCCESS,
} from "../constants/coupon.types";

const initialState = {
  loading: false,
  error: null,
  coupon: null,
};

const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    // get school list
    case GET_COUPON_REQUEST:
      return { ...state, loading: true, coupon: null };

    case GET_COUPON_SUCCESS:
      return { ...state, coupon: action.payload, loading: false };

    case GET_COUPON_FAIL:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
};
export default couponReducer;
