import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export default function HelmetMetaData(props) {
  // let { title, description, image, quote, hashtag } = props;

  const location = useLocation();
  const currentUrl = process.env.REACT_APP_DOMAIN_URL + location.pathname;

  const quote = 'this is some quote';
  // quote = quote || 'this is some quote';

  const title = 'Dardiwan - brings knowledge for you';

  // image =
  //   // image ||
  //   'https://diwanworld.app/diwanapi/api/public/uploads/diwan/originals/355792b1-cf10-443a-8316-47630012fe88.jpg';

  const imgUrl =
    'https://diwanworld.app/diwanapi/api/public/uploads/diwan/originals/355792b1-cf10-443a-8316-47630012fe88.jpg';

  const description =
    // description ||
    'CampersTribe lets you experience the camping culture. We discover the hidden gems in the nearby to help you connect with nature & yourself by learning in the woods, on the riverbank under the open sky.' +
    'Trust us, its million dollars experience to ride away from city life, pitch a tent, do campfire and endless talk!' +
    'So, join us on this voyage, and explore the beauty and miracle of being yourself!';

  const hashtag = '#book #diwan #dardiwan #diwanworld';

  return (
    <Helmet>
      <title>{title}</title>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta property="image" content={imgUrl} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={imgUrl} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="CampersTribe" />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}
