export const GET_CART_ITEMS_REQUEST = "GET_CART_ITEMS_REQUEST";
export const GET_CART_ITEMS_SUCCESS = "GET_CART_ITEMS_SUCCESS";
export const GET_CART_ITEMS_FAIL = "GET_CART_ITEMS_FAIL";

export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL";

export const REMOVE_FROM_CART_REQUEST = "REMOVE_FROM_CART_REQUEST";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_FAIL = "REMOVE_FROM_CART_FAIL";

export const RESET_CART_REQUEST = "RESET_CART_REQUEST";
export const RESET_CART_SUCCESS = "RESET_CART_SUCCESS";
export const RESET_CART_FAIL = "RESET_CART_FAIL";

export const REMOVE_CART_ITEM_FAIL = "REMOVE_CART_ITEM_FAIL";
export const REMOVE_CART_ITEM_REQUEST = "REMOVE_CART_ITEM_REQUEST";
export const REMOVE_CART_ITEM_SUCCESS = "REMOVE_CART_ITEM_SUCCESS";

export const CHECKOUT_FAIL = "CHECKOUT_FAIL";
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";

export const SHIPPING_METHOD_FAIL = "SHIPPING_METHOD_FAIL";
export const SHIPPING_METHOD_REQUEST = "SHIPPING_METHOD_REQUEST";
export const SHIPPING_METHOD_SUCCESS = "SHIPPING_METHOD_SUCCESS";

export const PAYMENT_METHOD_FAIL = "PAYMENT_METHOD_FAIL";
export const PAYMENT_METHOD_REQUEST = "PAYMENT_METHOD_REQUEST";
export const PAYMENT_METHOD_SUCCESS = "PAYMENT_METHOD_SUCCESS";

// wishlist
export const GET_WISHLIST_REQUEST = "GET_WISHLIST_REQUEST";
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS";
export const GET_WISHLIST_FAIL = "GET_WISHLIST_FAIL";

export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAIL = "ADD_TO_WISHLIST_FAIL";

// notify me
export const ADD_TO_NOTIFY_SUCCESS = "ADD_TO_NOTIFY_SUCCESS";
export const ADD_TO_NOTIFY_FAIL = "ADD_TO_NOTIFY_FAIL";
export const ADD_TO_NOTIFY_REQUEST = "ADD_TO_NOTIFY_REQUEST";

export const GET_NOTIFY_ME_SUCCESS = "GET_NOTIFY_ME_SUCCESS";
export const GET_NOTIFY_ME_FAIL = "GET_NOTIFY_ME_FAIL";
export const GET_NOTIFY_ME_REQUEST = "GET_NOTIFY_ME_REQUEST";

// remoe item from cart
export const REMOVE_ITEM_FROM_CART_REQUEST = "REMOVE_ITEM_FROM_CART_REQUEST";
export const REMOVE_ITEM_FROM_CART_SUCCESS = "REMOVE_ITEM_FROM_CART_SUCCESS";
export const REMOVE_ITEM_FROM_CART_FAIL = "REMOVE_ITEM_FROM_CART_FAIL";

export const GET_PACKAGE_DATA_REQUEST = "GET_PACKAGE_DATA_REQUEST";
export const GET_PACKAGE_DATA_SUCCESS = "GET_PACKAGE_DATA_SUCCESS";
export const GET_PACKAGE_DATA_FAIL = "GET_PACKAGE_DATA_FAIL";

export const RESET_CART = "RESET_CART";

// payment
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_CHECKOUT_PAYLOAD = "SET_CHECKOUT_PAYLOAD";
