import { Box, Divider, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'src/components/base/Loading';
import Center from 'src/components/Center';
import { getCartItemsAction } from 'src/store/actions/cart.actions';
import CartItem from './CartItem';

export default function UserCart({ ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, cartItems } = useSelector((state) => state.cartReducer);

  // useEffect(() => {
  //   !cartItems.length && dispatch(getCartItemsAction('cart'));
  // }, []);

  const renderEmptyCart = () => (
    <Center style={{ flexDirection: 'column' }}>
      <img
        src="/images/Icons-73.png"
        style={{ width: '30%', height: '30%', objectFit: 'cover' }}
      />
      <Typography color="primary">{t('cartEmpty')}!</Typography>
    </Center>
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Box mb={6} {...props}>
      {cartItems.length > 0
        ? cartItems.map((item) => (
            <div key={Math.random().toString()}>
              <CartItem
                product={item.product}
                userQty={item.quantity}
                cartItemId={item.id}
                storeQty={item.product.quantity}
              />

              <Divider style={{ margin: '20px 0' }} />
            </div>
          ))
        : renderEmptyCart()}
    </Box>
  );
}
