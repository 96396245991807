import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'src/components/base/Loading';
import Center from 'src/components/Center';
import OrderItem from './OrderItem';
import { getOrderHistoryAction } from 'src/store/actions/order.actions';
import OrderTracking from './OrderTracking';
import { useLocation } from 'react-router-dom';
import { convertToArabicNumbers } from 'src/utils';

const useClasses = makeStyles((theme) => ({
  title: {
    fontFamily: 'sfUiTextRegular',
    color: theme.palette.primary.main,
    fontSize:
      window.innerWidth >= 600 ? 34 : window.innerWidth >= 400 ? 25 : 20,
  },
  emptyHistory: {
    fontFamily: 'sfUiDisplayRegular',
    fontSize: 15,
    color: theme.custom.myTheme.grey3,
  },
}));

export default function OrderHistory({ title }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const classes = useClasses();
  const { loading, order: orders } = useSelector((state) => state.orderReducer);
  const searchParams = new URLSearchParams(useLocation().search);
  const orderId = searchParams.get('orderId');

  useEffect(() => {
    !orders?.length && dispatch(getOrderHistoryAction());
  }, []);
  console.log(orders?.length);
  const renderOrdeHistory = () => (
    <div style={{ backgroundColor: '#fff' }}>
      {orders?.map((item) => (
        <OrderItem key={item.id} order={item} />
      ))}
    </div>
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ height: '100%' }}>
      <Typography className={classes.title}>
        {orderId
          ? t('orderTracking') +
            ` #${convertToArabicNumbers(orderId + '', i18n.dir())}`
          : title}
      </Typography>

      {orderId ? (
        <OrderTracking />
      ) : orders?.length > 0 ? (
        <div style={{ marginTop: window.innerWidth >= 375 ? 50 : 25 }}>
          {renderOrdeHistory()}
        </div>
      ) : (
        <Center style={{ height: '90%' }}>
          <Typography className={classes.emptyHistory}>
            {t('noOrdersFound')}!
          </Typography>
        </Center>
      )}
    </div>
  );
}
