import { localStoragePrefix, PAYMENT_METHOD } from 'src/config';
import {
  ADD_TO_CART_FAIL,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  GET_CART_ITEMS_FAIL,
  GET_CART_ITEMS_REQUEST,
  GET_CART_ITEMS_SUCCESS,
  SHIPPING_METHOD_FAIL,
  SHIPPING_METHOD_REQUEST,
  SHIPPING_METHOD_SUCCESS,
  RESET_CART,
  GET_WISHLIST_REQUEST,
  GET_WISHLIST_SUCCESS,
  GET_WISHLIST_FAIL,
  PAYMENT_METHOD_FAIL,
  PAYMENT_METHOD_REQUEST,
  PAYMENT_METHOD_SUCCESS,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_SUCCESS,
  REMOVE_CART_ITEM_FAIL,
  ADD_TO_NOTIFY_SUCCESS,
  ADD_TO_NOTIFY_FAIL,
  ADD_TO_NOTIFY_REQUEST,
  GET_NOTIFY_ME_REQUEST,
  GET_NOTIFY_ME_SUCCESS,
  GET_NOTIFY_ME_FAIL,
  REMOVE_ITEM_FROM_CART_REQUEST,
  REMOVE_ITEM_FROM_CART_SUCCESS,
  REMOVE_ITEM_FROM_CART_FAIL,
  ADD_TO_WISHLIST_REQUEST,
  ADD_TO_WISHLIST_SUCCESS,
  ADD_TO_WISHLIST_FAIL,
  GET_PACKAGE_DATA_FAIL,
  GET_PACKAGE_DATA_REQUEST,
  GET_PACKAGE_DATA_SUCCESS,
  SET_PAYMENT_METHOD,
  SET_CHECKOUT_PAYLOAD,
} from '../constants/cart.types';

const initialState = {
  loadingCheckout: false,
  loading: false,
  error: null,
  cartItems: [],
  cartItemsCalled: false, // to redirect the user from shipping-address page to cart page if cartItems = 0
  cartItemsNum: 0,
  wlItemsNum: 0,
  loadingData: false,
  loadingNoti: false,
  packageData: null,

  paymentMethodId:
    localStorage.getItem(localStoragePrefix + PAYMENT_METHOD) || null,
  checkoutPayload: {
    total: 0,
    otherTotal: 0,
    subTotal: 0,
    otherSubtotal: 0,
    shipping: 0,
    otherShipping: 0,
    couponValue: 0,
    discountValue: 0,
    shippingMethodId: 1,
    couponCode: null,
    isPersentage: false,
    resetShipping: true,
    acceptTermsAndConditions: false,
    subscribeToNewsLetters: false,
    validCod: false,
  },
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    // add to cart
    case ADD_TO_CART_REQUEST:
      return {
        ...state,
        productId: action.productId,
        loadingCheckout: true,
        loading: true,
      };

    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        error: null,

        loading: false,
        loadingCheckout: false,
      };

    case ADD_TO_CART_FAIL:
      return {
        ...state,
        error: action.error,

        loading: false,
        loadingCheckout: false,
      };

    // add to notify me
    case ADD_TO_NOTIFY_REQUEST:
      return {
        ...state,
        productId: action.productId,
        loading: true,
        loadingNoti: true,
      };

    case ADD_TO_NOTIFY_SUCCESS:
      return {
        ...state,

        error: null,
        loading: false,
        loadingNoti: false,
      };

    case ADD_TO_NOTIFY_FAIL:
      return {
        ...state,

        error: action.error,
        loading: false,
        loadingNoti: false,
      };

    // get notify me items
    case GET_NOTIFY_ME_REQUEST:
      return { ...state, loadingData: true };

    case GET_NOTIFY_ME_SUCCESS:
      return {
        ...state,
        notifymeProducts: action.payload,
        error: null,
        loadingNotify: false,
      };

    case GET_NOTIFY_ME_FAIL:
      return { ...state, error: action.error, loadingNotify: false };

    // get cart items
    case GET_CART_ITEMS_REQUEST:
      return { ...state, loadingData: true };

    case GET_CART_ITEMS_SUCCESS:
      return {
        ...state,
        cartItems: action.payload,
        error: null,
        cartItemsNum: action.payload.length,
        loadingData: false,
        cartItemsCalled: true,
        // loading: false,
      };

    case GET_CART_ITEMS_FAIL:
      return { ...state, error: action.error, loading: false };

    // remove cart item
    case REMOVE_CART_ITEM_REQUEST:
      return { ...state, productId: action.productId, loading: true };

    case REMOVE_CART_ITEM_SUCCESS:
      return { ...state, error: null, loading: false };

    case REMOVE_CART_ITEM_FAIL:
      return { ...state, error: action.error, loading: false };

    // shipping method
    case SHIPPING_METHOD_REQUEST:
      return { ...state };

    case SHIPPING_METHOD_SUCCESS:
      return {
        ...state,
        shippingMethods: action.payload,
        error: null,
      };

    case SHIPPING_METHOD_FAIL:
      return { ...state, error: action.error };

    // payment method
    case PAYMENT_METHOD_REQUEST:
      return { ...state };

    case PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethods: action.payload,
        error: null,
      };

    case PAYMENT_METHOD_FAIL:
      return { ...state, error: action.error };

    // reset cart
    case RESET_CART:
      return {
        loadingCheckout: false,
        loading: false,
        error: null,
        cartItems: [],
        cartItemsNum: 0,
      };

    // wishlist
    // add to whishlist
    case ADD_TO_WISHLIST_REQUEST:
      return { ...state, productId: action.productId, loading: true };

    case ADD_TO_WISHLIST_SUCCESS:
      return { ...state, error: null };

    case ADD_TO_WISHLIST_FAIL:
      return {
        ...state,

        error: action.error,
        loading: false,
      };

    case GET_WISHLIST_REQUEST:
      return { ...state, loading: true };

    case GET_WISHLIST_SUCCESS:
      return {
        ...state,
        error: null,
        wishlistProducts: action.payload,
        wlItemsNum: action.payload.length,
        loading: false,
      };

    case GET_WISHLIST_FAIL:
      return { ...state, error: action.error, loading: false };

    // remove item
    // case REMOVE_ITEM_FROM_CART_REQUEST:
    //   return { ...state, loading: true };

    // case REMOVE_ITEM_FROM_CART_SUCCESS:
    //   return { ...state, error: null, loading: false };

    // case REMOVE_ITEM_FROM_CART_FAIL:
    //   return { ...state, error: action.error, loading: false };

    // package data to init aramix
    // shipping method
    case GET_PACKAGE_DATA_REQUEST:
      return { ...state };

    case GET_PACKAGE_DATA_SUCCESS:
      return {
        ...state,
        packageData: action.payload,
        error: null,
      };

    case GET_PACKAGE_DATA_FAIL:
      return { ...state, error: action.error };

    case SET_PAYMENT_METHOD:
      return { ...state, paymentMethodId: action.payload };

    case SET_CHECKOUT_PAYLOAD:
      return {
        ...state,
        checkoutPayload: { ...state.checkoutPayload, ...action.payload },
      };

    // case SET_GATEWAY_CODE:
    // return { ...state, paymentMethod: action.payload };

    default:
      return state;
  }
};

export default cartReducer;
