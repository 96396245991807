import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '50px',
  },
  paginationRoot: {
    direction: 'ltr',
  },
}));

export default function CoilxPagination({ page, handlePageChange, count }) {
  const classes = useStyles();

  /**
   * _limit: ?_limit=15
    _start: ?_start=100
    _page: ?_page=5 (uses _limit * _page number to get a _start value to make it easier and cleaner)
   */
  return (
    <div className={classes.root}>
      <Pagination
        count={count}
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handlePageChange}
        classes={{
          root: classes.paginationRoot,
        }}
      />
    </div>
  );
}
