import ApiService from '../../services/api.service';
import { localStoragePrefix } from '../../config';

import {
  ADD_TO_CART_FAIL,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  GET_CART_ITEMS_FAIL,
  GET_CART_ITEMS_REQUEST,
  GET_CART_ITEMS_SUCCESS,
  REMOVE_CART_ITEM_FAIL,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_SUCCESS,
  CHECKOUT_FAIL,
  CHECKOUT_REQUEST,
  RESET_CART_SUCCESS,
  SHIPPING_METHOD_FAIL,
  SHIPPING_METHOD_REQUEST,
  SHIPPING_METHOD_SUCCESS,
  PAYMENT_METHOD_FAIL,
  PAYMENT_METHOD_REQUEST,
  PAYMENT_METHOD_SUCCESS,
  GET_WISHLIST_SUCCESS,
  GET_WISHLIST_FAIL,
  GET_NOTIFY_ME_SUCCESS,
  GET_NOTIFY_ME_FAIL,
  GET_PACKAGE_DATA_FAIL,
  GET_PACKAGE_DATA_SUCCESS,
  CHECKOUT_SUCCESS,
} from '../constants/cart.types';
// import { useNavigation } from '@react-navigation/native';
import { calculateShippingAction } from './aramix.actions';
// import { SET_LOADER } from '../constants/main.type';

// Notifications.setNotificationHandler({
//   handleNotification: async () => {
//     return {
//       shouldShowAlert: true,
//     };
//   },
// });

export const addToCartAction =
  (tableName, productId, newQty = 1, celebrityId) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_TO_CART_REQUEST, productId });
      // const navigation = useNavigation();

      const {
        authReducer: { userInfo },
      } = getState();

      if (!userInfo) {
        return console.log('not auth');
        // return navigation.navigate('Auth');
      }

      // [1] check if the user already has a cart
      let url;
      tableName == 'cart'
        ? (url = `items/${tableName}?fields=id,quantity,product_id,product_id.id,product_id.quantity&filter[customer_id]=${userInfo.id}&filter[product_id.id]=${productId}`)
        : (url = `items/${tableName}?fields=id,product_id,product_id.id&filter[customer_id]=${userInfo.id}&filter[product_id.id]=${productId}`);
      let userCartExists = await ApiService.get(url);

      let productExists = userCartExists.data.data;
      if (productExists.length > 0) {
        productExists = productExists[0];
        // console.log(productExists);
        // console.log(productExists.quantity + newQty >= 1);
        if (
          (newQty < 0 && productExists.quantity > 1) || // case of decreasing qty
          (newQty > 0 &&
            newQty + productExists.quantity <=
              productExists.product_id.quantity) // case of increasing qty
        ) {
          tableName == 'cart' &&
            (await ApiService.patch(`items/${tableName}/${productExists.id}`, {
              quantity: productExists.quantity + newQty,
            }));
        }
      } else {
        let item = {
          customer_id: userInfo.id,
          product_id: productId,
          // quantity: newQty,
        };

        if (tableName == 'cart') {
          item['quantity'] = newQty;
          celebrityId && (item['celebrity_id'] = celebrityId);
        }

        await ApiService.post(`items/${tableName}`, item);
      }
      dispatch(getCartItemsAction(tableName));

      dispatch({ type: ADD_TO_CART_SUCCESS });
      // dispatch({ type: SET_LOADER });
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ADD_TO_CART_FAIL, error: message });
    }
  };

export const getShippingMethodAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: SHIPPING_METHOD_REQUEST });

    let url;
    url = `items/countery_shipping?fields=id,shipping_id.arabic_title,shipping_id.image.data.full_url,shipping_id.english_title,price_in_kd,period&filter[countery_id]=${id}`;

    const { data } = await ApiService.get(url);
    dispatch({ type: SHIPPING_METHOD_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: SHIPPING_METHOD_FAIL, error: message });
  }
};

export const getPaymentMethodAction = () => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_METHOD_REQUEST });

    let url;
    url = `items/new_payment_methods?fields=id,image.data.full_url,method,gateway_code`;

    const { data } = await ApiService.get(url);
    dispatch({ type: PAYMENT_METHOD_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PAYMENT_METHOD_FAIL, error: message });
  }
};

const getCartItemsAction = (tableName) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CART_ITEMS_REQUEST });
    const {
      authReducer: { userInfo },
    } = getState();

    let url;
    if (userInfo) {
      if (tableName == 'cart') {
        const {
          authReducer: { lang },
        } = getState();

        url = `custom/cart_ar?user=${userInfo.id}&lang=${lang}`;
        const { data } = await ApiService.get(url);

        // check cart items qty
        for (let item of data) {
          let cartQty = item.quantity;
          let stockQty = item.product.quantity;

          if (stockQty == 0) {
            // product is not in stock => remove item from car
            await ApiService.delete(`items/cart/${item.id}`);
          } else if (stockQty > 0 && stockQty < cartQty) {
            // stock quantity is less than cart qty => update user cart
            await ApiService.patch(`items/cart/${item.id}`, {
              quantity: stockQty,
            });
          }
        }
        dispatch({ type: GET_CART_ITEMS_SUCCESS, payload: data });
      } else {
        url = `items/${tableName}?fields=id,product_id.*,product_id.image.data.full_url&filter[customer_id]=${userInfo.id}`;
        const { data } = await ApiService.get(url);

        if (tableName == 'notify_me')
          dispatch({ type: GET_NOTIFY_ME_SUCCESS, payload: data.data });
        else if (tableName == 'favorite')
          dispatch({ type: GET_WISHLIST_SUCCESS, payload: data.data });
      }
    }
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    if (tableName == 'cart')
      dispatch({ type: GET_CART_ITEMS_FAIL, error: message });
    else if (tableName == 'favorite')
      dispatch({ type: GET_WISHLIST_FAIL, error: message });
    else if (tableName == 'notify_me')
      dispatch({ type: GET_NOTIFY_ME_FAIL, error: message });
    // dispatch({ type: RESET_CART_SUCCESS });
    // dispatch({ type: GET_CART_ITEMS_FAIL, error: message });
  }
};

export const checkOutAction = (payload, push) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHECKOUT_REQUEST });

    const {
      authReducer: { userInfo },
    } = getState();
    let item = {};
    let toUsd;

    const { subscribe_newsletter } = payload;
    if (subscribe_newsletter) {
      await ApiService.patch(`items/users/${userInfo.id}`, {
        subscribe_newsletter: '1',
      });
    }

    if (payload['paymentMethodId'] == 3) {
      let url = `items/currency?filter[title]=USD`;
      const usdCurrency = await ApiService.get(url);
      toUsd = usdCurrency.data.data[0].to;
    }

    item['customer_id'] = userInfo.id;
    item['price'] = payload['price'];
    item['other_price'] = payload['other_price'];
    item['currency_code'] = payload['currency']['title'];
    item['amount'] = payload['amount'];
    item['coupon_code'] = payload['coupon_code'];
    item['is_persentage'] = payload['is_persentage'];
    item['coupon_value'] = payload['coupon_value'];
    item['coupon_id'] = payload['coupon_id'];
    item['shipping_methods'] = payload['shipping_methods'];
    item['shipping_value'] = payload['shipping_value'];
    item['other_shipping_value'] = payload['other_shipping_value'];
    item['address_id'] = payload['address_id'];
    item['subtotal'] = payload['subTotal'];
    item['other_subtotal'] = payload['other_subTotal'];
    item['payment_status'] = 'inprogress';
    item['payment_method'] = payload['paymentMethodId'];
    item['package_data'] = payload['packageData'];

    const dataItems = await ApiService.post(`items/orders`, item);
    // let total_amount = 0;
    item = {};
    payload.cartItems.forEach(async (element) => {
      item['order_id'] = dataItems.data.data.id;
      item['product_id'] = element.product.id;
      item['price'] =
        element.product.discounted_price * payload['currency']['from'];
      item['other_price'] =
        (element.product.discounted_price * payload['currency']['from']) /
        payload['currency']['to'];
      item['currency_code'] = payload['currency']['title'];
      item['quantity'] = element.quantity;
      item['celebrity_id'] = element.celebrity_id
        ? element.celebrity_id.id
        : null;
      // total_amount += parseInt(element.product.discounted_price);
      await ApiService.post(`items/order_item`, item);
    });

    if (payload['gateway_code'] == 'cods') {
      let obj = {};
      obj['order_no'] = dataItems.data.data.id;
      obj['cods'] = true;
      dispatch(successPayment(obj, push));
      dispatch({ type: CHECKOUT_SUCCESS });
    } else {
      const amount =
        payload['paymentMethodId'] != 3
          ? payload['other_price']
          : parseInt(payload['other_price']) * parseInt(toUsd);

      const redirectUrl = window.location.origin + '/#/payment-status-check';
      // const baseUrl = window.location.origin + "/#/payment-status-check";

      const paymentUrl = await ApiService.get(
        `custom/getPaymentUrl?order_no=${dataItems.data.data.id}&gateway_code=${payload.gateway_code}&customer_id=${userInfo.id}&amount=${amount}&baseUrl=${redirectUrl}`
      );

      console.log(paymentUrl.data.url);

      window.location.assign(paymentUrl.data.url, '_blank');
    }

    // window.location.href = paymentUrl.data.url;
    // window.open(paymentUrl.data.url);

    dispatch({ type: CHECKOUT_SUCCESS });

    // dispatch(removeCartItemsAction());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    console.log(message);
    dispatch({ type: CHECKOUT_FAIL, error: message });
  }
};

export const successPayment = (data, push) => async (dispatch) => {
  try {
    let ottuRes;
    if (!data.cods) {
      let url = `items/ottu?filter[order_no]=${data.order_no}`;
      ottuRes = await ApiService.get(url);
    }

    const orderData = await ApiService.patch(`items/orders/${data.order_no}`, {
      payment_status: data.cods ? 'cods' : ottuRes.data.data[0].result,
      reference_number: data.cods ? '' : data.reference_number,
      // payment_status: ottuRes.data.data[0].result,
      // reference_number: data.reference_number,
    });

    if (data.cods || ottuRes?.data?.data[0]?.result == 'success') {
      const navToSuccess = `/order-success/${orderData.data.data.id}/${orderData.data.data.date}/${orderData.data.data.shipping_value}/${orderData.data.data.subtotal}/${orderData.data.data.price}`;
      push(navToSuccess);
      dispatch(removeCartItemsAction());
      await ApiService.get(`custom/success?order_no=${data.order_no}`);
    } else {
      push('/order-faild');
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: CHECKOUT_FAIL, error: message });
  }
};

export const removeCartItemAction =
  (tableName, itemId, productId) => async (dispatch) => {
    try {
      dispatch({ type: REMOVE_CART_ITEM_REQUEST, productId });

      await ApiService.delete(`items/${tableName}/${itemId}`);
      dispatch({ type: REMOVE_CART_ITEM_SUCCESS });
      dispatch(getCartItemsAction(tableName));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: REMOVE_CART_ITEM_FAIL, error: message });
    }
  };

export const resetCartAction = () => async (dispatch) => {
  try {
    await localStorage.removeItem(localStoragePrefix + 'cart');
    dispatch({ type: RESET_CART_SUCCESS });
  } catch (error) {
    dispatch({ type: ADD_TO_CART_FAIL, error });
  }
};

export const removeCartItemsAction = () => async (dispatch, getState) => {
  try {
    const {
      cartReducer: { cartItems },
    } = getState();

    dispatch({ type: REMOVE_CART_ITEM_REQUEST });
    cartItems.forEach((element) => {
      dispatch(removeCartItemAction('cart', element.id));
    });

    dispatch({ type: REMOVE_CART_ITEM_SUCCESS });
  } catch (error) {
    dispatch({ type: ADD_TO_CART_FAIL, error });
  }
};

export const getPackageDataAction = (payload) => async (dispatch, getState) => {
  try {
    const {
      authReducer: { userInfo },
    } = getState();
    // dispatch({ type: GET_PACKAGE_DATA_REQUEST });
    const url = `custom/package?user=${userInfo.id}`;
    const data = await ApiService.get(url);
    dispatch(calculateShippingAction(data.data, payload));

    dispatch({ type: GET_PACKAGE_DATA_SUCCESS, payload: data.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_PACKAGE_DATA_FAIL, error });
  }
};

export { getCartItemsAction };
