import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PrivateRoute({
  component: Component,
  notAuthOnly,
  shopping,
  passToShopping,
  ...rest
}) {
  const { userInfo } = useSelector((state) => state.authReducer);

  return (
    <Route
      {...rest}
      render={(props) => {
        // if (
        //   (userInfo && !notAuthOnly && !shopping) ||
        //   (userInfo && !notAuthOnly && shopping && cartItems?.length > 0) ||
        //   (!userInfo && notAuthOnly)
        // ) {
        //   return <Component {...props} />;
        // } else if (
        //   userInfo &&
        //   !notAuthOnly &&
        //   shopping &&
        //   cartItems?.length == 0
        // ) {
        //   return <Redirect to="/cart" />;
        // } else {
        //   return <Redirect to="/" />;
        // }

        if (
          (userInfo && !notAuthOnly && !shopping) ||
          (userInfo && !notAuthOnly && shopping) ||
          (!userInfo && notAuthOnly)
        ) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/" />;
        }

        // (userInfo && !notAuthOnly && !shopping) ||
        // (userInfo && !notAuthOnly && shopping && cartItems?.length) ||
        // (!userInfo && notAuthOnly) ? (
        //   <Component {...props} />
        // ) : (
        //   <Redirect to="/" />
        // );
      }}
    ></Route>
    // <Route
    //   {...rest}
    //   render={(props) =>
    //     (userInfo && !notAuthOnly) || (!userInfo && notAuthOnly) ? (
    //       <Component {...props} />
    //     ) : (
    //       <Redirect to="/" />
    //     )
    //   }
    // ></Route>
  );
}
