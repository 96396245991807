import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavLink as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import BaseButton from 'src/components/base/BaseButton';
import BaseContainer from 'src/components/base/BaseContainer';
import BaseInput from 'src/components/base/BaseInput';
import BasePage from 'src/components/base/BasePage';
import Loading from 'src/components/base/Loading';
import ProductItem from 'src/components/ProductItem';
import Pagination from 'src/components/Pagination';
import {
  getAuthorsAction,
  getProductByAuthorIdAction,
  getAuthorInfoAction,
} from 'src/store/actions/product.actions';
import { RESET_PAGINATION } from 'src/store/constants/product.types';

const useClasses = makeStyles((theme) => ({
  authors: {
    fontFamily: 'sfUiTextRegular',
    marginBottom: 50,
    fontSize: 25,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      marginBottom: 20,
    },
  },
  author: {
    fontSize: 17,
    fontFamily: 'roboto',
    fontWeight: 400,
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}));

function AuthorsPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useClasses();
  // const location = useLocation();
  const {
    loading,
    authorsList,
    authorProducts,
    authorInfo,
    authors_page_count,
    // authors_current_page,
  } = useSelector((state) => state.productReducer);
  const lang = i18n.language.split('-')[0];
  const { authorId } = useParams();
  // const [authorInfo, setAuthorInfo] = useState();
  // const [reset, setReset] = useState(false);
  const [searchText, setSearchText] = useState('');

  const searchParams = new URLSearchParams(useLocation().search);
  const [current_page, setCurrent_page] = useState(
    searchParams.get('page') || 1
  );

  // useEffect(() => {
  //   if (authorId && authorProducts?.length) {
  //     const authorName = authorProducts[0]?.authors?.find(
  //       (item) => item.author_id.id == authorId
  //     )?.author_id;
  //     setAuthorInfo(authorName);
  //   }
  // }, [authorProducts, authorId]);

  const renderAuthorsList = () => (
    <div>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{ marginBottom: 40 }}
      >
        {authorsList?.map((author) => (
          <Grid item key={author.id} style={{ width: 300 }}>
            <Button
              color="primary"
              component={RouterLink}
              to={`/authors/${author.id}`}
              // style={{
              //   fontSize: 17,
              //   fontFamily: 'roboto',
              //   width: '100%',
              //   textAlign: 'center',
              // }}
              className={classes.author}
            >
              {
                author[
                  lang == 'ar'
                    ? 'arabic_title'
                    : lang == 'tr'
                    ? 'turkish_title'
                    : 'english_title'
                ]
              }
            </Button>
          </Grid>
        ))}
      </Grid>

      <Pagination
        count={authors_page_count}
        handlePageChange={handlePageChange}
        page={+current_page}
      />
    </div>
  );

  const renderAuthorProducts = () => (
    <Grid container spacing={2}>
      {authorProducts?.map((item) => (
        <Grid item cols={12} md={3} key={Math.random().toString()}>
          <ProductItem item={item} />
        </Grid>
      ))}
    </Grid>
  );

  const handlePageChange = (_, page) => {
    setCurrent_page(page);
    const searchQuery = searchParams.get('search');
    let url = `/authors?page=${page}`;
    searchQuery?.trim() && (url += `&search=${searchQuery?.trim()}`);
    history.push(url);
    // history.push(`/authors?page=${page}`);
  };

  const handleSearch = () => {
    const trimedText = searchText.trim();
    if (trimedText) {
      history.push(`/authors?search=${trimedText}`);

      // dispatch({ type: SET_SEARCH_TEXT, payload: trimedText });
      setCurrent_page(1);
      dispatch(
        getAuthorsAction({
          searchText: trimedText,
          lang,
          startPage: 1,
          reset: true,
        })
      );
    } else {
      history.push(`/authors?page=1`);
      // setCurrent_page(1);
      dispatch(getAuthorsAction({ lang }));
    }
  };

  useEffect(() => {
    if (!authorId) {
      // if (!reset) {
      const searchQuery = searchParams.get('search');
      current_page &&
        dispatch(
          getAuthorsAction({
            searchText: searchQuery?.trim(),
            lang,
            startPage: current_page,
          })
        );
      // }
      // !authorsList?.length && dispatch(getAuthorsAction());
    } else {
      dispatch(getProductByAuthorIdAction(authorId));
      dispatch(getAuthorInfoAction(authorId));
    }
  }, [authorId, current_page]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_PAGINATION });
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <BasePage>
      <BaseContainer>
        <Typography color="primary" className={classes.authors}>
          {!authorId
            ? t('authors')
            : authorInfo &&
              authorInfo[lang == 'ar' ? 'arabic_title' : 'english_title']}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={8} sm={10}>
            <BaseInput
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <BaseButton
              text={t('search')}
              style={{ height: 39, width: '100%', borderRadius: 8 }}
              textStyle={{ fontSize: 16, textTransform: 'capitalize' }}
              onClick={handleSearch}
            />
          </Grid>
        </Grid>

        <div style={{ marginTop: 50 }}>
          {!authorId ? renderAuthorsList() : renderAuthorProducts()}
        </div>
      </BaseContainer>
    </BasePage>
  );
}

export default AuthorsPage;
