import { Typography } from '@material-ui/core';
import Center from 'src/components/Center';
import BaseContainer from 'src/components/base/BaseContainer';
import BasePage from 'src/components/base/BasePage';
import { ErrorOutline } from '@material-ui/icons';

function NotFound() {
  return (
    <BasePage>
      <BaseContainer>
        <Center
          style={{
            height: '65vh',
            flexDirection: window.innerWidth <= 500 && 'column',
          }}
        >
          <ErrorOutline
            style={{
              fontSize: window.innerWidth > 500 ? 150 : 110,
              margin: '0 20px',
            }}
            color="error"
          />
          <Typography
            variant="h2"
            color="error"
            align="center"
            style={{
              fontSize: window.innerWidth <= 500 && 40,
              marginTop: window.innerWidth <= 500 && 20,
            }}
          >
            Page Not Found
          </Typography>
        </Center>
      </BaseContainer>
    </BasePage>
  );
}

export default NotFound;
