import { CircularProgress } from '@material-ui/core';
import React from 'react';

export default function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        minHeight: '50vh',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <CircularProgress size={25} />
    </div>
  );
}
