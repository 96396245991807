import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Center from 'src/components/Center';

const useclasses = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    background: theme.custom.myTheme.grey1,
    cursor: 'pointer',
  },
  imageContainer: {
    width: '50%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '40%',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    transform: ({ dir }) => `scaleX(${dir == 'rtl' ? -1 : 1})`,
  },
  titleContainer: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '60%',
    },
  },
  title: {
    fontSize: 68,
    fontFamily: 'roboto',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}));

const HImageTitle = ({ item, style }) => {
  const { i18n } = useTranslation();
  const classes = useclasses({ dir: i18n.dir() });

  const renderText = (text) => {
    const splitted = text.split(' ');
    return splitted.map((t) => (
      <Typography
        key={Math.random().toString()}
        color="primary"
        className={classes.title}
      >
        {t}
      </Typography>
    ));
  };

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={item.data} />
      </div>
      <Center className={classes.titleContainer}>
        <Typography
          key={Math.random().toString()}
          color="primary"
          className={classes.title}
        >
          {item.title}
        </Typography>

        {/* {renderText(item.title)} */}
      </Center>
    </div>
  );
};

export default HImageTitle;
