import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BasePage from 'src/components/base/BasePage';
import Loading from 'src/components/base/Loading';
import Center from 'src/components/Center';
import ProductList from 'src/components/ProductList';
import { getCartItemsAction } from 'src/store/actions/cart.actions';

const useClasses = makeStyles((theme) => ({}));

export default function Wishlist({ title }) {
  const { loading, wishlistProducts } = useSelector(
    (state) => state.cartReducer
  );

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const emptyContent = ({ title }) => (
    <Center style={{ height: 330 }}>
      <Typography variant="body1" color="textSecondary">
        {t('noItemsFound')}!
      </Typography>
    </Center>
  );

  useEffect(() => {
    !wishlistProducts && dispatch(getCartItemsAction('favorite'));
  }, []);

  useEffect(() => {
    wishlistProducts && console.log(wishlistProducts);
  }, [wishlistProducts]);

  if (loading || !wishlistProducts) {
    return <Loading />;
  }

  return (
    <div style={{ height: '100%' }}>
      <Typography
        variant="h4"
        style={{
          fontFamily: 'sfUiTextRegular',
          fontSize: window.innerWidth <= 599 && 25,
        }}
        color="primary"
      >
        {title}
      </Typography>

      {wishlistProducts.length ? (
        <div style={{ marginTop: window.innerWidth > 599 ? 50 : 20 }}>
          <ProductList
            items={wishlistProducts}
            // itemPerRow={3}
            noSlider={true}
          />
        </div>
      ) : (
        <Center style={{ height: '90%' }}>{emptyContent()}</Center>
      )}
    </div>
  );
}
