import { Button as MUButton, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  btn: {
    height: 44,
    borderRadius: 10,
    // color: 'white',
    // fontSize: 15,
    outline: 0,
    transition: 'all ease-in-out .3s',
    // textTransform: 'capitalize',
    // '&:hover': {
    //   backgroundColor: theme.palette.secondary.main,
    // },
  },
  startIcon: {
    marginRight: ({ dir }) => (dir == 'ltr' ? 0 : 3),
    marginLeft: ({ dir }) => (dir == 'ltr' ? 0 : 3),
    transform: ({ dir }) => `scaleX(${dir == 'rtl' ? -1 : 1})`,
    // margin: 0,
  },
}));
export default function BaseButton({
  text,
  startIcon,
  children,
  textStyle,
  ...rest
}) {
  const { i18n } = useTranslation();
  const classes = useStyles({ dir: i18n.dir() });

  return (
    <MUButton
      variant="contained"
      color="primary"
      className={classes.btn}
      disableElevation
      startIcon={startIcon}
      {...rest}
    >
      {/* {text} */}
      {text && (
        <Typography
          style={{
            color: '#fff',
            fontFamily: 'roboto',
            fontSize: 18,
            // fontSize: 20,
            ...textStyle,
          }}
        >
          {text}
        </Typography>
      )}
      {children}
    </MUButton>
  );
}
