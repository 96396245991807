import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import BaseContainer from '../../base/BaseContainer';
import Center from '../../Center';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SET_AUTH_DIALOG } from 'src/store/constants/auth.types';
import { getAppSettingsActions } from 'src/store/actions/app.settings.actions';
import Dialog from 'src/components/Dialog';
import Loading from 'src/components/base/Loading';
import { appleStoreLink, playStoreLink } from 'src/config';

// const appleStoreLink = 'https://apps.apple.com/bh/app/diwan-world/id1581960846';
// const playStoreLink =
//   'https://play.google.com/store/apps/details?id=com.diwanworld.app';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4),
    // height: 174,
  },
  logo: {
    width: '100%',
    width: 70,
    height: 100,
    // height: 150,
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      width: window.innerWidth > 300 ? 70 : 60,
      height: window.innerWidth > 300 ? 100 : 80,
    },
  },
  vSeparator: {
    width: 1,
    height: 90,
    background: 'white',
    display: 'block',
    // margin: '0 25px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  socialItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: window.innerWidth > 300 ? 12 : 8,
      marginBottom: window.innerWidth < 400 && 0,
    },
  },
  socialLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      // padding: '2px 0',
    },
  },
  socialLinkTitle: {
    color: 'white',
    fontFamily: 'sfUiDisplayLight',
    fontSize: 12,
  },
  socialsContainer: {
    marginLeft: ({ dir }) => dir == 'ltr' && 3,
    marginRight: ({ dir }) => dir == 'rtl' && 3,
  },
  platformIcon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: window.innerWidth > 300 ? 18 : 12,
      height: window.innerWidth > 300 ? 18 : 12,
    },
  },

  logoContainer: {
    background: '#fff',
    borderRadius: 12,
    width: 68.36,
    height: 68.36,
    [theme.breakpoints.down('sm')]: {
      width: window.innerWidth > 300 ? 68.36 : 50.36,
      // height: '100%',
      height: window.innerWidth > 300 ? 68.36 : 50.36,
    },
  },
  logo2: {
    width: 35,
    height: 54,
    objectFit: 'cover',

    [theme.breakpoints.down('sm')]: {
      width: window.innerWidth > 300 ? 35 : 27,
      height: window.innerWidth > 300 ? 54 : 40,
    },
  },
  diwanWorld: {
    color: 'white',
    fontFamily: 'sfUiDisplayLight',
    fontSize: 9,
    marginTop: 2,
  },
  availableOn: {
    fontFamily: 'sfUiDisplayBold',
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 280 ? 10 : 9,
    },
  },
  diwanWorldApp: {
    marginTop: -4,
    fontFamily: 'sfUiDisplayBold',
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 280 ? 12 : 10,
      // fontSize: window.innerWidth > 400 ?15,
    },
  },
  downloadContainer: {
    background: '#fff',
    padding: '0 6px',
    marginRight: ({ dir }) => dir == 'ltr' && 5,
    marginLeft: ({ dir }) => dir == 'rtl' && 5,
    [theme.breakpoints.down('xs')]: {
      padding: '0 3px',
    },
  },
  downloadTxt: {
    fontFamily: 'sfUiDisplayHeavy',

    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  now: {
    fontFamily: 'sfUiDisplayHeavy',
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  footerAbout: {
    color: 'white',
    fontFamily: 'sfUiDisplayLight',
    fontSize: 13,
    maxWidth: 370,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 11,
    },
  },
  storeImg: {
    width: 75,
    height: 21,
    objectFit: 'cover',
    cursor: 'pointer',
  },
  termsConditions: {
    fontFamily: 'sfUiDisplayRegular',
    color: theme.custom.myTheme.grey3,
  },
}));

const platforms = [
  { icon: 'Icons-29', href: 'https://www.facebook.com/DiwanWorld' },
  { icon: 'Icons-30', href: 'https://www.instagram.com/diwanWorld' },
  { icon: 'Icons-31', href: 'https://www.twitter.com/diwanWorld' },
];

const footerLinks = [
  {
    title: 'aboutUs',
    socials: false,
    href: 'aboutUs',
  },
  {
    title: 'contactUs',
    socials: false,
    href: '/profile/5',
  },
  {
    title: 'termsConditions',
    socials: false,
    href: '/profile/2',
    // href: 'terms&conditions',
  },
  {
    title: 'followUs',
    socials: true,
    href: 'followUs',
  },
];
export default function Footer() {
  const { t, i18n } = useTranslation();
  const classes = useStyles({ dir: i18n.dir() });
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [termsDialog, setTermsDialog] = useState(false);

  const { userInfo } = useSelector((state) => state.authReducer);
  const {
    loading,
    appSettings,
    refundsPolicy,
    arReturnsPolicy,
    returnsPolicy,
    arRefundsPolicy,
  } = useSelector((state) => state.appSettingsReducer);

  const navigationHandler = (href) => {
    if (href == 'terms&conditions') {
      setTermsDialog(true);
    } else {
      if (!['followUs', 'aboutUs'].includes(href)) {
        if (userInfo) push(href);
        else dispatch({ type: SET_AUTH_DIALOG, payload: true });
      }
    }
  };

  const termsContentDialog = () => (
    <Dialog
      open={termsDialog}
      closeDialog={() => setTermsDialog(false)}
      // setOpen={setTermsDialog}
      title={t('termsConditions')}
      color="secondary"
      // icon={PersonIcon}
    >
      <Typography className={classes.termsConditions}>
        {i18n.language == 'en' ? refundsPolicy?.value : arRefundsPolicy?.value}
        {i18n.language == 'en' ? returnsPolicy?.value : arReturnsPolicy?.value}

        {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam magnam
        dicta architecto! Quaerat doloribus voluptatibus et, sapiente autem
        quisquam aliquid ipsum quas placeat! Laudantium sunt impedit soluta
        veritatis iusto iste. */}
      </Typography>
    </Dialog>
  );

  useEffect(() => {
    (!appSettings || appSettings?.length == 0) &&
      dispatch(getAppSettingsActions());
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <footer className={classes.root}>
      <BaseContainer style={{ padding: '0 5%' }}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={4} md={2}>
            <Center>
              <img
                className={classes.logo}
                src="/svg/logo.svg"
                alt="Dardiwan"
              />
            </Center>
          </Grid>
          <div className={classes.vSeparator} />
          <Grid item xs={8} md={2}>
            <Center>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {footerLinks.map(({ title, socials, href = '#' }) => (
                  <div
                    key={title}
                    className={classes.socialItemContainer}
                    style={{ margin: socials && 0 }}
                  >
                    <div
                      onClick={() => navigationHandler(href)}
                      className={classes.socialLink}
                      style={{ cursor: 'pointer' }}
                    >
                      <Typography className={classes.socialLinkTitle}>
                        {t(title)}
                      </Typography>
                    </div>

                    {/* <RouterLink to={href} className={classes.socialLink}>
                      <Typography className={classes.socialLinkTitle}>
                        {t(title)}
                      </Typography>
                    </RouterLink> */}
                    {socials && (
                      <div className={classes.socialsContainer}>
                        {platforms.map((platform) => (
                          <IconButton
                            onClick={() => window.open(platform.href, '_blank')}
                            style={{ padding: 4 }}
                            key={platform.icon}
                          >
                            <img
                              className={classes.platformIcon}
                              src={
                                process.env.PUBLIC_URL +
                                `/images/icons/${platform.icon}.png`
                              }
                              alt={platform.icon}
                            />
                          </IconButton>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Center>
          </Grid>
          <div className={classes.vSeparator} />
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            container
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={4}>
              <Center style={{ flexDirection: 'column' }}>
                <Center className={classes.logoContainer}>
                  <img
                    className={classes.logo2}
                    src="/svg/top-logo.svg"
                    // src="/logo/logo.png"
                    alt="Diwan World"
                  />
                </Center>
                <Typography className={classes.diwanWorld}>
                  {t('diwanWorld')}
                </Typography>
              </Center>
            </Grid>
            <Grid item xs={8}>
              <div>
                <div>
                  <Typography color="secondary" className={classes.availableOn}>
                    {t('availableOn')}
                  </Typography>
                  <Typography
                    color="secondary"
                    className={classes.diwanWorldApp}
                  >
                    {t('diwanWorldApp')}
                  </Typography>
                </div>
                <Center
                  style={{ justifyContent: 'flex-start', margin: '4px 0 8px' }}
                >
                  <span className={classes.downloadContainer}>
                    <Typography color="primary" className={classes.downloadTxt}>
                      {t('download')}
                    </Typography>
                  </span>
                  <Typography className={classes.now}>{t('now')}</Typography>
                </Center>
                <Box display="flex">
                  <img
                    className={classes.storeImg}
                    src="/images/playstore.png"
                    alt="Get it now on play store"
                    onClick={() => window.open(playStoreLink, '_blank')}
                  />
                  <div style={{ width: 10 }} />
                  <img
                    className={classes.storeImg}
                    src="/images/appstore.png"
                    alt="download on the app store"
                    onClick={() => window.open(appleStoreLink, '_blank')}
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
          <div className={classes.vSeparator} />
          <Grid item xs={12} sm={6} md={3}>
            <Center>
              <Typography className={classes.footerAbout}>
                {t('footerAbout')}
              </Typography>
            </Center>
          </Grid>
        </Grid>
        {termsContentDialog()}
      </BaseContainer>
    </footer>
  );
}
