import ApiService from 'src/services/api.service';
// import { getFromAsyncStorage } from '../../helpers/getFromAsyncStorage';
import {
  GET_ORDER_HISTORY_FAIL,
  GET_ORDER_HISTORY_REQUEST,
  GET_ORDER_HISTORY_SUCCESS,
  GET_ORDER_HISTORY_DETAIL_FAIL,
  GET_ORDER_HISTORY_DETAIL_REQUEST,
  GET_ORDER_HISTORY_DETAIL_SUCCESS,
} from '../constants/order.type';

export const getOrderHistoryAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ORDER_HISTORY_REQUEST });

    const {
      authReducer: { userInfo },
    } = getState();

    const { data } = await ApiService.get(
      `items/orders?filter[customer_id.id]=${userInfo.id}&filter[payment_status][in]=success,cods&sort=-id`
    );

    dispatch({
      type: GET_ORDER_HISTORY_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_ORDER_HISTORY_FAIL, error: message });
  }
};

export const getOrderHistoryDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDER_HISTORY_DETAIL_REQUEST });
    const { data } = await ApiService.get(
      `items/orders?fields=*,address_id.*,address_id.countery_id.id,address_id.countery_id.english_title,address_id.city_id.english_title,address_id.countery_id.arabic_title,address_id.city_id.arabic_title,order_items.*,order_items.product_id.*,order_items.product_id.image.data.full_url,order_items.product_id.authors.*,order_items.rating.rating,order_items.rating.title,order_items.rating.review,payment_method.method,payment_method.image.data.full_url&filter[id]=${id}`
    );
    dispatch({
      type: GET_ORDER_HISTORY_DETAIL_SUCCESS,
      payload: data.data[0],
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_ORDER_HISTORY_DETAIL_FAIL, error: message });
  }
};

export const rating = (data) => async (dispatch, getState) => {
  try {
    const {
      authReducer: { userInfo },
    } = getState();

    let item = {};
    let oItemId = data['order_item_id'];
    const reviewData = await ApiService.get(
      `items/review?filter[order_item_id]=${oItemId}`
    );

    item['customer_id'] = userInfo.id;
    item['product_id'] = data['product_id'];
    item['order_item_id'] = data['order_item_id'];
    item['order_id'] = data['order_id'];
    item['title'] = data['title'];
    item['review'] = data['review'];
    item['rating'] = data['rating'];
    if (reviewData.data.data.length > 0) {
      const ratingItems = await ApiService.patch(
        `items/review/${reviewData.data.data[0].id}`,
        item
      );
    } else {
      const ratingItems = await ApiService.post(`items/review`, item);
    }
    dispatch(getOrderHistoryDetailAction(data['order_id']));
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // dispatch({ type: Rating_FAIL, error: message });
  }
};
