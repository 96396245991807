import { createTheme } from "@material-ui/core";
import typography from "./typography";

export const theme = createTheme({
  palette: {
    // background: {
    //   dark: "#F4F6F8",
    //   default: colors.common.white,
    //   paper: colors.common.white,
    // },
    primary: {
      main: "#004A87",
    },
    secondary: {
      main: "#1EC0CA",
    },
    error: {
      main: "#FF8383",
    },
    grey: {
      main: "#EAEAEA",
    },

    // text: {
    //   primary: colors.blueGrey[900],
    //   secondary: colors.blueGrey[600],
    // },
  },

  // typography,
  typography: {
    fontFamily: [
      "sfUiDisplayMedium",
      "sfUiDisplaySemiBold",
      "sfUiDisplayBold",
      "sfUiDisplayHeavy",
      "sfUiDisplayUltraLight",
      "sfUiDisplayLight",
      "sfUiDisplayThin",
      "Roboto",
    ].join(","),
  },
  custom: {
    myTheme: {
      py: 100,
      pageHeaderHeight: 300,
      appBarHeight: 130,
      grey1: "#F0F0F0",
      grey2: "#E6E6E6",
      grey3: "#8b8b8b",
      success: "#74CE58",
    },
  },
});
