import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '85vh',
    padding: '80px 0',
    // width: window.innerWidth,
    // maxWidth: window.innerWidth,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 0',
    },
  },
}));

export default function BasePage({ children, ...rest }) {
  const classes = useStyles();

  return (
    <Box className={classes.root} {...rest}>
      {children}
    </Box>
  );
}
