import { Button, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 10,
    padding: '0 20px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  title: {
    fontFamily: 'roboto',
    fontWeight: 500,
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  viewAll: {
    fontFamily: 'roboto',
    fontWeight: 400,
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
}));

export default function Section({ style, title, filter, route }) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const navigationHandler = () => {
    if (route == 'images_gallery') {
      history.push('/categories/celebrities');
    } else if (route == 'library') {
      history.push('/categories/library');
    } else if (route == 'category_detail') {
      history.push('/categories/book_pharmacy');
    } else {
      history.push(`/filter/${filter.table}/${filter.filter_by}/${filter.eq}`);
    }
  };

  return (
    <div className={classes.root}>
      <Typography color="primary" className={classes.title}>
        {title}
      </Typography>

      <Button
        color="secondary"
        onClick={navigationHandler}
        className={classes.viewAll}
      >
        {t('viewAll')}
      </Button>
    </div>
  );
}
