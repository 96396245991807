import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BaseContainer from 'src/components/base/BaseContainer';
import BasePage from 'src/components/base/BasePage';
import Loading from 'src/components/base/Loading';
import { getOrderHistoryDetailAction } from 'src/store/actions/order.actions';
import PaymentMethod from 'src/views/shopping/OrderSummary/PaymentMethod';
import ShippingMethod from 'src/views/shopping/OrderSummary/ShippingMethod';
import Summary from 'src/views/shopping/OrderSummary/Summary';
import CartItem from 'src/views/shopping/UserCart/CartItem';

const useClasses = makeStyles((theme) => ({
  orderInfo: {
    color: theme.custom.myTheme.grey3,
    fontFamily: 'sfUiDisplayRegular',
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  orderStatus: {
    color: theme.custom.myTheme.grey3,
    fontFamily: 'sfUiDisplayRegular',
  },

  status: {
    fontFamily: 'sfUiDisplaySemiBold',
    color: ({ status }) =>
      status == 'complete'
        ? theme.palette.success.main
        : status == 'canceled'
        ? theme.palette.error.main
        : theme.palette.warning.main,
  },
  deliveredOn: {
    color: theme.palette.primary.main,
    fontFamily: 'roboto',
    fontWeight: 'bold',
    '& span': {
      color: theme.palette.success.main,
    },
  },

  divider: {
    margin: '10px 0',
  },
}));

export default function OrderDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loadingDetail, orderDetail } = useSelector(
    (state) => state.orderReducer
  );
  const classes = useClasses({ status: orderDetail?.order_status });

  const { orderId } = useParams();

  useEffect(() => {
    dispatch(getOrderHistoryDetailAction(orderId));
  }, [orderId]);

  if (loadingDetail || !orderDetail || !orderDetail.order_status) {
    return <Loading />;
  }
  return (
    <BasePage>
      <BaseContainer>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Typography variant="body1" className={classes.orderInfo}>
              {t('orderOn')}: <span>{orderDetail.date.slice(0, 10)}</span>
            </Typography>
            <Typography variant="body1" className={classes.orderInfo}>
              {t('orderNum')}: <span>{orderDetail.id}</span>
            </Typography>
            <Typography variant="body1" className={classes.orderStatus}>
              {t('status')}:{' '}
              <span className={classes.status}>
                {t(orderDetail.order_status)}
              </span>
            </Typography>

            <Divider className={classes.divider} />

            <div>
              <Typography
                variant="body1"
                color="primary"
                style={{ fontFamily: 'roboto', fontWeight: 'bold' }}
              >
                {t('deliveryAddress')}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ fontFamily: 'sfUiDisplayRegular' }}
              >
                {orderDetail.address_id.full_name}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ fontFamily: 'sfUiDisplayRegular' }}
              >
                {orderDetail.address_id.address_line_1 +
                  ' ' +
                  orderDetail.address_id.address_line_2 +
                  ' ,Phone: ' +
                  orderDetail.address_id.phone}
              </Typography>
            </div>

            <Divider className={classes.divider} />

            <Typography variant="body1" className={classes.deliveredOn}>
              {t('deliveredOn')}: <span>{orderDetail.date.slice(0, 10)}</span>
            </Typography>

            <Divider className={classes.divider} />

            <Typography
              variant="body1"
              color="primary"
              style={{
                fontFamily: 'roboto',
                fontWeight: 'bold',
                marginBottom: 15,
              }}
            >
              {t('yourOrders')}
            </Typography>
            {orderDetail.order_items.map((order) => (
              <div key={Math.random().toString()}>
                <CartItem
                  product={order.product_id}
                  orderQty={order.quantity}
                  orderDetails={true}
                  orderItemDetails={order}
                />
              </div>
            ))}
          </Grid>
          <Grid item xs={12} md={4}>
            <ShippingMethod />
            <PaymentMethod orderDetail={orderDetail} />
            <Summary orderDetail={orderDetail} />
          </Grid>
        </Grid>
      </BaseContainer>
    </BasePage>
  );
}
