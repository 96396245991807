import { Box, Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseButton from 'src/components/base/BaseButton';
import AddressForm from 'src/components/AddressForm';
import UserAddresses from './UserAddresses';
import UserCart from '../UserCart';
import BasePage from 'src/components/base/BasePage';
import BaseContainer from 'src/components/base/BaseContainer';
import CheckoutWizard from '../CheckoutWizard';
import WizardActions from '../WizardActions';
import OrderSummary from '../OrderSummary';
import { localStoragePrefix, ROUTE_PATH } from 'src/config';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';

const makeClasses = makeStyles((theme) => ({
  newAddressContainer: {
    border: `1px solid ${theme.custom.myTheme.grey2}`,
    borderRadius: 12,
    height: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${window.innerWidth > 550 ? '50px' : '20px'}`,
  },
  location: {
    width: '50%',
    height: '50%',
    // width: 107,
    // height: 107,
    objectFit: 'cover',
    // margin: '0 30px',

    [theme.breakpoints.down('xs')]: {
      width: window.innerWidth > 550 ? 85 : 75,
      height: window.innerWidth > 550 ? 85 : 75,
    },
  },
  newAddressBtn: {
    width: '100%',
    // width: 288,
    [theme.breakpoints.down('xs')]: {
      // width: window.innerWidth > 550 ? 250 : 180,
      '& span p': {
        fontSize:
          window.innerWidth > 550 ? '16px !important' : '14px !important',
      },
    },
  },
}));

export default function ShippingAddress() {
  const classes = makeClasses();
  const { t } = useTranslation();
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState();
  const { push } = useHistory();

  const { cartItems, cartItemsCalled } = useSelector(
    (state) => state.cartReducer
  );

  const addressFormHandler = () => {
    setShowAddressForm(true);
    // setSelectedAddress(null);
  };

  const editAddressHandler = (address) => {
    setAddressToEdit(address);
    setShowAddressForm(true);
  };

  const addNewAddress = () => (
    <div className={classes.newAddressContainer}>
      <Grid container spacing={4} alignItems="center">
        <Grid item container justifyContent="center" xs={4}>
          <img src="/images/icons/Icons-66.png" className={classes.location} />
        </Grid>
        <Grid item xs={8} sm={6}>
          <BaseButton
            color="primary"
            text={t(window.innerWidth > 280 ? 'addNewAddress' : 'newAddress')}
            onClick={addressFormHandler}
            className={classes.newAddressBtn}
          />
        </Grid>
      </Grid>
    </div>
  );

  useEffect(() => {
    const routePath = localStorage.getItem(localStoragePrefix + ROUTE_PATH);
    if (routePath) {
      localStorage.removeItem(localStoragePrefix + routePath);
      push(routePath);
    }
  }, []);

  useEffect(() => {
    if (cartItemsCalled && cartItems?.length == 0) push('/cart');
  }, [cartItemsCalled]);

  return (
    <>
      <BasePage>
        <BaseContainer>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <CheckoutWizard activeStep={1} prevRoute="cart" />

              {!showAddressForm && (
                <UserAddresses editAddressHandler={editAddressHandler} />
              )}
              {showAddressForm ? (
                <>
                  <Box display="flex" marginY={1}>
                    <Box flexGrow={1} />
                    <IconButton
                      size="medium"
                      color="secondary"
                      onClick={() => setShowAddressForm(false)}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                  <AddressForm record={addressToEdit} routePath="/checkout" />
                  <Divider style={{ margin: '20px 0' }} />
                </>
              ) : (
                addNewAddress()
              )}

              <UserCart style={{ marginTop: 20 }} />
              <WizardActions activeStep={1} stepsLength={3} />
            </Grid>
            <Grid item xs={12} md={4}>
              <OrderSummary activeStep={1} />
            </Grid>
          </Grid>
        </BaseContainer>
      </BasePage>
    </>
  );
}
