import React from 'react';
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  link: {
    fontSize: 22,
    fontWeight: 200,
    cursor: 'pointer',
  },
}));

const Breadcrumbs = (props) => {
  const {
    // history,
    // location: { pathname },

    staticContext,
    ...rest
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const pathnames = pathname.split('/').filter((x) => x);
  return (
    <MUIBreadcrumbs {...rest}>
      {pathnames.length > 0 ? (
        <Link className={classes.link} onClick={() => history.push('/')}>
          {t('home')}
        </Link>
      ) : (
        <Typography className={classes.link}> {t('home')} </Typography>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        // console.log('routeTo', routeTo);
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography className={classes.link} key={name}>
            {name}
          </Typography>
        ) : (
          <Link
            className={classes.link}
            key={name}
            onClick={() => history.push(routeTo)}
          >
            {name}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);
