import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import pageReducer from './reducers/page.reducer';
import productReducer from './reducers/product.reducer';
import authReducer from './reducers/auth.reducer';
import cartReducer from './reducers/cart.reducer';
import orderReducer from './reducers/order.reducer';
import userReducer from './reducers/user.reducer';
import addressReducer from './reducers/address.reducer';
import aramixReducer from './reducers/aramix.reducer';
import couponReducer from './reducers/coupon.reducer';
import appSettingsReducer from './reducers/app.settings.reducer';
import searchReducer from './reducers/search.reducer';

const rootReducer = combineReducers({
  pageReducer,
  productReducer,
  cartReducer,
  authReducer,
  orderReducer,
  userReducer,
  addressReducer,
  aramixReducer,
  couponReducer,
  appSettingsReducer,
  searchReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));
export default store;
