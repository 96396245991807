import ApiService from 'src/services/api.service';
import { localStoragePrefix } from 'src/config';

import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_FAIL,
  USER_SIGNOUT,
  USER_EXIST,
  SET_USER_INFO_SUCCESS,
  SET_USER_INFO_FAIL,
  TEST_USER_CREDENTIALS_REQUEST,
  TEST_USER_CREDENTIALS_SUCCESS,
  TEST_USER_CREDENTIALS_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  SET_CURRENCY_INFO_SUCCESS,
  SET_CURRENCY_INFO_FAIL,
  RESET_PASS_EMAIL_REQUEST,
} from '../constants/auth.types';

export const socialAuthAction = (payload) => async (dispatch) => {
  try {
    const dataval = await ApiService.get(
      `users?filter[email]=${payload.email}`
    );

    if (dataval.data.data[0]?.id) {
      // check if the user used the correct auth provider
      if (
        dataval.data.data[0].from != 'diwan' &&
        payload.from != dataval.data.data[0].from
      ) {
        console.log('invalid credentials');
      } else {
        // login user
        const loginPayload = {
          email: payload.email,
          password: process.env.REACT_APP_SOCIAL_AUTH_PASS,
          from: payload.from,
        };

        dispatch(loginAction(loginPayload));
      }
    } else {
      // create new user
      const newUser = {
        email: payload.email,
        from: payload.from,
        password: process.env.REACT_APP_SOCIAL_AUTH_PASS,
        role: 4,
      };

      payload.first_name && (newUser['first_name'] = payload.first_name);
      payload.last_name && (newUser['last_name'] = payload.last_name);
      dispatch(signupAction(newUser));
    }
  } catch (error) {
    console.log(error);
  }
};

const loginAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const { from } = payload;
    // check if the use has registered using some auth provider
    const accountInfo = await ApiService.get(
      `users?filter[email]=${payload.email}&fields=email,from`
    );
    if (accountInfo.data.data.length > 0) {
      // const noProviders = ['diwan', null, undefined];
      if (
        accountInfo.data.data[0].from != from &&
        accountInfo.data.data[0].from != 'diwan'
        // accountInfo.data.data[0].from != 'diwan'
        // !noProviders.contains(accountInfo.data.data[0].from)
      ) {
        console.log('this account is registered using some auth provider');
        dispatch({
          type: LOGIN_FAIL,
          error: `please login with your ${accountInfo.data.data[0].from} account`,
        });
      } else {
        const { data } = await ApiService.post('auth/authenticate', payload);
        const { user, token } = data.data;

        localStorage.setItem(
          localStoragePrefix + 'userInfo',
          JSON.stringify(user)
        );
        localStorage.setItem(localStoragePrefix + 'token', token);

        dispatch({ type: LOGIN_SUCCESS, payload: { user, token } });
        window.location.reload();
      }
    }
  } catch (error) {
    // console.log({ error });
    const message =
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message;
    // console.log(message);
    dispatch({ type: LOGIN_FAIL, error: message });
  }
};

const sendResetPasswordEmail = (payload) => async () => {
  try {
    dispatch({ type: RESET_PASS_EMAIL_REQUEST });
    const { data } = await ApiService.get(
      `users?filter[email]=${payload.email}`
    );

    if (data.data.length !== 0) {
      payload['url'] = process.env.REACT_APP_DOMAIN_URL + 'resetpassword';
      payload['id'] = data.data[0].id;

      // console.log(payload);
      await ApiService.post(`custom/resetPassword`, payload);
      return true;
    } else {
      console.log('user not exist');
      return false;
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    console.log(message);
    return false;
  }
};

export const setUserInfoAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SET_USER_INFO_SUCCESS, payload });
  } catch (error) {
    // console.log({ error });
    dispatch({ type: SET_USER_INFO_FAIL, payload: error });
  }
};

// export const setCurrencyInfoAction = (payload) => async (dispatch) => {
//   try {
//     dispatch({ type: SET_CURRENCY_INFO_SUCCESS, payload });
//   } catch (error) {
//     dispatch({ type: SET_CURRENCY_INFO_FAIL, payload: error });
//   }
// };

export const testUserCredentialsAction =
  (password) => async (dispatch, getState) => {
    try {
      // dispatch({ type: TEST_USER_CREDENTIALS_REQUEST });

      const {
        authReducer: { userInfo },
      } = getState();

      const payload = {
        email: userInfo.email,
        password,
      };

      // return ApiService.post('auth/authenticate', payload);
      const { data } = await ApiService.post('auth/authenticate', payload);
      // dispatch({ type: TEST_USER_CREDENTIALS_SUCCESS });
      if (data.data.token) {
        return data.data;
      }
    } catch (error) {
      // console.log(error);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({ type: TEST_USER_CREDENTIALS_FAIL });
      console.log(message);
      // return message;
      return false;
    }
  };

const signupAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SIGNUP_REQUEST });
    // await ApiService.post('users', payload, {
    //   headers: { Authorization: '' },
    // });

    // dispatch({
    //   type: SIGNUP_SUCCESS,
    // });
    // dispatch(loginAction(payload));

    const { data } = await ApiService.get(
      `users?filter[email]=${payload.email}`
    );
    if (data.data.length == 0) {
      await ApiService.post('users', payload, {
        headers: { Authorization: '' },
      });

      dispatch({
        type: SIGNUP_SUCCESS,
      });
      dispatch(loginAction(payload));
    } else {
      dispatch({
        type: SIGNUP_FAIL,
        payload: 'user with the same email already exists',
      });
    }

    // else {
    //   if (data.data[0].from == 'diwan') {
    //     dispatch({
    //       type: USER_EXIST,
    //     });
    //   } else {
    //     dispatch(loginAction(payload));
    //   }
    // }
  } catch (error) {
    console.log({ error });
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: SIGNUP_FAIL, payload: message });
  }
};

// change password
// export const changePasswordAction =
//   (password, navigation) => async (dispatch, getState) => {
//     try {
//       dispatch({ type: CHANGE_PASSWORD_REQUEST });

//       const {
//         authReducer: { userInfo },
//       } = getState();

//       await ApiService.patch(`users/${userInfo.id}`, { password });

//       // dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: data.data });
//       loginAction({ email: userInfo.email, password });

//       // navigation.navigate('Account');
//     } catch (error) {
//       console.log(error);
//       const message =
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message;
//       dispatch({ type: CHANGE_PASSWORD_FAIL, payload: message });
//     }
//   };

export const passwordResetRequestAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });

    await ApiService.post(`auth/password/reset`, payload);
    // window.location.reload();
    dispatch({ type: CHANGE_PASSWORD_SUCCESS });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: CHANGE_PASSWORD_FAIL, payload: message });
  }
};

export const passwordResetAction = (payload) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });

    const {
      authReducer: { userInfo },
    } = getState();

    await ApiService.post(`auth/password/reset`, payload);

    dispatch({ type: CHANGE_PASSWORD_SUCCESS });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: CHANGE_PASSWORD_FAIL, payload: message });
  }
};

export const signoutAction = () => async (dispatch) => {
  try {
    dispatch({ type: USER_SIGNOUT });
    // dispatch(getCartItemsAction("cart"));
    localStorage.removeItem(localStoragePrefix + 'userInfo');
    localStorage.removeItem(localStoragePrefix + 'token');

    window.location.reload();
  } catch (error) {
    console.log(error);
  }

  // navigation.navigate('Auth');
};

export { loginAction, sendResetPasswordEmail, signupAction };
