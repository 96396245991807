import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import BaseContainer from 'src/components/base/BaseContainer';
import { Box, Grid } from '@material-ui/core';

import BasePage from 'src/components/base/BasePage';
import AccountTabs from './AccountTabs';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  username: {
    fontFamily: 'roboto',
    fontWeight: 400,
    fontSize: 35,
    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
    },
  },
  email: {
    fontFamily: 'roboto',
    fontWeight: 400,
    fontSize: 20,
    color: theme.custom.myTheme.grey3,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tab: {
    borderRadius: 10,
    background: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: 95,
    transition: 'all .3s ease-in-out',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('xs')]: {
      height: 60,
    },
  },
  tabIcon: {
    width: 25,
    objectFit: 'cover',
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      width: 20,
    },
  },
  tabLabel: {
    color: 'white',
    fontFamily: 'roboto',
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  snack: {
    position: 'absolute',
    top: 0,
    width: window.innerWidth,
    height: 55,
    background: theme.palette.error.main,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '0 120px',
    transition: 'all .3s ease-in-out',
    opacity: ({ openSnack }) => (openSnack ? 1 : 0),
    '& button': {
      marginRight: 20,
      color: 'white',
    },
  },
}));

export default function AccountScreen() {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { userInfo } = useSelector((state) => state.authReducer);
  const { accountTabs } = useSelector((state) => state.userReducer);
  const [openSnack, setOpenSnack] = useState(true);
  const classes = useStyles({ openSnack });

  const { tabIndex } = useParams();

  const renderTab = (label, iconName, idx) => (
    <Grid
      item
      xs={12}
      md={6}
      key={Math.random().toString()}
      onClick={() => push(`/profile/${idx}`)}
    >
      <div className={classes.tab}>
        <img className={classes.tabIcon} src={`/svg/${iconName}.svg`} />

        <Typography className={classes.tabLabel}>{t(label)}</Typography>
      </div>
    </Grid>
  );

  return (
    <div>
      <BasePage
        style={{
          position: 'relative',
          // padding: window.innerWidth < 960 && 0,
        }}
      >
        <BaseContainer>
          {tabIndex + 1 ? (
            <AccountTabs />
          ) : (
            <div className={classes.tabsContainer}>
              <Box width="80%">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 50,
                  }}
                >
                  <img
                    src="/images/icons/Icons-14.png"
                    style={{ width: 175, height: 175, objectFit: 'cover' }}
                  />
                  <Box mt={1} textAlign="center">
                    <Typography color="primary" className={classes.username}>
                      {userInfo.first_name + ' ' + userInfo.last_name}
                    </Typography>
                    <Typography className={classes.email}>
                      {userInfo.email}
                    </Typography>
                  </Box>
                </div>
                <Grid container spacing={2}>
                  {accountTabs.map(({ label, iconName }, idx) =>
                    renderTab(label, iconName, idx)
                  )}
                </Grid>
              </Box>
            </div>
          )}
        </BaseContainer>
      </BasePage>
    </div>
  );
}
