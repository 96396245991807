import {
  GET_ORDER_HISTORY_FAIL,
  GET_ORDER_HISTORY_REQUEST,
  GET_ORDER_HISTORY_SUCCESS,
  GET_ORDER_HISTORY_DETAIL_FAIL,
  GET_ORDER_HISTORY_DETAIL_REQUEST,
  GET_ORDER_HISTORY_DETAIL_SUCCESS,
} from '../constants/order.type';

const initialState = {
  loading: false,
  loadingDetail: false,
  error: null,
  order: null,
  orderDetail: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_HISTORY_REQUEST:
      return { ...state, loading: true, order: null };

    case GET_ORDER_HISTORY_SUCCESS:
      return { ...state, order: action.payload, loading: false };

    case GET_ORDER_HISTORY_FAIL:
      return { ...state, error: action.error, loading: false };

    case GET_ORDER_HISTORY_DETAIL_REQUEST:
      return { ...state, loadingDetail: true, orderDetail: null };

    case GET_ORDER_HISTORY_DETAIL_SUCCESS:
      return { ...state, orderDetail: action.payload, loadingDetail: false };

    case GET_ORDER_HISTORY_DETAIL_FAIL:
      return { ...state, error: action.error, loadingDetail: false };

    default:
      return state;
  }
};
export default orderReducer;
