import {
  Button,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'src/components/base/Loading';
import Center from 'src/components/Center';
import Dialog from 'src/components/Dialog';
import { localStoragePrefix, PAYMENT_METHOD } from 'src/config';
import { calculateShippingAction } from 'src/store/actions/aramix.actions';
import { getPaymentMethodAction } from 'src/store/actions/cart.actions';
import { getCurrencyInfo } from 'src/store/actions/user.actions';
import {
  SET_CHECKOUT_PAYLOAD,
  SET_PAYMENT_METHOD,
} from 'src/store/constants/cart.types';
import ContentCard from './ContentCard';

const makeClasses = makeStyles((theme) => ({
  redirectMessage: {
    fontFamily: 'sfUiDisplayRegular',
    color: theme.custom.myTheme.grey3,
    marginBottom: 20,
  },
  methodName: {
    fontSize: 16,
    fontFamily: 'sfUiDisplayRegular',
    color: theme.palette.primary.main,
    margin: '0 10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 300 ? 14 : 12,
    },
  },
}));

export default function PaymentMethod() {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const { t } = useTranslation();
  const {
    loading,
    paymentMethodId,
    paymentMethods,
    cartItems,
    checkoutPayload,
  } = useSelector((state) => state.cartReducer);
  const { loadingDetail, orderDetail } = useSelector(
    (state) => state.orderReducer
  );

  const { currencyInfo } = useSelector((state) => state.userReducer);

  const { codCountries } = useSelector((state) => state.aramixReducer);

  const { shippingAddress } = useSelector((state) => state.addressReducer);
  const [gatewayCode, setGatewayCode] = useState(null);

  const [selectedPaymetMethodId, setSelectedPaymetMethodId] = useState('');
  const [redirectDialog, setRedirectDialog] = useState(false);
  const [redirectMessage, setRedirectMessage] = useState();

  const handleChange = (_, value) => {
    if (value) {
      dispatch({
        type: SET_CHECKOUT_PAYLOAD,
        payload: {
          shippingCalsLoading: true,
        },
      });

      setSelectedPaymetMethodId(value);
      dispatch({
        type: SET_PAYMENT_METHOD,
        payload: value,
      });
      localStorage.setItem(localStoragePrefix + PAYMENT_METHOD, value);

      setTimeout(() => {
        dispatch({
          type: SET_CHECKOUT_PAYLOAD,
          payload: {
            shippingCalsLoading: false,
          },
        });
      }, 1500);
    }
  };

  const checkProductsCats = (cartItems) => {
    let res = false;
    cartItems.forEach((element) => {
      let check = element.product.categories.find(
        (category) => category.category_id == 105
      );

      if (check == undefined) {
        res = true;
      }
    });
    return res;
  };

  const checkCurrencyIsoCodes = () => {
    // check if localstorage's country iso code === shipping address's country iso code
    const isoCode = localStorage.getItem(localStoragePrefix + 'countryIsoCode');

    const shippingAddressCountryIsoCode = shippingAddress?.country_code;

    if (isoCode !== shippingAddressCountryIsoCode)
      dispatch(
        getCurrencyInfo(
          gatewayCode == 'cods' ? shippingAddressCountryIsoCode : isoCode
        )
      );
  };

  const getCashOnDelivary = () => {
    console.log('start cod');
    if (codCountries.includes(shippingAddress.country_code)) {
      if (
        ['EG', 'TR'].includes(shippingAddress.country_code) &&
        checkProductsCats(cartItems)
      ) {
        setRedirectMessage(
          "your cart has some products that doesn't support COD in your country address"
        );
        setRedirectDialog(true);
        localStorage.removeItem(localStoragePrefix + PAYMENT_METHOD);
        dispatch({
          type: SET_PAYMENT_METHOD,
          payload: null,
        });
        setSelectedPaymetMethodId('');

        dispatch({
          type: SET_CHECKOUT_PAYLOAD,
          payload: {
            validCod: false,
          },
        });
      } else {
        dispatch(
          calculateShippingAction(
            shippingAddress.id,
            'cods',
            currencyInfo.title,
            checkoutPayload.total
          )
        );
        dispatch({
          type: SET_CHECKOUT_PAYLOAD,
          payload: {
            validCod: true,
          },
        });
      }
    } else {
      setRedirectMessage(
        "your country address doesn't support COD, please select another payment method"
      );

      setRedirectDialog(true);
      localStorage.removeItem(localStoragePrefix + PAYMENT_METHOD);
      dispatch({
        type: SET_PAYMENT_METHOD,
        payload: null,
      });
      setSelectedPaymetMethodId('');
      dispatch({
        type: SET_CHECKOUT_PAYLOAD,
        payload: {
          validCod: false,
        },
      });
    }
  };

  const dialogHandler = () => {
    window.location.reload();
    // setRedirectDialog(false);
  };

  const renderRedirectDialog = () => (
    <Dialog
      open={redirectDialog}
      closeDialog={() => setRedirectDialog(false)}
      color="secondary"
    >
      <div>
        <Typography className={classes.redirectMessage}>
          {redirectMessage}
        </Typography>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={dialogHandler}
        >
          {t('ok')}
        </Button>
      </div>
    </Dialog>
  );

  const renderPaymentMethod = (method) => (
    <Center style={{ justifyContent: orderDetail && 'flex-start' }}>
      <img
        src={method.image}
        style={{
          width: 50,
          height: 25,
          objectFit: 'cover',
        }}
      />
      <Typography className={classes.methodName}>{method.name}</Typography>
    </Center>
  );

  useEffect(() => {
    checkCurrencyIsoCodes();
  }, [gatewayCode]);

  useEffect(() => {
    if (paymentMethods && paymentMethodId) {
      const getGatewayCode = paymentMethods?.find(
        (item) => item.id == paymentMethodId
      )?.gateway_code;

      setGatewayCode(getGatewayCode);

      if (getGatewayCode == 'cods') {
        getCashOnDelivary();
      } else {
        if (checkoutPayload.gateway_code == 'cods') {
          dispatch(calculateShippingAction(shippingAddress.id));
        }

        dispatch({
          type: SET_CHECKOUT_PAYLOAD,
          payload: {
            validCod: true,
          },
        });
      }

      dispatch({
        type: SET_CHECKOUT_PAYLOAD,
        payload: {
          gateway_code: gatewayCode,
        },
      });
    }
  }, [paymentMethodId, checkoutPayload.total]);

  useEffect(() => {
    paymentMethodId && setSelectedPaymetMethodId(paymentMethodId);
  }, [paymentMethodId]);

  useEffect(() => {
    !orderDetail && !paymentMethods && dispatch(getPaymentMethodAction());
  }, []);

  if (((loading || !paymentMethods) && !orderDetail) || loadingDetail) {
    return <Loading />;
  }

  return (
    <ContentCard title="paymentMethod">
      {
        !orderDetail ? (
          <FormControl component="fieldset">
            <RadioGroup value={+selectedPaymetMethodId} onChange={handleChange}>
              {paymentMethods?.map((method) => (
                <FormControlLabel
                  value={method.id}
                  key={method.id}
                  control={<Radio />}
                  label={renderPaymentMethod({
                    name: method.method,
                    image: method.image.data.full_url,
                  })}
                />
              ))}
            </RadioGroup>
            {renderRedirectDialog()}
          </FormControl>
        ) : (
          renderPaymentMethod({
            name: orderDetail?.payment_method?.method,
            image: orderDetail?.payment_method?.image?.data?.full_url,
          })
        )
        // <img
        //   src={orderDetail.payment_method.image.data.full_url}
        //   style={{ width: 40, height: 35, objectFit: 'cover' }}
        // />
      }
    </ContentCard>
  );
}
