import React from 'react';
import BasePage from 'src/components/base/BasePage';
import UserInfo from './UserInfo';
import ChangePassword from './ChangePassword';
import { Typography } from '@material-ui/core';

export default function UserProfile({ title }) {
  return (
    <div style={{ height: '100%' }}>
      <Typography
        // variant="h4"
        style={{
          fontFamily: 'sfUiTextRegular',
          fontSize:
            window.innerWidth >= 600 ? 34 : window.innerWidth >= 400 ? 25 : 20,
        }}
        color="primary"
      >
        {title}
      </Typography>
      <div style={{ marginTop: window.innerWidth > 599 ? 50 : 20 }}>
        <UserInfo />
        <ChangePassword />
      </div>
    </div>
  );
}
