import React, { memo, useMemo } from 'react';
import ProductItem from 'src/components/ProductItem';
import Section from 'src/components/Section';
import BaseCarousel from 'src/components/base/BaseCarousel';
import { Grid } from '@material-ui/core';
import { SwiperSlide } from 'swiper/react/swiper-react';

function ProductList({ title, items, filter, noSlider, itemPerRow }) {
  const renderItem = useMemo(
    () => (item) =>
      (
        <div key={Math.random().toString()}>
          <ProductItem item={item} />
        </div>
      ),
    [items]
  );

  const renderGridItems = (items) => (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid
          key={Math.random().toString()}
          item
          xs={6}
          md={12 / itemPerRow || 4}
        >
          <ProductItem item={item.product_id || item} />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      {title && items.length && <Section title={title} filter={filter} />}
      {noSlider ? (
        renderGridItems(items)
      ) : (
        <BaseCarousel>
          {items?.map((item) => (
            <SwiperSlide key={Math.random().toString()}>
              <div>{renderItem(item)}</div>
            </SwiperSlide>
          ))}
        </BaseCarousel>
      )}
    </>
  );
}

export default memo(ProductList);
