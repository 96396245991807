import ApiService from 'src/services/api.service';
import {
  GET_CITIES_FAIL,
  GET_CITIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  CALCULATE_SHIPPING_FAIL,
  CALCULATE_SHIPPING_REQUEST,
  CALCULATE_SHIPPING_SUCCESS,
} from '../constants/address.types';

import { SET_CHECKOUT_PAYLOAD } from '../constants/cart.types';
import {
  GET_ORDER_TRACKING_FAIL,
  GET_ORDER_TRACKING_REQUEST,
  GET_ORDER_TRACKING_SUCCESS,
} from '../constants/aramix.types';

export const getCountriesAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_COUNTRIES_REQUEST });

    const { data } = await ApiService.get('custom/getAramexCountries');

    const sortedCountries = data.Countries.sort((a, b) => {
      // a.Name.toUpperCase() > b.Name.toUpperCase() ? 1 : -1
      if (a.Name.toUpperCase() > b.Name.toUpperCase()) {
        return 1;
      } else if (a.Name.toUpperCase() < b.Name.toUpperCase()) {
        return -1;
      }
      // names must be equal
      return 0;
    });

    dispatch({
      type: GET_COUNTRIES_SUCCESS,
      payload: sortedCountries,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_COUNTRIES_FAIL, error: message });
  }
};

export const getCitiesAction = (countryCode) => async (dispatch) => {
  try {
    // dispatch({ type: GET_CITIES_REQUEST });

    const { data } = await ApiService.get(
      `custom/getAramexCities?CountryCode=${countryCode}`
    );

    dispatch({ type: GET_CITIES_SUCCESS, payload: data.Cities });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_CITIES_FAIL, error: message });
  }
};

// paymentMethod,currencyCode,price
export const calculateShippingAction =
  (addressId, paymentMethod, currencyCode, price) => async (dispatch) => {
    try {
      // console.log("addressId", addressId);
      dispatch({ type: CALCULATE_SHIPPING_REQUEST });

      const data = await ApiService.get(
        `custom/calculateShipping?addressId=${addressId}&paymentMethod=${paymentMethod}&currencyCode=${currencyCode}&price=${price}`
      );

      dispatch({
        type: CALCULATE_SHIPPING_SUCCESS,
        payload: data.data,
      });

      dispatch({
        type: SET_CHECKOUT_PAYLOAD,
        payload: {
          resetShipping: true,
        },
      });
    } catch (error) {
      console.log(error);

      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: CALCULATE_SHIPPING_FAIL, error: message });
    }
  };

export const getOrderTrackingAction = (trackingNumber) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDER_TRACKING_REQUEST });

    const orderTracking = await ApiService.get(
      `custom/getLastTrackingUpdate?orderId=${trackingNumber}&last=${false}`
    );

    dispatch({
      type: GET_ORDER_TRACKING_SUCCESS,
      payload: orderTracking.data.TrackingResults[0].Value,
    });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_ORDER_TRACKING_FAIL, error: message });
  }
};
