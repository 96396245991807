import {
  SEARCH_PRODUCTS_REQUEST,
  SEARCH_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS_FAIL,
  INC_SEARCH_PAGE,
  RESET_SEARCH_PAGE,
  TRIGGER_REFRESHING,
  SET_SEARCH_PAGE_COUNT,
  SET_SEARCH_PAGE,
  RESET_SEARCH_PAGE_COUNT,
} from "../constants/search.types";

const initialState = {
  loading: false,
  error: null,
  searchResult: [],
  refreshing: false,
  limit: 50,
  page: 0,
  page_count: 1,
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    // search products
    case SEARCH_PRODUCTS_REQUEST:
      return { ...state, loading: true, error: null };

    case SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        // searchResult:
        //   state.searchResult.length == 0 || action.reset
        //     ? action.payload
        //     : [...state.searchResult, ...action.payload],
        searchResult: action.payload,
        refreshing: false,
        loading: false,
      };

    case SEARCH_PRODUCTS_FAIL:
      return { ...state, error: action.error, loading: false };

    // case INC_SEARCH_PAGE:
    //   return { ...state, page: state.page + 1 };

    case RESET_SEARCH_PAGE:
      return { ...state, page: 0 };

    case SET_SEARCH_PAGE:
      return { ...state, page: action.payload };

    case SET_SEARCH_PAGE_COUNT:
      return { ...state, page_count: action.payload };

    case RESET_SEARCH_PAGE_COUNT:
      return { ...state, page_count: 1 };

    case TRIGGER_REFRESHING:
      return { ...state, refreshing: true };

    default:
      return state;
  }
};
export default searchReducer;
