import React from 'react';

export default function Center({ children, style, ...rest }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
