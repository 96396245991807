import { useFormikContext } from 'formik';
import React from 'react';
import BaseButton from '../base/BaseButton';

export default function FormikButton({ disabled, ...props }) {
  const { values, submitForm, isValid } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  const configButton = {
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    disabled: !isValid || disabled,

    onClick: handleSubmit,
  };

  return <BaseButton {...props} {...configButton} />;
}
