import BaseContainer from 'src/components/base/BaseContainer';
import Celebrities from 'src/components/Celebrities';
import ProductList from 'src/components/ProductList';
import Section from 'src/components/Section';
import ProductItem from 'src/components/ProductItem';
import RenderCard from './RenderCard';
import BaseCarousel from 'src/components/base/BaseCarousel';
import { SwiperSlide } from 'swiper/react/swiper-react';

export default function RenderPage({
  section,
  catDetails,
  from,
  noSlider,
  customRoute,
}) {
  const renderContent = () => {
    switch (section.type) {
      case 'images gallery':
        return (
          <Celebrities
            title={section.title}
            filter={{
              table: section.table,
              filter_by: section.filter_by,
              eq: section.eq,
            }}
            key={Math.random().toString()}
            data={section.data}
            // catDetails={catDetails}
            from={from}
            type={'images_gallery'}
          />
        );

      case 'hlist detail':
        section.data.sort(function (a, b) {
          return a.sequence - b.sequence;
        });

        return (
          <>
            <Section
              title={section.title}
              filter={{
                table: section.table,
                filter_by: section.filter_by,
                eq: section.eq,
              }}
            />
            <BaseCarousel>
              {section?.data?.map((item) => (
                <SwiperSlide key={Math.random().toString()}>
                  <ProductItem item={item} />
                </SwiperSlide>
              ))}
            </BaseCarousel>
          </>
        );
      case 'hlist detail sub':
        return section.data.map((list) => (
          <ProductList
            title={list.title}
            key={Math.random().toString()}
            items={list.data}
            filter={{
              table: list.table,
              filter_by: list.filter_by,
              eq: list.eq,
            }}
            itemPerRow={6}
            noSlider={true}
          />
        ));

      case 'grid2*2':
        return (
          <RenderCard
            key={Math.random().toString()}
            title={section.title}
            filter={{
              table: section.table,
              filter_by: section.filter_by,
              eq: section.eq,
            }}
            items={section.data}
            from={from}
            noSlider={noSlider}
            type={section.type}
            // customRoute={customRoute}
          />
        );

      case 'hlist image':
        return (
          <RenderCard
            key={Math.random().toString()}
            title={section.title}
            filter={{
              table: section.table,
              filter_by: section.filter_by,
              eq: section.eq,
            }}
            items={section.data}
            // catDetails={catDetails}
            // customRoute={customRoute}
          />
        );

      case 'list image':
        return (
          <RenderCard
            key={Math.random().toString()}
            title={section.title}
            items={section.data}
            showSection={false}
            itemPerRow={5}
            type="listImage"
            // catDetails={catDetails}
            noSlider={true}
            // customRoute={customRoute}
          />
        );

      case 'image':
        const data = [
          {
            data: section.data,
            title: section.title,
            description: section.description,
            filter: {
              table: section.table,
              filter_by: section.filter_by,
              eq: section.eq,
            },
            custom_link: section.custom_link,
            id: section.id,
          },
        ];

        return (
          <RenderCard
            key={Math.random().toString()}
            // numColumns={deviceType}
            items={data}
            showSection={false}
            type={section.type}
            // catDetails={catDetails}
            noSlider={noSlider}
            // customRoute={customRoute}
          />
        );

      case 'grid image':
        // render in pharmacy sub cat screen
        return (
          <RenderCard
            key={Math.random().toString()}
            title={section.title}
            // numColumns={2}
            items={section.data}
            showSection={false}
            // catDetails={catDetails}
            noSlider={noSlider}
            // customRoute={customRoute}
          />
        );

      case 'pharmacy':
        return (
          <RenderCard
            key={Math.random().toString()}
            title={section.title}
            // numColumns={2}
            items={section.data}
            showSection={false}
            // catDetails={catDetails}
            type={section.type}
            noSlider={noSlider}
            itemPerRow={2}
            // customRoute={customRoute}
          />
        );

      case 'library':
        return (
          <RenderCard
            key={Math.random().toString()}
            title={section.title}
            // numColumns={2}
            items={section.data}
            showSection={false}
            type={section.type}
            from={from}
            noSlider={true}
            itemPerRow={2}
            customRoute={customRoute}
          />
        );

      default:
        return (
          <div style={{ color: 'red', textAlign: 'center' }}>
            this type is not supported
          </div>
        );
    }
  };

  return <BaseContainer>{renderContent()}</BaseContainer>;
}
