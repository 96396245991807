import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import LanguageMenu from './LanguageMenue';
import Center from 'src/components/Center';
import AccountBtns from '../AccountBtns';
import SocialMedial from './SocialMedial';
import BaseContainer from 'src/components/base/BaseContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 40,
    width: '100%',
    zIndex: 101,
    background: '#f8f8f8',
    transition: 'all .2s ease-in-out',
  },
  separator: {
    width: '100%',
    background: theme.palette.primary.main,
    height: 1,
  },
  navItemSeparator: {
    height: 15,
    width: 1,
    background: theme.palette.primary.main,
    margin: '0 6px',
  },
  startIcon: {
    marginRight: ({ dir }) => (dir !== 'ltr' ? 0 : 3),
    marginLeft: ({ dir }) => (dir !== 'rtl' ? 0 : 3),
    transform: ({ dir }) => `scaleX(${dir == 'rtl' ? -1 : 1})`,
  },
  btnName: {
    fontSize: 13,
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    },
  },
}));

export default function TopBar() {
  const { i18n } = useTranslation();
  const classes = useStyles({ dir: i18n.dir() });

  return (
    <div className={classes.root} id="top-bar">
      <BaseContainer>
        <Center>
          <SocialMedial />

          <Box flexGrow={1} />

          <AccountBtns />

          <LanguageMenu />
        </Center>
      </BaseContainer>
    </div>
  );
}
