import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BaseInput from '../base/BaseInput';
import { Box, IconButton, InputAdornment } from '@material-ui/core';
import { ArrowDropDown, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

const PAGINATION_VALUE = 100;
function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, options, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(PAGINATION_VALUE);

  const handleStart = () => {
    setStart(start - PAGINATION_VALUE);
    setEnd(end - PAGINATION_VALUE);
  };

  const handleEnd = () => {
    setStart(start + PAGINATION_VALUE);
    setEnd(end + PAGINATION_VALUE);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [searchText, setSearchText] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);

  const searchHandler = () => {
    if (searchText?.trim()) {
      setSearchOptions(
        options.filter(
          (option) =>
            option
              .toLocaleLowerCase()
              .indexOf(searchText.trim().toLocaleLowerCase()) > -1
        )
      );
      setStart(0);
      setEnd(PAGINATION_VALUE);
    } else {
      return setSearchOptions(options);
    }
  };

  const GetPrevArrow = i18n.dir() == 'ltr' ? ChevronLeft : ChevronRight;

  const GetNextArrow = i18n.dir() == 'ltr' ? ChevronRight : ChevronLeft;

  useEffect(() => {
    searchHandler();
  }, [searchText]);

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        <BaseInput
          onChange={(text) => setSearchText(text.target.value)}
          // autocomplete={false}
          inputProps={{
            form: {
              autocomplete: 'off',
            },
          }}
        />
      </DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {searchOptions?.slice(start, end).map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
        <Box display="flex" justifyContent="center" width="100%">
          <IconButton
            disabled={start <= 0}
            onClick={handleStart}
            color="primary"
          >
            <GetPrevArrow />
            {/* <ChevronLeft /> */}
          </IconButton>
          <Box width={2} />
          <IconButton
            color="primary"
            disabled={end >= searchOptions.length}
            onClick={handleEnd}
          >
            <GetNextArrow />
            {/* <ChevronRight /> */}
          </IconButton>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleOk} color="primary">
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
}));

export default function FormikSearchSelect({
  name,
  options,
  // initialConfigSelect,
  ...otherProps
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // const [value, setValue] = useState('Dione');

  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (value) => {
    if (value) {
      // const { value } = value.target;
      setFieldValue(name, value);
    }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    console.log(newValue);
    handleChange(newValue);
    setOpen(false);
  };

  const configSelect = {
    ...field,
    ...otherProps,

    onClick: handleClickListItem,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <div className={classes.root}>
      <BaseInput
        {...configSelect}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDown color="action" />
            </InputAdornment>
          ),
        }}
      />

      <ConfirmationDialogRaw
        classes={{
          paper: classes.paper,
        }}
        id="ringtone-menu"
        keepMounted
        open={open}
        onClose={handleClose}
        value={values[name]}
        // value={value}
        options={options}
      />
    </div>
  );
}
