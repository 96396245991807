import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const useClasses = makeStyles((theme) => ({
  cartItem: {
    width: '100%',
    border: `1px solid ${theme.custom.myTheme.grey2}`,
    borderRadius: 12,
  },
  cartTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: theme.palette.primary.main,
    padding: '15px 25px',
  },
  cartBody: {
    padding: '10px 20px',
  },
  orderHeaderTxt: {
    color: '#fff',
    fontFamily: 'roboto',
    fontWeight: 400,
    fontSize: window.innerWidth >= 350 ? 18 : 13,
  },

  infoContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  orderInfoKey: {
    padding: '5px 0',
    color: theme.custom.myTheme.grey3,
    fontSize: window.innerWidth >= 350 ? 16 : 10,
  },
  orderInfoValue: {
    color: theme.custom.myTheme.grey3,
    fontSize: window.innerWidth >= 350 ? 16 : 10,
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

export default function OrderItem({ order }) {
  const { t, i18n } = useTranslation();
  const classes = useClasses();
  const { push } = useHistory();
  const theme = useTheme();

  const renderItem = (title, value, color) => (
    <>
      <Grid item xs={5}>
        <Typography className={classes.orderInfoKey}>{title}</Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent={window.innerWidth < 350 ? 'center' : 'flex-start'}
        xs={5}
      >
        <Typography
          className={classes.orderInfoValue}
          style={{
            color: color && color,
          }}
        >
          {value}
        </Typography>
      </Grid>
    </>
  );

  return (
    <div className={classes.cartItem}>
      <div className={classes.cartTitle}>
        <Typography className={classes.orderHeaderTxt}>
          {t('orderNum')} :{order.id}
        </Typography>
        <Typography className={classes.orderHeaderTxt}>
          {order.date.slice(0, 10)}
        </Typography>
      </div>
      <div className={classes.cartBody}>
        <Grid container>
          <Grid
            item
            container
            xs={11}
            alignItems="center"
            // style={{ background: '#ff0' }}
          >
            {renderItem(t('quantity'), order.amount)}
            {renderItem(
              t('totalAmount'),
              order.currency_code + ' ' + order.price
            )}
            {renderItem(
              t('status'),
              t(order.order_status),
              order.order_status == 'complete'
                ? theme.palette.success.main
                : order.order_status == 'canceled'
                ? theme.palette.error.main
                : theme.palette.warning.main
            )}
          </Grid>

          <Grid item xs={1}>
            <div className={classes.iconContainer}>
              {i18n.dir() == 'rtl' ? (
                <ChevronLeft
                  onClick={() => push(`/order-details/${order.id}`)}
                  fontSize="large"
                  color="primary"
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <ChevronRight
                  onClick={() => push(`/order-details/${order.id}`)}
                  fontSize="large"
                  color="primary"
                  style={{ cursor: 'pointer' }}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
