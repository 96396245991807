import React from 'react';
import { IconButton } from '@material-ui/core';

const platforms = [
  { icon: 'Icons-29.png', href: 'https://www.facebook.com/DiwanWorld' },
  { icon: 'Icons-30.png', href: 'https://www.instagram.com/diwanworld' },
  { icon: 'Icons-31.png', href: 'https://www.twitter.com/diwanworld' },
];

function SocialMedial() {
  return (
    <div>
      {platforms.map((platform) => (
        <IconButton
          onClick={() => window.open(platform.href, '_blank')}
          style={{ padding: window.innerWidth > 300 ? 4 : 2 }}
          key={platform.icon}
        >
          <img
            style={{
              width: window.innerWidth > 300 ? 25 : 18,
              height: window.innerWidth > 300 ? 25 : 18,
              objectFit: 'cover',
            }}
            src={`/images/icons/${platform.icon}`}
            alt={platform.icon}
          />
        </IconButton>
      ))}
    </div>
  );
}

export default SocialMedial;
