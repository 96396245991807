import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ProductList from 'src/components/ProductList';

export default function RelatedProducts() {
  const { t } = useTranslation();
  const { relatedProducts } = useSelector((state) => state.productReducer);

  if (!relatedProducts) {
    return null;
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Typography variant="h6" color="primary" style={{ marginBottom: 10 }}>
        {t('relatedProducts')}
      </Typography>

      <ProductList key={Math.random().toString()} items={relatedProducts} />
    </div>
  );
}
