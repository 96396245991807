import React from 'react';
import { Swiper } from 'swiper/react/swiper-react';
import { Pagination, Navigation, Autoplay } from 'swiper';

import 'swiper/swiper.min.css';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useClasses = makeStyles((theme) => ({
  root: {
    '& .swiper-button-next': {
      right: ({ dir }) => dir == 'ltr' && -1,
      left: ({ dir }) => dir == 'rtl' && -1,
      backgroundPosition: '70% 36%',
      background: '#fff',
      height: '100%',
      top: '0% !important',
      marginTop: 0,
      backgroundSize: '50%',
      width: 32,
      // border: '1px solid red',
      [theme.breakpoints.down('xs')]: {
        width: 15,
      },
    },
    '& .swiper-button-prev': {
      top: '0% !important',
      backgroundPosition: '70% 36%',
      background: '#fff',
      height: '100%',
      top: '0% !important',
      marginTop: 0,
      backgroundSize: '50%',
      left: ({ dir }) => dir == 'ltr' && -1,
      right: ({ dir }) => dir == 'rtl' && -1,
      width: 32,
      // border: '1px solid red',
      [theme.breakpoints.down('xs')]: {
        width: 15,
        left: ({ dir }) => dir == 'ltr' && -1,
        right: ({ dir }) => dir == 'rtl' && -1,
      },
    },
    '& .swiper-button-disabled': {
      display: 'none',
    },
    '& .swiper-button-prev:after': {
      fontSize: '30px !important',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        fontSize: '25px !important',
      },
    },
    '& .swiper-button-next:after': {
      fontSize: '30px !important',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        fontSize: '25px !important',
      },
    },

    '& .swiper-pagination': {
      bottom: '0px !important',
      zIndex: 2000,
    },
    '& span.swiper-pagination-bullet': {
      width: '8px !important',
      height: '8px !important',
      borderRadius: 0,
      background: '#219ecb',
      transform: 'rotate(45deg)',
      margin: '0 4px !important',
    },
  },
}));

function SwiperVersion({
  showNavigation = true,
  showPagination = false,
  slidesPerView,
  style,
  children,
  autoplay,
  ...props
}) {
  const { i18n } = useTranslation();
  // breakpoints 480 | 768 | 1024 | 1200

  let modules = [];
  autoplay && modules.push(Autoplay);

  const classes = useClasses({ dir: i18n.dir() });
  let swiperConfig = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    // rtl: true,
    modules,
  };

  if (showNavigation) {
    modules.push(Navigation);
    swiperConfig['navigation'] = {};
  }

  if (showPagination) {
    modules.push(Pagination);
    swiperConfig['pagination'] = { clickable: true };
  }

  !slidesPerView &&
    (swiperConfig['breakpoints'] = {
      0: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10,
      },
      601: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
      751: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 10,
      },
      940: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 20,
      },
      1281: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 20,
      },
    });

  swiperConfig = { ...swiperConfig, ...props };

  return (
    <div className={classes.root}>
      <Swiper
        style={{
          padding: window.innerWidth > 599 ? '0 40px' : '0 25px',
          // transition: 'transform 1000ms ease 0s',
          ...style,
        }}
        {...swiperConfig}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {children}
      </Swiper>
    </div>
  );
}

export default SwiperVersion;
