import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from 'src/components/Dialog';
import { getAppSettingsActions } from 'src/store/actions/app.settings.actions';
import { SET_CHECKOUT_PAYLOAD } from 'src/store/constants/cart.types';

const makeClasses = makeStyles((theme) => ({
  checkboxLabel: {
    color: theme.custom.myTheme.grey3,
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      '& button': {
        fontSize: 11,
      },
    },
  },
  termsConditions: {
    fontFamily: 'sfUiDisplayRegular',
    color: theme.custom.myTheme.grey3,
  },
}));

export default function UserAgreements(props) {
  const { checkoutPayload } = useSelector((state) => state.cartReducer);
  const {
    userInfo: { subscribe_newsletter },
  } = useSelector((state) => state.authReducer);

  const { terms, subscribe, subscribeToNewsLetters, acceptTermsAndConditions } =
    checkoutPayload;
  // const { terms, subscribe, setTerms, setSubscribe } = props;
  const classes = makeClasses();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [termsDialog, setTermsDialog] = useState(false);
  const {
    // loading,
    // error,
    appSettings,
    refundsPolicy,
    arReturnsPolicy,
    returnsPolicy,
    arRefundsPolicy,
  } = useSelector((state) => state.appSettingsReducer);

  const termsContentDialog = () => (
    <Dialog
      open={termsDialog}
      closeDialog={() => setTermsDialog(false)}
      // setOpen={setTermsDialog}
      title={t('termsConditions')}
      color="secondary"
      // icon={PersonIcon}
    >
      <Typography className={classes.termsConditions}>
        {i18n.language == 'en' ? refundsPolicy?.value : arRefundsPolicy?.value}
        {i18n.language == 'en' ? returnsPolicy?.value : arReturnsPolicy?.value}
        {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam magnam
        dicta architecto! Quaerat doloribus voluptatibus et, sapiente autem
        quisquam aliquid ipsum quas placeat! Laudantium sunt impedit soluta
        veritatis iusto iste. */}
      </Typography>
    </Dialog>
  );

  const agreementsHandler = (type, value) => {
    let payload = {};
    payload[type] = value;
    dispatch({
      type: SET_CHECKOUT_PAYLOAD,
      payload,
    });
  };

  // useEffect(() => {
  //   appSettings?.length == 0 && dispatch(getAppSettingsActions());
  // }, []);

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptTermsAndConditions}
                onChange={(e) =>
                  agreementsHandler(
                    'acceptTermsAndConditions',
                    e.target.checked
                  )
                }
                // onChange={(e) => setTerms(e.target.checked)}
                name="terms"
              />
            }
            label={
              <Box display="flex" alignItems="center">
                <Typography
                  className={classes.checkboxLabel}
                  style={{ fontFamily: 'sfUiDisplayRegular' }}
                >
                  {t('haveReadTerms')}
                  <Button
                    onClick={() => setTermsDialog(true)}
                    color="secondary"
                    style={{ fontFamily: 'sfUiDisplaySemiBold' }}
                  >
                    {t('termsConditions')}
                  </Button>
                </Typography>
                {/* {termsContentDialog()} */}
              </Box>
            }
          />
          {!subscribe_newsletter && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={subscribeToNewsLetters}
                  onChange={(e) =>
                    agreementsHandler(
                      'subscribeToNewsLetters',
                      e.target.checked
                    )
                  }
                  name="subscribe"
                />
              }
              label={
                <Typography className={classes.checkboxLabel}>
                  {t('subscribeToNews')}
                </Typography>
              }
            />
          )}
        </Box>
      </Box>
      {termsContentDialog()}
    </>
  );
}
