import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import BaseContainer from 'src/components/base/BaseContainer';
import Center from 'src/components/Center';
import { Close } from '@material-ui/icons';
import { appleStoreLink, playStoreLink, WEBSITE_URL } from 'src/config';
import { SET_USER_UI_SETTINGS } from 'src/store/constants/app.settings.types';
import { useDispatch, useSelector } from 'react-redux';

function DownloadAppBar({ mobileOS, setMobileOS }) {
  const theme = useTheme();
  // const dispatch = useDispatch();
  // const {
  //   userUISettings: { mobileOS },
  // } = useSelector((state) => state.appSettingsReducer);

  return (
    <Center
      style={{
        backgroundColor: theme.palette.primary.main,
        height: 100,
        direction: 'ltr',
      }}
    >
      <BaseContainer
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Center>
          <img
            style={{ width: 50 }}
            src="/images/Download Pop up Icon-01.svg"
            alt="Diwan World"
            onClick={() =>
              window.open(
                mobileOS == 'android'
                  ? playStoreLink
                  : mobileOS == 'ios'
                  ? appleStoreLink
                  : WEBSITE_URL,
                '_blank'
              )
            }
          />
        </Center>

        <Center>
          <div>
            <Typography
              variant="body2"
              style={{
                color: '#fff',
                textAlign: 'right',
                fontFamily: 'roboto',
                fontWeight: 700,
              }}
            >
              حمل تطبيق عالم ديوان
            </Typography>
            <Typography
              variant="body2"
              color="secondary"
              style={{
                fontFamily: 'roboto',
                fontWeight: 700,
              }}
            >
              Download Diwan World App
            </Typography>
          </div>

          <img
            style={{ width: 50, margin: '0 10px' }}
            src="/images/Diwan World App - Logo.svg"
            alt="Diwan World"
          />
          <Close
            // onClick={() =>
            //   dispatch({
            //     type: SET_USER_UI_SETTINGS,
            //     payload: { mobileOS: 'unknown' },
            //   })
            // }
            onClick={() => setMobileOS('unknown')}
            style={{ color: '#fff', fontSize: 14 }}
          />
        </Center>
      </BaseContainer>
    </Center>
  );
}

export default DownloadAppBar;
