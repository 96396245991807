import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Snackbar,
  Hidden,
  useTheme,
  Button,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { NavLink } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import FormikButton from 'src/components/FormUI/FormikButton';
import FormikInput from 'src/components/FormUI/FormikInput';

import { contactTechSupportAction } from 'src/store/actions/user.actions';
import Loading from 'src/components/base/Loading';
import { SET_USER_UI_SETTINGS } from 'src/store/constants/app.settings.types';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const contacts = [
//   // {
//   //   phone: '+965 88888888',
//   //   icon: '/images/icons/Icons-55.png',
//   // },
//   {
//     phone: '+965 91111474',
//     icon: '/images/icons/Icons-56.png',
//   },
// ];

const whatsappCcontact = {
  phone: '+965 91111474',
  icon: '/images/icons/Icons-56.png',
};

const initialValues = {
  first_name: '',
  last_name: '',
  title: '',
  message: '',
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),

  last_name: Yup.string().required('Required'),

  title: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
});

const formFields = [
  {
    name: 'first_name',
    label: 'fname',
  },
  {
    name: 'last_name',
    label: 'lname',
  },
  {
    name: 'title',
    label: 'title',
  },
  {
    name: 'message',
    label: 'message',
    multiline: true,
    rows: 5,
  },
];

const useClasses = makeStyles((theme) => ({
  contacts: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    justifyContent: 'flex-end',
    margin: '20px 0',
    width: '100%',
    // width: '80%',
  },
  contactContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 8px 2px 4px',
    border: '1px solid #ccc',
    borderRadius: 18,
  },
  contactIcon: {
    width: 30,
    height: 30,
    objectFit: 'cover',
    marginRight: 5,
  },
}));

export default function Support({ title }) {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.authReducer);
  const { loading } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const classes = useClasses();
  const theme = useTheme();
  // const [loading, setLoading] = useState(false);
  // const [snackOpen, setSnackOpen] = useState(true);
  const {
    userUISettings: { snackOpen },
  } = useSelector((state) => state.appSettingsReducer);

  const onSubmit = (values) => {
    const payload = {
      full_name: userInfo['first_name'] + ' ' + userInfo['last_name'],
      title: values['title'].trim(),
      email: userInfo['email'],
      message: values['message'].trim(),
    };

    // Object.keys(payload).forEach(
    //   (key) =>
    //     (payload[key] == undefined || payload[key] == '') &&
    //     delete payload[key]
    // );

    // setLoading(true);
    // loading && console.log('loading');
    // console.log('payload', payload);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1500);
    dispatch(contactTechSupportAction(payload));
  };

  const setSnackOpen = () => {
    dispatch({
      type: SET_USER_UI_SETTINGS,
      payload: { snackOpen: false },
    });
  };

  const renderSuccessMsg = () => (
    <Snackbar
      open={snackOpen}
      autoHideDuration={6000}
      onClose={() => setSnackOpen(false)}
    >
      <Alert onClose={() => setSnackOpen(false)} severity="success">
        {t('yourMsgWasSentSuccessfully')}
      </Alert>
    </Snackbar>
  );

  useEffect(() => {
    if (userInfo) {
      Object.keys(initialValues).forEach(
        (key) => userInfo[key] && (initialValues[key] = userInfo[key])
      );
    }
  }, [userInfo]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ height: '100%' }}>
      <Typography
        variant="h4"
        style={{
          fontFamily: 'sfUiTextRegular',
          fontSize: window.innerWidth <= 599 && 25,
        }}
        color="primary"
      >
        {title}
      </Typography>
      {renderSuccessMsg()}
      <div
        style={{
          marginTop: window.innerWidth > 599 ? 50 : 20,
        }}
      >
        <Grid container spacing={4}>
          <Grid item sm={9}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              <Form noValidate>
                <Grid container spacing={1}>
                  {formFields.map((field) => (
                    <Grid key={field.name} item xs={12}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Hidden xsDown>
                          <div
                            style={{ width: '25%' }}
                            style={{ color: theme.custom.myTheme.grey3 }}
                          >
                            {t(field.label)}
                          </div>
                          <div style={{ width: '75%' }}>
                            <FormikInput
                              name={field.name}
                              multiline={field.multiline}
                              rows={field.rows}
                            />
                          </div>
                        </Hidden>

                        <Hidden smUp>
                          <FormikInput
                            label={t(field.label)}
                            name={field.name}
                            multiline={field.multiline}
                            rows={field.rows}
                          />
                        </Hidden>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <Box display="flex">
                  <div className={classes.contacts}>
                    <Button
                      className={classes.contactContainer}
                      onClick={() =>
                        window.open(
                          `https://web.whatsapp.com/send?phone=${whatsappCcontact.phone}`,
                          '_blank'
                        )
                      }
                    >
                      <img
                        src={whatsappCcontact.icon}
                        className={classes.contactIcon}
                      />

                      <Typography variant="body1" color="primary">
                        {whatsappCcontact.phone}
                      </Typography>
                    </Button>
                  </div>
                </Box>
                <Box display="flex">
                  <Box flexGrow={1} />
                  <FormikButton
                    text={t('send')}
                    style={{
                      width: 150,
                      borderRadius: 6,
                    }}
                  />
                </Box>
              </Form>
            </Formik>
          </Grid>
          <Hidden xsDown>
            <Grid item sm={3} container justifyContent="center">
              <img
                src="/svg/logo-primary-text.svg"
                style={{ width: 115, height: 212, objectFit: 'cover' }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </div>
  );
}
