import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Breadcrumbs from 'src/components/Breadcrumbs';

const useclasses = makeStyles((theme) => ({
  constainer: { width: '100%', marginBottom: 40 },
  image: {
    width: '100%',
    // height: '100%',
    height: 450,
    // height: '83%',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: 110,
    },
  },
  description: {
    color: theme.custom.myTheme.grey3,
    fontFamily: 'roboto',
    fontWeight: 600,
    fontSize: 17,
    marginTop: 10,
  },
}));

const FeaturedImage = ({ item }) => {
  const classes = useclasses();
  return (
    <div className={classes.constainer}>
      <img src={item.data} className={classes.image} />
      {/* <div>
        <Breadcrumbs />
        <Typography className={classes.description}>
          {item.description}
        </Typography>
      </div> */}
    </div>
  );
};

export default FeaturedImage;
