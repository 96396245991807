import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // margin: '0 13px',
    backgroundColor: theme.custom.myTheme.grey1,
    cursor: 'pointer',
  },
  image: {
    // width: window.innerWidth <= 400 && '100%',
    width: '100%',
    maxHeight: 220,
    objectFit: 'cover',
    backgroundImage: 'url(/images/pic_img.jpeg)',
    [theme.breakpoints.down('xs')]: {
      maxHeight: window.innerWidth > 300 ? 200 : 140,
    },
  },
  name: {
    padding: '4px 0',
    textAlign: 'center',
    fontFamily: 'roboto',
    fontWeight: 500,
    fontSize: 15,
    color: theme.palette.primary.main,
  },
}));

export default function CelebrityItem({ item, from }) {
  const classes = useStyles();
  const history = useHistory();

  const navigationHandler = () => {
    history.push(`/category-details/${item.id}?page=celebrity_detail`);
  };

  return (
    <div className={classes.gridItem} onClick={navigationHandler}>
      <img className={classes.image} src={item?.data} />

      <Typography className={classes.name}>{item?.title}</Typography>
    </div>
  );
}
