import React, { useState, useMemo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getMainPageAction } from '../../store/actions/page.action';
import RenderPage from 'src/components/RenderPage';
import Loading from 'src/components/base/Loading';
import { useLocation, useParams } from 'react-router-dom';
import BasePage from 'src/components/base/BasePage';

export default function CatDetailsScreen() {
  const params = useParams();

  const searchParams = new URLSearchParams(useLocation().search);
  const page = searchParams.get('page');

  const dispatch = useDispatch();
  const [contentDataSub, setcontentDataSubSub] = useState(null);
  const pageReducer = useSelector((state) => state.pageReducer);

  const renderItem = useMemo(
    () => (item) =>
      (
        <RenderPage
          key={Math.random().toString()}
          // style={{ flex: 1 }}
          section={item}
          noSlider={true}
        />
      ),
    [contentDataSub]
  );

  const data = pageReducer[`${page}_${params.id}`];

  useEffect(() => {
    if (!data) dispatch(getMainPageAction(page, params.id));
    else {
      setcontentDataSubSub(data);
    }
  }, [page, params.id, dispatch, data]);

  useEffect(() => {
    return () => {
      setcontentDataSubSub(null);
    };
  }, []);

  if (!contentDataSub) {
    return <Loading />;
  }

  return (
    <BasePage style={{ paddingTop: 0 }}>
      {contentDataSub && contentDataSub.map((item) => renderItem(item))}
    </BasePage>
  );
}
