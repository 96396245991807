import { localStoragePrefix, SHIPPING_ADDRESS } from 'src/config';
import {
  ADD_TO_ADDRESS_FAIL,
  ADD_TO_ADDRESS_REQUEST,
  ADD_TO_ADDRESS_SUCCESS,
  GET_ADDRESS_ITEMS_FAIL,
  GET_ADDRESS_ITEMS_REQUEST,
  GET_ADDRESS_ITEMS_SUCCESS,
  GET_CITIES_FAIL,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  RESET_ADDRESS,
  UPDATE_ADDRESS_FAIL,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  VALIDATE_ADDRESS_FAIL,
  VALIDATE_ADDRESS_REQUEST,
  VALIDATE_ADDRESS_SUCCESS,
  RESET_HAS_ERROR,
  SET_SHIPPING_ADDRESS,
} from '../constants/address.types';

const initialState = {
  loading: false,
  error: null,
  addresses: null,
  hasError: false,

  shippingAddress: localStorage.getItem(localStoragePrefix + SHIPPING_ADDRESS)
    ? JSON.parse(localStorage.getItem(localStoragePrefix + SHIPPING_ADDRESS))
    : null,
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    // add to address
    case ADD_TO_ADDRESS_REQUEST:
      return { ...state, error: null, loading: true };

    case ADD_TO_ADDRESS_SUCCESS:
      return { ...state, error: null, loading: false };

    case ADD_TO_ADDRESS_FAIL:
      return { ...state, error: action.error, loading: false };

    case UPDATE_ADDRESS_REQUEST:
      return { ...state, error: null, loading: true };

    case UPDATE_ADDRESS_SUCCESS:
      return { ...state, error: null, loading: false };

    case UPDATE_ADDRESS_FAIL:
      return { ...state, error: action.error, loading: false };

    case VALIDATE_ADDRESS_REQUEST:
      return { ...state, error: null };

    case VALIDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        error: null,
        hasError: action.payload,
      };

    case VALIDATE_ADDRESS_FAIL:
      return { ...state, error: action.error };

    case RESET_HAS_ERROR:
      return { ...state, hasError: false };

    // get address items
    case GET_ADDRESS_ITEMS_REQUEST:
      return { ...state, loading: true };

    case GET_ADDRESS_ITEMS_SUCCESS:
      // let c;
      // c = action.payload.map((item) => {
      //   return {
      //     product: item.product_id,
      //     id: item.id,
      //     qty: item.qty,
      //   };
      // });

      return {
        ...state,
        addresses: action.payload,
        error: null,
        loading: false,
      };

    case GET_ADDRESS_ITEMS_FAIL:
      return { ...state, error: action.error, loading: false };

    // reset address
    case RESET_ADDRESS:
      return { ...initialState };

    case GET_COUNTRIES_REQUEST:
      return { ...state, loading: true };

    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        error: null,
        countries: action.payload,
        loading: false,
      };

    case GET_COUNTRIES_FAIL:
      return { ...state, error: action.error, loading: false };

    case GET_CITIES_REQUEST:
      return { ...state, loading: true };

    case GET_CITIES_SUCCESS:
      return { ...state, error: null, cities: action.payload, loading: false };

    case GET_CITIES_FAIL:
      return { ...state, error: action.error, loading: false };

    case SET_SHIPPING_ADDRESS:
      return { ...state, shippingAddress: action.payload };

    default:
      return state;
  }
};

export default addressReducer;
