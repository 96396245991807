import { localStoragePrefix } from '../../config';
import ApiService from '../../services/api.service';
import { SET_USER_UI_SETTINGS } from '../constants/app.settings.types';
import {
  GET_CURRENCY_INFO_REQUEST,
  GET_CURRENCY_INFO_SUCCESS,
  GET_CURRENCY_INFO_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  CONTACT_TECH_SUPPORT_SUCCESS,
  CONTACT_TECH_SUPPORT_FAIL,
  CONTACT_TECH_SUPPORT_REQUEST,
  TOGGLE_MODAL,
} from '../constants/user.types';

export const getCurrencyInfo = (isoCode) => async (dispatch) => {
  try {
    dispatch({ type: GET_CURRENCY_INFO_REQUEST });
    const { data } = await ApiService.get(
      `items/counteries?fields=id,currency_id.from,currency_id.to,arabic_title,english_title,iso_code,currency_id.id,currency_id.english_title,currency_id.arabic_title,currency_id.title,currency_id.from_kuwaiti_dinar&filter[iso_code]=${isoCode}`
    );

    if (data.data?.length) {
      dispatch({
        type: GET_CURRENCY_INFO_SUCCESS,
        payload: data.data[0].currency_id,
      });
    }
  } catch (error) {
    console.log({ error });
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_CURRENCY_INFO_FAIL, error: message });
  }
};

export const updateProfileAction = (payload, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    for await (const [key, value] of Object.entries(payload)) {
      if (
        ['image/png', 'image/jpg', 'image/jpeg'].includes(payload[key].type)
      ) {
        let formData = new FormData();
        formData.append('files', value);

        const { data } = await ApiService.post('/upload', formData);

        payload[key] = data.data[0].id;
      }
    }

    const { data } = await ApiService.patch(`users/${id}`, payload);

    localStorage.setItem(
      localStoragePrefix + 'userInfo',
      JSON.stringify(data.data)
    );
    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.data });
    window.location.reload();
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: UPDATE_PROFILE_FAIL, payload: message });
  }
};

// technial support
export const contactTechSupportAction = (payload) => async (dispatch) => {
  try {
    // console.log('payload req', payload);
    dispatch({ type: CONTACT_TECH_SUPPORT_REQUEST });

    // setTimeout(() => {
    //   dispatch({ type: CONTACT_TECH_SUPPORT_SUCCESS });
    //   dispatch({ type: SET_USER_UI_SETTINGS, payload: { snackOpen: true } });
    // }, 1500);

    await ApiService.post('items/technical_support', payload);

    dispatch({ type: CONTACT_TECH_SUPPORT_SUCCESS });
    dispatch({ type: SET_USER_UI_SETTINGS, payload: { snackOpen: true } });

    // dispatch({ type: TOGGLE_MODAL });

    // navigation.navigate('Account');
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: CONTACT_TECH_SUPPORT_FAIL, payload: message });
  }
};
