export const GET_PRODUCT_BY_ID_REQUEST = 'GET_PRODUCT_BY_ID_REQUEST';
export const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS';
export const GET_PRODUCT_BY_ID_FAIL = 'GET_PRODUCT_BY_ID_FAIL';

export const GET_PRODUCT_BY_AUTHOR_ID_REQUEST =
  'GET_PRODUCT_BY_AUTHOR_ID_REQUEST';
export const GET_PRODUCT_BY_AUTHOR_ID_SUCCESS =
  'GET_PRODUCT_BY_AUTHOR_ID_SUCCESS';
export const GET_PRODUCT_BY_AUTHOR_ID_FAIL = 'GET_PRODUCT_BY_AUTHOR_ID_FAIL';

export const FILTER_PRODUCTS_REQUEST = 'FILTER_PRODUCTS_REQUEST';
export const FILTER_PRODUCTS_SUCCESS = 'FILTER_PRODUCTS_SUCCESS';
export const FILTER_PRODUCTS_FAIL = 'FILTER_PRODUCTS_FAIL';

export const GET_AUTHORS_REQUEST = 'GET_AUTHORS_REQUEST';
export const GET_AUTHORS_SUCCESS = 'GET_AUTHORS_SUCCESS';
export const GET_AUTHORS_FAIL = 'GET_AUTHORS_FAIL';

// search pagination
export const INC_SEARCH_PAGE = 'INC_SEARCH_PAGE';
export const SET_SEARCH_PAGE = 'SET_SEARCH_PAGE';
export const RESET_SEARCH_PAGE = 'RESET_SEARCH_PAGE';
export const SET_SEARCH_PAGE_COUNT = 'SET_SEARCH_PAGE_COUNT';
export const RESET_SEARCH_PAGE_COUNT = 'RESET_SEARCH_PAGE_COUNT';
// export const TRIGGER_REFRESHING = 'TRIGGER_REFRESHING';

// authors pagination
// export const INC_SEARCH_PAGE = 'INC_SEARCH_PAGE';
export const SET_AUTHORS_CURRENT_PAGE = 'SET_AUTHORS_CURRENT_PAGE';
export const RESET_AUTHORS_CURRENT_PAGE = 'RESET_AUTHORS_CURRENT_PAGE';
export const SET_AUTHORS_PAGE_COUNT = 'SET_AUTHORS_PAGE_COUNT';

// author's products pagination
export const INC_AUTHORS_PRODUCTS_PAGE = 'INC_AUTHORS_PRODUCTS_PAGE';
export const SET_AUTHORS_PRODUCTS_PAGE = 'SET_AUTHORS_PRODUCTS_PAGE';
export const RESET_AUTHORS_PRODUCTS_PAGE = 'RESET_AUTHORS_PRODUCTS_PAGE';
// export const TRIGGER_REFRESHING = 'TRIGGER_REFRESHING';
export const SET_AUTHORS_PRODUCTS_PAGE_COUNT =
  'SET_AUTHORS_PRODUCTS_PAGE_COUNT';
export const RESET_AUTHORS_PRODUCTS_PAGE_COUNT =
  'RESET_AUTHORS_PRODUCTS_PAGE_COUNT';

export const RESET_PAGINATION = 'RESET_PAGINATION';

export const SEARCH_PRODUCTS_REQUEST = 'SEARCH_PRODUCTS_REQUEST';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAIL = 'SEARCH_PRODUCTS_FAIL';

export const GET_RELATED_PRODUCTS_REQUEST = 'GET_RELATED_PRODUCTS_REQUEST';
export const GET_RELATED_PRODUCTS_SUCCESS = 'GET_RELATED_PRODUCTS_SUCCESS';
export const GET_RELATED_PRODUCTS_FAIL = 'GET_RELATED_PRODUCTS_FAIL';

export const GET_AUTHOR_INFO_REQUEST = 'GET_AUTHOR_INFO_REQUEST';
export const GET_AUTHOR_INFO_SUCCESS = 'GET_AUTHOR_INFO_SUCCESS';
export const GET_AUTHOR_INFO_FAIL = 'GET_AUTHOR_INFO_FAIL';

export const SET_REVIEW_PAYLOAD = 'SET_REVIEW_PAYLOAD';

export const POST_REVIEW_REQ = 'POST_REVIEW_REQ';
export const POST_REVIEW_SUCCESS = 'POST_REVIEW_SUCCESS';
export const POST_REVIEW_FAIL = 'POST_REVIEW_FAIL';
