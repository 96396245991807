import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getProductByIdAction } from 'src/store/actions/product.actions';

import Loading from 'src/components/base/Loading';
import { Box, Grid, Typography } from '@material-ui/core';
import BasePage from 'src/components/base/BasePage';
import BaseContainer from 'src/components/base/BaseContainer';
import { useParams } from 'react-router';

import ProductReviews from './ProductReviews';
import ProductFeatures from './ProductFeatures';
import RelatedProducts from './RelatedProducts';
import ProductMainInfo from './ProductMainInfo';
import ProductMedia from './ProductMedia';

export default function ProductDetailsScreen() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];

  const productReducer = useSelector((state) => state.productReducer);

  const { loading: loadingProduct, error, product } = productReducer;

  const { productId } = useParams();

  useEffect(() => {
    dispatch(getProductByIdAction(productId));
  }, [productId]);

  if (loadingProduct || !product) {
    return <Loading />;
  }

  return (
    <BasePage>
      <BaseContainer>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <ProductMedia />
          </Grid>
          <Grid item xs={12} md={9}>
            <ProductMainInfo />
          </Grid>
        </Grid>

        {/* description */}
        <Box mt={5}>
          <Typography variant="h6" color="primary" style={{ marginBottom: 3 }}>
            {t('description')}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {
              product[
                lang == 'ar'
                  ? 'arabic_description'
                  : lang == 'tr'
                  ? 'turkish_description'
                  : 'english_description'
              ]
            }
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={8} md={5}>
            <ProductFeatures style={{ marginTop: 20 }} />
          </Grid>
        </Grid>

        {/* customer reviews */}
        {product.rating.length > 0 &&
          product.rating.some((review) => review.status == 'published') && (
            <div style={{ marginTop: 20 }}>
              <Typography
                variant="h6"
                color="primary"
                style={{ marginBottom: 15 }}
              >
                {t('customerReviews')}
              </Typography>

              <ProductReviews reviews={product.rating} />
            </div>
          )}

        {/* related products */}
        <RelatedProducts />
      </BaseContainer>
    </BasePage>
  );
}
