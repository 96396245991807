import React, { useEffect, useState } from 'react';
import { Hidden } from '@material-ui/core';

import InnerBar from './InnerBar';
import NavBar from './NavBar';
import TopBar from './TopBar';
import DownloadAppBar from './DownloadAppBar';
import { getMobileOperatingSystem } from 'src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { SET_USER_UI_SETTINGS } from 'src/store/constants/app.settings.types';
// import './app-bar.css';

const supportedMobileOs = ['ipad', 'ios', 'android'];

export default function AppBar({ mobileOS, setMobileOS }) {
  const dispatch = useDispatch();
  // const [mobileOS, setMobileOS] = useState();

  // const {
  //   userUISettings: { mobileOS },
  // } = useSelector((state) => state.appSettingsReducer);

  const handleScroll = () => {
    let topBarPos = 0;
    let mainNavPos = 80;
    let currentScrollPos = window.pageYOffset;
    let appBarWrapper = document.getElementById('app-bar-wrapper');
    let appBarClearer = document.getElementById('app-bar-clearer');
    let topBarElment = document.getElementById('top-bar');
    let mainNavElement = document.getElementById('main-nav-bottom');
    let mainLogo = document.getElementById('main-logo');

    if (window.innerWidth >= 600) {
      appBarWrapper.style.position = 'relative';
      if (topBarPos < currentScrollPos) {
        mainLogo.style.marginTop = '38px';
        topBarElment.style.position = 'fixed';
        topBarElment.style.top = '0';
        topBarElment.style.left = '0';
      } else {
        topBarElment.style.position = 'relative';
        mainLogo.style.marginTop = '0';
      }

      if (mainNavPos < currentScrollPos) {
        mainNavElement.style.position = 'fixed';
        mainNavElement.style.top = '40px';
        mainNavElement.style.left = '0';
        mainNavElement.style.right = '0';
      } else {
        mainNavElement.style.position = 'relative';
        mainNavElement.style.top = '0';
        mainNavElement.style.left = '0';
        mainNavElement.style.right = '0';
      }
    } else {
      appBarWrapper.style.position = 'fixed';
      appBarWrapper.style.top = '0';
      appBarWrapper.style.left = '0';
      appBarClearer.style.display = 'block';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // dispatch({
    //   type: SET_USER_UI_SETTINGS,
    //   payload: { mobileOS: getMobileOperatingSystem()?.toLowerCase() },
    // });
    setMobileOS(getMobileOperatingSystem()?.toLowerCase());
  }, []);

  return (
    <div
      style={{ position: 'relative', width: '100%', zIndex: 300 }}
      id="app-bar-wrapper"
    >
      {supportedMobileOs.includes(mobileOS) && (
        // <DownloadAppBar />
        <DownloadAppBar mobileOS={mobileOS} setMobileOS={setMobileOS} />
      )}

      <div style={{ width: '100%' }}>
        <Hidden xsDown>
          <TopBar />
        </Hidden>
        <InnerBar />
      </div>

      <NavBar />
    </div>
  );
}
