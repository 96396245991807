import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseContainer from 'src/components/base/BaseContainer';
import BasePage from 'src/components/base/BasePage';
import Center from 'src/components/Center';

function StoreScreen() {
  const { t } = useTranslation();

  return (
    <BasePage>
      <BaseContainer>
        <Center style={{ flexDirection: 'column' }}>
          <img
            src="/images/comming-soon.png"
            style={{ width: 882, height: 664, objectFit: 'cover' }}
          />
          <Typography
            color="primary"
            style={{
              fontFamily: 'sfUiDisplayLight',
              fontSize: 90,
              marginTop: 20,
              textTransform: 'uppercase',
            }}
          >
            {t('commingSoon')}
          </Typography>
        </Center>
      </BaseContainer>
    </BasePage>
  );
}

export default StoreScreen;
