import React from 'react';
import ReactDOM from 'react-dom';
import 'swiper/swiper-bundle.css';

import App from './App';
// import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <div
      style={{
        width: window.innerWidth,
        backgroundColor: '#ff0',
      }}
    >
    </div> */}
    <App />
  </Provider>,
  //  </React.StrictMode>,
  document.getElementById('root')
);

// reportWebVitals();
