import ApiService from "src/services/api.service";
import {
  GET_COUPON_FAIL,
  GET_COUPON_REQUEST,
  GET_COUPON_SUCCESS,
} from "../constants/coupon.types";

export const getCouponAction = (code) => async (dispatch) => {
  try {
    dispatch({ type: GET_COUPON_REQUEST });
    const { data } = await ApiService.get(
      `items/coupons?fields=*,orders.*,orders.id,orders.payment_status&filter[code]=${code}`
    );
    dispatch({
      type: GET_COUPON_SUCCESS,
      payload: data.data,
    });
    // console.log("end validation", data.data);
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_COUPON_FAIL, error: message });
  }
};
