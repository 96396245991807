import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import BaseContainer from 'src/components/base/BaseContainer';
import { Grid } from '@material-ui/core';

import UserProfile from './panels/UserProfile';
import BasePage from 'src/components/base/BasePage';
import OrderHistory from './OrderHistory';
import ReturnExchange from './panels/ReturnExchange';
import MyAddresses from './MyAddresses';
import Wishlist from './panels/Wishlist';
import Support from './panels/Support';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: {
    marginTop: window.innerWidth >= 960 && 90,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  tab: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: 10,
    borderRadius: 10,
    background: theme.palette.primary.main,
    opacity: 1,
    justifyContent: 'flex-start',
    padding: '0 20px',
    '& span': {
      alignItems: 'flex-start',
    },
  },
  tabIcon: {
    width: 25,
    objectFit: 'cover',
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      width: 20,
    },
  },
  tabLabel: {
    color: 'white',
    fontFamily: 'roboto',
    fontSize: 16,
    margin: '0 10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  selected: {
    background: theme.palette.secondary.main,
  },
  panel: {
    height: '100%',
    '& > div': {
      height: '100%',
    },
  },
}));

const tabpanels = [
  {
    label: 'editProfile',
    Component: UserProfile,
  },
  {
    label: 'orderHistory',
    Component: OrderHistory,
  },
  {
    label: 'returnExchange',
    Component: ReturnExchange,
  },
  {
    label: 'myAddresses',
    Component: MyAddresses,
  },
  {
    label: 'wishlist',
    Component: Wishlist,
  },
  {
    label: 'support',
    Component: Support,
  },
];

export default function AccountTabs() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const { push } = useHistory();

  const { accountTabs } = useSelector((state) => state.userReducer);

  const handleChange = (event, newValue) => {
    push(`/profile/${newValue}`);
    // setValue(newValue);
  };

  const { tabIndex } = useParams();

  const renderTab = (label, iconName, idx) => (
    <Tab
      key={Math.random().toString()}
      classes={{ root: classes.tab, selected: classes.selected }}
      label={
        <Box display="flex" alignItems="center">
          <img
            className={classes.tabIcon}
            // style={{ color: 'white', marginRight: 10, width: 20 }}
            src={`/svg/${iconName}.svg`}
          />

          <Typography className={classes.tabLabel}>{t(label)}</Typography>
        </Box>
      }
      {...a11yProps(idx)}
    />
  );

  const renderPanel = (label, Component, idx) => (
    <TabPanel
      value={value}
      index={idx}
      key={Math.random().toString()}
      className={classes.panel}
    >
      <Component title={t(label)} />
    </TabPanel>
  );

  useEffect(() => {
    tabIndex && setValue(+tabIndex);
  }, [tabIndex]);

  return (
    <BasePage>
      <div className={classes.root}>
        <BaseContainer>
          <Grid container spacing={7}>
            <Grid item xs={12} md={4}>
              <Tabs
                orientation="vertical"
                // variant="scrollable"
                TabIndicatorProps={{
                  style: {
                    display: 'none',
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                classes={{ root: classes.tabs }}
              >
                {accountTabs.map(({ label, iconName }, idx) =>
                  renderTab(label, iconName, idx)
                )}
              </Tabs>
            </Grid>
            <Grid item xs={12} md={8}>
              {tabpanels.map(({ label, Component }, idx) =>
                renderPanel(label, Component, idx)
              )}
            </Grid>
          </Grid>
        </BaseContainer>
      </div>
    </BasePage>
  );
}
