import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import { Typography } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import BaseContainer from 'src/components/base/BaseContainer';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  btn: {
    padding: 0,
    borderRadius: 0,
    transition: 'all .2s ease-in-out',
    fontSize: 13,
    '&:hover': {
      background: theme.palette.primary.main,
      color: 'white',
      borderBottom: `3px solid ${theme.palette.secondary.main}`,
    },
  },

  arrow: {
    position: 'absolute',
    bottom: -15,
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: `10px solid ${theme.palette.secondary.main}`,
    margin: '0 auto',
  },
  link: {
    '& :hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const ITEM_HEIGHT = 25;
export default function MenuListComposition({ item }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t, i18n } = useTranslation();
  const classes = useStyles({ dir: i18n.dir() });
  const lang = i18n.language.split('-')[0];

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div
      className={classes.root}
      // onClick={() => setOpen(true)}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          color="primary"
          style={{ position: 'relative' }}
          className={classes.btn}
        >
          {t(item.text)}
          {open && <div className={classes.arrow} />}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            // background: 'blue',
            left: 0,
            right: 0,
            transform: 'unset !important',
            transitionDuration: '0s !important',
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <div
                style={{
                  // border: '1px solid red',
                  width: '100vw',
                  height: 490,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    // top: 80,
                    top: 15,
                    left: 0,
                    right: 0,
                    width: '100%',
                    zIndex: 1000,
                    borderRadius: 0,
                    // border: '1px solid red',
                    // height: 490,
                  }}
                >
                  <BaseContainer style={{ background: '#fff' }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                        style={{ padding: 0 }}
                      >
                        {/* item.submenu && */}

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: 20,
                          }}
                        >
                          {item.submenu.map((submenu) => (
                            <div key={Math.random().toString()}>
                              <Typography
                                color="primary"
                                style={{
                                  fontFamily: 'sfUiDisplayBold',
                                  marginBottom: 5,
                                }}
                              >
                                {
                                  submenu[
                                    lang == 'ar'
                                      ? 'arabic_title'
                                      : lang == 'tr'
                                      ? 'turkish_title'
                                      : 'english_title'
                                  ]
                                }
                                {/* {submenu.english_title} */}
                              </Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  flexWrap: 'wrap',
                                  // width: 240,
                                  width:
                                    ITEM_HEIGHT *
                                      submenu?.child_categories?.length >
                                    400
                                      ? 400
                                      : 200,
                                  height: 400,
                                  // border: '1px solid red',
                                }}
                              >
                                {submenu?.child_categories.map((child) => (
                                  <Link
                                    key={Math.random().toString()}
                                    to={`/category-details/${child.id}?page=sub_category_detail`}
                                    className={classes.link}
                                    style={{
                                      textDecoration: 'none',
                                      height: ITEM_HEIGHT,
                                    }}
                                  >
                                    <Typography
                                      color="textSecondary"
                                      variant="body2"
                                      style={{
                                        fontFamily: 'sfUiDisplayRegular',
                                        padding: '2px 0',
                                      }}
                                    >
                                      {
                                        child[
                                          lang == 'ar'
                                            ? 'arabic_title'
                                            : lang == 'tr'
                                            ? 'turkish_title'
                                            : 'english_title'
                                        ]
                                      }
                                    </Typography>
                                  </Link>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </MenuList>
                    </ClickAwayListener>
                  </BaseContainer>
                </div>
              </div>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
