export const GET_APP_SETTINGS_REQUEST = 'GET_APP_SETTINGS_REQUEST';
export const GET_APP_SETTINGS_SUCCESS = 'GET_APP_SETTINGS_SUCCESS';
export const GET_APP_SETTINGS_FAIL = 'GET_APP_SETTINGS_FAIL';

export const GET_RETURNS_POLICY_REQUEST = 'GET_RETURNS_POLICY_REQUEST';
export const GET_RETURNS_POLICY_SUCCESS = 'GET_RETURNS_POLICY_SUCCESS';
export const GET_RETURNS_POLICY_FAIL = 'GET_RETURNS_POLICY_FAIL';

export const GET_REFUNDS_POLICY_REQUEST = 'GET_REFUNDS_POLICY_REQUEST';
export const GET_REFUNDS_POLICY_SUCCESS = 'GET_REFUNDS_POLICY_SUCCESS';
export const GET_REFUNDS_POLICY_FAIL = 'GET_REFUNDS_POLICY_FAIL';

export const SET_USER_UI_SETTINGS = 'SET_USER_UI_SETTINGS';
