import React from 'react';
import { StarRounded } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import MuiRating from '@material-ui/lab/Rating';
import { useDispatch, useSelector } from 'react-redux';
import { SET_REVIEW_PAYLOAD } from 'src/store/constants/product.types';

const StyledRating = withStyles((theme) => ({
  iconFilled: {
    color: theme.palette.secondary.main,
  },
  iconHover: {
    color: theme.palette.secondary.main,
  },
}))(MuiRating);

export default function Rating({ oldRating, readOnly = true }) {
  const dispatch = useDispatch();

  const {
    reviewPayload,
    // reviewPayload: { rating },
  } = useSelector((state) => state.productReducer);

  const handleChange = (_, newValue) => {
    // setValue(newValue);
    dispatch({
      type: SET_REVIEW_PAYLOAD,
      payload: { showForm: newValue ? true : false, rating: newValue },
    });
  };

  if (reviewPayload == undefined) return null;

  return (
    <div>
      <StyledRating
        name="rating"
        value={oldRating || reviewPayload?.rating}
        precision={0.5}
        onChange={handleChange}
        readOnly={readOnly}
        // fontSize: window.innerWidth <= 390 && 13
        icon={
          <StarRounded
            style={{
              fontSize:
                window.innerWidth >= 600
                  ? 20
                  : window.innerWidth >= 390
                  ? 16
                  : 13,
            }}
          />
        }
      />
    </div>
  );
}
