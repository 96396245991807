import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { ListItem, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const NavItem = ({ className, item, ...rest }) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  let Icon = item.icon;

  const renderNavItem = () =>
    item.href ? (
      // <Link  to={item.href || '#'}>
      <ListItem
        to={item.href}
        className={clsx(classes.item, classes.button)}
        component={RouterLink}
        button
        disableGutters
        color="primary"
        {...rest}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}> {t(item.text)}</span>
        <ChevronRight style={{ fontSize: 28 }} />
      </ListItem>
    ) : (
      // </Link>
      <ListItem
        className={clsx(classes.item, classes.button)}
        disableGutters
        button
        {...rest}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}> {t(item.text)}</span>
        {item.submenu && <ChevronRight />}
      </ListItem>
    );

  return renderNavItem();
};

export default NavItem;
