import {
  GET_CITIES_FAIL,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  CALCULATE_SHIPPING_FAIL,
  CALCULATE_SHIPPING_REQUEST,
  CALCULATE_SHIPPING_SUCCESS,
} from "../constants/address.types";

import {
  GET_ORDER_TRACKING_FAIL,
  GET_ORDER_TRACKING_REQUEST,
  GET_ORDER_TRACKING_SUCCESS,
} from "../constants/aramix.types";

const initialState = {
  loading: false,
  error: null,
  addressItems: null,
  totalAmount: null,
  codCountries: ["SA", "KW", "QA", "BH", "AE", "OM", "EG", "TR"],
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES_REQUEST:
      return { ...state, loading: true };

    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        aramixCountries: action.payload,
        error: null,
        loading: false,
      };

    case GET_COUNTRIES_FAIL:
      return { ...state, error: action.error, loading: false };

    case GET_CITIES_REQUEST:
      return { ...state, loading: true };

    case GET_CITIES_SUCCESS:
      return {
        ...state,
        aramixCities: action.payload,
        error: null,
        loading: false,
      };

    case GET_CITIES_FAIL:
      return { ...state, error: action.error, loading: false };

    case CALCULATE_SHIPPING_REQUEST:
      return { ...state, loading: true };

    case CALCULATE_SHIPPING_SUCCESS:
      return {
        ...state,
        error: null,
        shippingInfo: action.payload,
        loading: false,
      };

    case CALCULATE_SHIPPING_FAIL:
      return { ...state, error: action.error, loading: false };

    // order tracking
    case GET_ORDER_TRACKING_REQUEST:
      return { ...state, loading: true };

    case GET_ORDER_TRACKING_SUCCESS:
      return {
        ...state,
        error: null,
        trackingData: action.payload,
        loading: false,
      };

    case GET_ORDER_TRACKING_FAIL:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
};

export default addressReducer;
