import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loading from 'src/components/base/Loading';
import Center from 'src/components/Center';
import { getShippingMethodAction } from 'src/store/actions/cart.actions';
import ContentCard from './ContentCard';

const useClasses = makeStyles((theme) => ({
  trackingBtn: {
    background: theme.palette.secondary.main,
    color: 'white',
    textTransform: 'capitalize',
    width: '100%',
    fontSize: 16,
    marginTop: 15,
    transition: 'all .3s ease-in-out',
    '& img': {
      width: 26,
      height: 26,
      objectFit: 'cover',
      margin: '0 5px',
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      opacity: 0.8,
    },
  },
}));

export default function ShippingMethod() {
  const { loading, shippingMethods } = useSelector(
    (state) => state.cartReducer
  );

  const { orderDetail } = useSelector((state) => state.orderReducer);

  const { t } = useTranslation();
  const classes = useClasses();
  const dispatch = useDispatch();
  const { push } = useHistory();

  useEffect(() => {
    // call by aramix id
    !shippingMethods && dispatch(getShippingMethodAction(1));
    // !orderDetail && !shippingMethods && dispatch(getShippingMethodAction(1));
  }, []);

  if ((loading || !shippingMethods) && !orderDetail) {
    return <Loading />;
  }

  return (
    <ContentCard title="shippingMethod">
      <Box display="flex" alignItems="center">
        {shippingMethods?.map((item) => (
          <Center key={Math.random().toString()}>
            <img
              src={item.shipping_id.image.data.full_url}
              style={{ width: 85, height: 35, objectFit: 'cover' }}
            />
            {orderDetail && (
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ margin: '0 10px' }}
              >
                | {orderDetail.shipping_value || t('freeShipping')}
              </Typography>
            )}
          </Center>
        ))}
      </Box>
      {orderDetail && (
        <Button
          className={classes.trackingBtn}
          onClick={() => push(`/profile/1?orderId=${orderDetail.id}`)}
          // disabled={orderDetail.order_status == 'canceled'}
        >
          {t('trackYourOrder')}
          <img src="/images/icons/Icons-65.png" />
        </Button>
      )}
    </ContentCard>
  );
}
