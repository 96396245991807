import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { parse, isDate } from 'date-fns';
import { format } from 'date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& input': {},
    '& label': {},
    '& div': {},
  },
  label: {
    width: 150,
    color: theme.custom.myTheme.grey3,
    marginBottom: 10,
  },
  inputProps: {
    width: '100%',
    padding: '0 16px',
    height: 40,
    borderRadius: 0,
    color: theme.palette.primary.main,
  },
}));

// function parseDateString(_, originalValue) {
//   console.log('parseDateString');
//   const parsedDate = isDate(originalValue)
//     ? originalValue
//     : parse(originalValue, 'yyyy-MM-dd', new Date());

//   return parsedDate;
// }

// ? parseDateString(null, values[name]) :

export default function FormikPicker(props) {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState();

  const { label, name, ...otherProps } = props;
  const [field, mata] = useField(name);
  const { setFieldValue, values, errors } = useFormikContext();

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  const handleChange = (date) => {
    handleDateChange(date);
    setFieldValue(name, format(date, 'yyyy-MM-dd'));
    // setFieldValue(name, date.toISOString().slice(0, 10));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {<div className={classes.label}>{label}</div>}
      <DatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        // label="With keyboard"
        // format="yyyy-MM-dd"
        format="dd/MM/yyyy"
        value={values[name] || null}
        // value={values[name] || new Date('22/09/2005')}
        // value={selectedDate}
        // InputAdornmentProps={{ position: 'start' }}
        onChange={handleChange}
        // {...configTextfield}
        inputProps={{
          className: classes.inputProps,
        }}
        style={{
          width: '100%',
          height: 40,
          borderRadius: 0,
          background: 'white',
          margin: 0,
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
