import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { localStoragePrefix } from 'src/config';
import { getCountriesAction } from 'src/store/actions/address.actions';
import { getCurrencyInfo } from 'src/store/actions/user.actions';
import Dialog from '../Dialog';
import Select from './Select';

export default function SelectCountry() {
  const { t } = useTranslation();

  const [countryDialog, setCountryDialog] = useState(false);
  const [countryId, setCountryId] = useState();
  const [countryIsoCode, setCountryIsoCode] = useState();

  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.addressReducer);
  const { currencyInfo } = useSelector((state) => state.userReducer);

  const getData = async () => {
    try {
      const res = await axios.get('https://geolocation-db.com/json/');
      res.data.country_code &&
        localStorage.setItem(
          localStoragePrefix + 'countryInfo',
          res.data.country_code
        );
      setCountryIsoCode(res.data.country_code);
    } catch (error) {
      setCountryDialog(true);
      console.log('denied');
    }
  };

  // useEffect(() => {
  //   countryCode && dispatch(getCurrencyInfo(countryCode));
  // }, [countryCode]);

  useEffect(() => {
    if (!currencyInfo) {
      const isoCode = localStorage.getItem(
        localStoragePrefix + 'countryIsoCode'
      );
      if (isoCode) {
        setCountryIsoCode(isoCode);
        //  getCurrencyInfo(countryIsoCode)
      } else {
        getData();
      }
    }
  }, [currencyInfo]);

  useEffect(() => {
    countryDialog && dispatch(getCountriesAction());
  }, [countryDialog]);

  useEffect(() => {
    if (countryId) {
      const isoCode = countries?.find((item) => item.id == countryId)?.iso_code;
      isoCode &&
        localStorage.setItem(localStoragePrefix + 'countryIsoCode', isoCode);

      setCountryIsoCode(isoCode);
      // dispatch(getCurrencyInfo(isoCode));
      setCountryDialog(false);
    }
  }, [countryId]);

  useEffect(() => {
    countryIsoCode && dispatch(getCurrencyInfo(countryIsoCode));
  }, [countryIsoCode]);

  return (
    <Dialog
      open={countryDialog}
      closeDialog={() => setCountryDialog(false)}
      // setOpen={setCountryDialog}
      title={t('selectCountry')}
      closeIcon={false}
      color="secondary"
    >
      <div style={{ width: 400 }}>
        <Select
          options={countries || []}
          value={countryId || ''}
          label={t('selectCountry')}
          setSelectValue={setCountryId}
        />
      </div>
    </Dialog>
  );
}
