import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseCarousel from 'src/components/base/BaseCarousel';
import { SwiperSlide } from 'swiper/react/swiper-react';
import Dialog from 'src/components/Dialog';

const useClasses = makeStyles((theme) => ({
  image: {
    width: '100%',
    height: '100%',
    // width: 260,
    // height: 350,
    objectFit: 'cover',
    cursor: 'pointer',
  },
  dialogContainer: {
    width: '100%',
    height: '100%',
    // height: 850,
    textAlign: 'center',
  },

  dialogImage: {
    width:
      window.innerWidth >= 960 ? 800 : window.innerWidth >= 610 ? 480 : 350,
    height: '100%',
    objectFit: 'cover',
  },
  borderedTitle: {
    borderBottomColor: theme.custom.myTheme.grey3,
    borderBottomWidth: 1,
    color: theme.palette.primary.main,
    fontFamily: 'roboto',
    fontWeight: 700,
    fontSize: 16,
    marginBottom: '2%',
  },
}));

export default function ProductMedia() {
  const classes = useClasses();
  const { product } = useSelector((state) => state.productReducer);
  const [mediaItems, setMedia] = useState([]);
  const [sliderDialogOpen, setSliderDialogOpen] = useState(false);
  const { i18n } = useTranslation();

  const imageDialogHandler = () => {
    if (window.innerWidth >= 960) {
      setSliderDialogOpen(true);
    }
  };

  useEffect(() => {
    if (product) {
      let medias = [{ data: product.imageUrl }];

      let mediaObj = {};

      if (product.media.length > 0)
        product.media.forEach((mediaItem) => {
          if (mediaItem.type == 'I') {
            mediaObj = { data: mediaItem.image.data.full_url };
            medias.push(mediaObj);
          }
        });
      setMedia(medias);
    }
  }, [product]);

  return (
    <>
      <div>
        <BaseCarousel
          showNavigation={false}
          showPagination={true}
          slidesPerView={1}
          style={{ padding: 0 }}
        >
          {mediaItems?.map((slide) => (
            <SwiperSlide
              key={Math.random().toString()}
              style={{ textAlign: 'center' }}
            >
              <img
                className={classes.image}
                src={slide.data}
                onClick={imageDialogHandler}
              />
            </SwiperSlide>
          ))}
        </BaseCarousel>
      </div>

      <Dialog
        open={sliderDialogOpen}
        closeDialog={() => setSliderDialogOpen(false)}
        maxWidth={window.innerWidth >= 960 && 'md'}
      >
        <div className={classes.dialogContainer}>
          <BaseCarousel slidesPerView={1} showPagination={true}>
            {mediaItems?.map((slide) => (
              <SwiperSlide key={Math.random().toString()}>
                <div>
                  <img className={classes.dialogImage} src={slide.data} />
                </div>
              </SwiperSlide>
            ))}
          </BaseCarousel>
        </div>
      </Dialog>
    </>
  );
}
