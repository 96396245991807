import {
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BaseContainer from 'src/components/base/BaseContainer';
import BasePage from 'src/components/base/BasePage';
import { calculateShippingAction } from 'src/store/actions/aramix.actions';
import CheckoutWizard from '../CheckoutWizard';
import OrderSummary from '../OrderSummary';

import UserCart from '../UserCart';
import WizardActions from '../WizardActions';
import UserAgreements from './UserAgreements';

const useClasses = makeStyles((theme) => ({
  deliverTo: {
    cursor: 'pointer',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 280 ? 16 : 14,
    },
  },
  address: {
    fontFamily: 'sfUiDisplayRegular',
    color: theme.custom.myTheme.grey3,
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: window.innerWidth > 280 ? 14 : 12,
    },
  },
}));

export default function Checkout(props) {
  const { t } = useTranslation();
  // const { ...rest } = props;
  const { shippingAddress } = useSelector((state) => state.addressReducer);
  const { cartItems, cartItemsCalled } = useSelector(
    (state) => state.cartReducer
  );
  const dispatch = useDispatch();
  const classes = useClasses();
  const history = useHistory();

  const deliveryAddress = () => (
    <div>
      <Typography color="primary" className={classes.deliverTo}>
        {t('deliveryAddress')}
      </Typography>
      <Typography
        className={classes.address}
        style={{ fontFamily: 'sfUiDisplayRegular' }}
      >
        {shippingAddress.full_name}
      </Typography>
      <Typography
        className={classes.address}
        style={{ fontFamily: 'sfUiDisplayRegular' }}
      >
        {shippingAddress.address_line_1 +
          ' ' +
          shippingAddress.address_line_2 +
          ' ,Phone: ' +
          shippingAddress.phone}
      </Typography>
    </div>
  );

  useEffect(() => {
    if (cartItemsCalled) {
      if (cartItems?.length > 0) {
        if (shippingAddress) {
          shippingAddress?.id &&
            dispatch(calculateShippingAction(shippingAddress?.id));
        } else {
          history.push('/shipping-address');
        }
      } else {
        history.push('/cart');
      }
    }
  }, [cartItemsCalled, shippingAddress?.id]);

  if (!shippingAddress?.id) return null;

  return (
    <>
      {/* {deliveryAddress()}
      <Divider style={{ margin: '15px 0' }} />
      <UserCart />
      <UserAgreements {...rest} /> */}

      <BasePage>
        <BaseContainer>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <CheckoutWizard activeStep={2} />
              {deliveryAddress()}
              <Divider style={{ margin: '15px 0' }} />
              <UserCart />
              <Hidden xsDown>
                <UserAgreements />
                <WizardActions activeStep={2} stepsLength={3} />
              </Hidden>
            </Grid>
            <Grid item xs={12} md={4}>
              <OrderSummary activeStep={2} />
            </Grid>
          </Grid>
          <Hidden smUp>
            <UserAgreements />
            <WizardActions activeStep={2} stepsLength={3} />
          </Hidden>
        </BaseContainer>
      </BasePage>
    </>
  );
}
